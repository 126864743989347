import Delete from '@icons/delete.svg'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import SystemSyntaxHierarchyButtons from 'src/components/system/SystemSyntax/SystemSyntaxHierarchyButtons'
import { useSystemSyntaxes } from 'src/query/systems/systemSettings'
import IconButton from 'src/ui-elements/button/IconButton'
import { classNames } from 'src/utility/utils'
import Selector from '../../../../../components/selectors/Selector'
import SyntaxObjectConverter from '../../../../../components/system/SystemSyntax/context/SyntaxObjectConverter'
import { IFilterObject } from '../../../../types/IFilterObject'
import SyntaxSearchFilterLevel from './SyntaxSearchFilterLevel'

interface SyntaxFilterObjectProps {
  filterObject: IFilterObject
  disabled?: boolean
  onChange: (value: IFilterObject) => void
  onDelete?: () => void
  hasError?: boolean
  showHelpText?: boolean
  currentLevel?: number
  disableSyntaxSelector?: boolean
}

const SyntaxFilterObject = ({
  filterObject,
  disabled = false,
  onChange,
  onDelete,
  hasError,
  showHelpText,
  currentLevel,
  disableSyntaxSelector,
}: SyntaxFilterObjectProps) => {
  const { t } = useTranslation()
  const { data: systemSyntaxes = [] } = useSystemSyntaxes()

  const syntaxCodes = useMemo(
    () =>
      systemSyntaxes.map((syntax) => {
        const code = SyntaxObjectConverter.getSyntaxCode(
          syntax.system_syntax_categories,
        )
        return { ...syntax, code }
      }),
    [systemSyntaxes],
  )

  const changeSelectedProjectSyntax = (id: number) => {
    onChange({
      systemSettingId: id,
      syntaxSearchFilters: {},
      appliesToLevels: [],
    })
  }
  const currentSyntax = syntaxCodes?.find(
    (syntax) => syntax.id === filterObject.systemSettingId,
  )

  const systemSyntaxCategories = useMemo(
    () =>
      currentSyntax?.system_syntax_categories?.filter((category) =>
        currentLevel
          ? category.level
            ? category.level < currentLevel
            : false
          : true,
      ),
    [syntaxCodes, filterObject, currentLevel],
  )

  // Decide which syntax levels can be valid parents
  const disabledSearchLevels = []
  if (currentSyntax?.system_syntax_categories && currentLevel) {
    let allowOptional = false
    for (const category of currentSyntax.system_syntax_categories.sort(
      (a, b) => (b.level ?? 0) - (a.level ?? 0),
    )) {
      if (!category.level) continue

      const isLevelAbove = category.level === currentLevel - 1
      if (isLevelAbove && category.is_optional) allowOptional = true
      if (!isLevelAbove && !allowOptional)
        disabledSearchLevels.push(category.level)
      if (!isLevelAbove && allowOptional && !category.is_optional)
        allowOptional = false
    }
  }

  return (
    <div className="w-full">
      <div
        className={classNames(
          'bg-d-grey-background rounded text-sm font-medium py-1 px-2',
          hasError && 'border-red-300 border',
        )}
      >
        <div className={'flex justify-between'}>
          <div className={'flex flex-col w-full'}>
            {!disableSyntaxSelector && (
              <Selector
                items={syntaxCodes}
                onSelect={changeSelectedProjectSyntax}
                selectedItemId={filterObject.systemSettingId}
                label={t('system_syntax')}
                dataFields={['name', 'code']}
                disabled={disabled}
              />
            )}
            <div className={'py-2'}>
              {systemSyntaxCategories
                ?.sort((a, b) => a.level ?? 0 - (b.level ?? 0))
                .map((syntaxCategory) => {
                  return (
                    <SyntaxSearchFilterLevel
                      key={syntaxCategory.id}
                      searchFilters={
                        filterObject.syntaxSearchFilters[syntaxCategory.id] ??
                        []
                      }
                      systemSyntaxItem={syntaxCategory}
                      disabled={disabled}
                      onChange={(filters) => {
                        delete filterObject.syntaxSearchFilters[
                          syntaxCategory.id
                        ]
                        onChange({
                          ...filterObject,
                          syntaxSearchFilters: {
                            ...filterObject.syntaxSearchFilters,
                            ...(filters.length && {
                              [syntaxCategory.id]: filters,
                            }),
                          },
                        })
                      }}
                      showHelpText={showHelpText}
                    />
                  )
                })}
            </div>
          </div>
          {!disabled && !disableSyntaxSelector && (
            <div className={'flex gap-1'}>
              {onDelete && (
                <IconButton
                  icon={<Delete className="fill-red" />}
                  onClick={onDelete}
                  tooltipText={t('delete')}
                />
              )}
            </div>
          )}
        </div>
        <label className="mb-2">{t('applies_to_levels')}</label>
        {systemSyntaxCategories && (
          <SystemSyntaxHierarchyButtons
            systemSettingId={filterObject.systemSettingId}
            setSelectedLevels={(levels: number[]) => {
              onChange({
                ...filterObject,
                appliesToLevels: levels,
              })
            }}
            appliesToLevels={filterObject.appliesToLevels}
            disabled={disabled}
            disableLevels={currentLevel ? disabledSearchLevels : undefined}
          />
        )}
        {showHelpText && (
          <p className={'flex-[2] text-xs font-normal italic'}>
            {t('search_filter_applies_to_explained')}
          </p>
        )}
      </div>
    </div>
  )
}

export default SyntaxFilterObject

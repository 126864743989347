import { FC, useCallback, useEffect, useRef, useState } from 'react'
import TableSelectorEditor from 'src/components/table-ResponsibleEditor/TableSelectorEditor'
import Step from 'src/document/components/FolderDocument/Step'
import FolderService from 'src/document/services/FolderService'
import { IStepData } from 'src/document/types/IFlow'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import useProjectId from '../../../../components/hooks/useProjectId'
import { assignFile, getStep } from '../../../../service/FileContainerService'
import { IUploadResponse } from '../types'

interface RejectedFileList {
  setMovedStep: (stepId: IStepData, docIds: number[]) => void
  uploadedFiles: UploadedFiles
  folderId: number
}

export interface RejectList {
  fileName: string
  fileId: number
  doc?: { name: string; id: number }
  currentStep?: IStep
  nextStep?: IStep
}
interface UploadedFiles {
  [key: string]: IUploadResponse
}

type IStep = Pick<IStepData, 'id' | 'name' | 'sequence'>

const selectStep = () => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <MaterialIcon
          outline={true}
          icon={'do_not_disturb_on'}
          className="text-d-grey-light"
        />
        <span className="pl-4 text-d-grey-light font-extrabold text-xs uppercase">
          Select Step
        </span>
      </div>
      <MaterialIcon
        outline={true}
        icon={'expand_more'}
        className="text-d-fontchip"
      />
    </div>
  )
}

const RejectedFileList: FC<RejectedFileList> = ({
  setMovedStep,
  uploadedFiles,
  folderId,
}) => {
  const projectId = useProjectId()
  const [uploadedList, setUploadedList] = useState<RejectList[]>([])
  const [nextStep, setNextStep] = useState<any>({})
  const [reloadKey, setReloadKey] = useState<boolean>(false)

  const ref = useRef(uploadedList)
  const nextStepRef = useRef(nextStep)

  const { t } = useMultipleKeysTranslation()

  const loadListData = useCallback(() => {
    const listData: RejectList[] = Object.keys(uploadedFiles).map(
      (fileName) => ({ fileName, fileId: uploadedFiles[fileName].id }),
    )
    setUploadedList(listData)
  }, [uploadedFiles])

  useEffect(() => {
    loadListData()
  }, [loadListData])

  useEffect(() => {
    ref.current = uploadedList
    nextStepRef.current = nextStep
  })

  const onStepChange = (docId: number, newStep: any) => {
    const cloneNext = { ...nextStepRef.current }
    cloneNext[docId] = newStep
    setNextStep(cloneNext)
    setReloadKey((n) => !n)
    setMovedStep(newStep, [docId])
  }

  const onDocChange = async (doc: any, fileId: number) => {
    await assignFile(projectId, doc.id, fileId)
    const index = ref.current.findIndex((file) => file.fileId === fileId)
    const cloneUploadedList = [...ref.current]
    cloneUploadedList[index].doc = doc
    cloneUploadedList[index].currentStep = doc.current_step
    setUploadedList(cloneUploadedList)
    setReloadKey((n) => !n)
  }

  const columns: IListColumns[] = [
    {
      size: '350',
      id: 'uploaded_file',
      name: 'uploaded_file',
      dataField: 'fileName',
      cell: (name: string) => {
        return <span>{name}</span>
      },
    },
    {
      size: '300',
      id: 'target_document',
      name: 'target_document',
      dataField: 'doc',
      cell: (__?: string, _?: number, index?: number) => {
        const file = ref.current[index ?? 0] ?? ''

        return (
          <TableSelectorEditor
            rowId={0}
            selected={'x'}
            getData={() => FolderService.getContainers(folderId)}
            dataFields={['name']}
            isUserSelector={false}
            showCancelButton={true}
            onDataSelected={(d) => onDocChange(d, file.fileId)}
            displayContent={
              file.doc ? (
                <span>{file.doc.name}</span>
              ) : (
                <div className="flex justify-between">
                  <span className="text-d-danger">
                    {t(['document', 'not', 'found'])}
                  </span>
                  <MaterialIcon
                    outline={true}
                    icon={'expand_more'}
                    className="text-d-fontchip"
                  />
                </div>
              )
            }
          />
        )
      },
    },
    {
      size: '250',
      id: 'current_step',
      name: 'current_step',
      dataField: 'currentStep',
      cell: (container?: IStepData) => {
        if (container) {
          return <Step step={container} />
        }
        return <></>
      },
    },
    {
      size: '250',
      id: 'next_step',
      name: 'next_step',
      dataField: 'currentStep',
      cell: (step?: IStepData, _?: number, index?: number) => {
        const file = ref.current[index ?? 0] ?? ''
        if (!step) return <></>
        const nextStep = nextStepRef.current[file.doc?.id ?? 0] ?? ''
        return (
          <TableSelectorEditor
            rowId={file.doc?.id ?? 0}
            selected={step.id}
            getData={() => getStep(projectId, file.doc?.id ?? 0, step.id ?? 0)}
            dataFields={['name']}
            isUserSelector={false}
            showCancelButton={true}
            onDataSelected={(d) => onStepChange(file.doc?.id ?? 0, d)}
            displayContent={nextStep ? <Step step={nextStep} /> : selectStep()}
          />
        )
      },
    },
  ]

  return (
    <div key={reloadKey ? '1' : '2'}>
      <List
        columns={columns}
        disableColumnSelector={true}
        tableName={'RejectedFileList'}
        disableConfigStore={true}
        data={uploadedList}
        onRowClick={() => {
          /*noop */
        }}
        itemsPerPage={0}
      />
    </div>
  )
}

export default RejectedFileList

import moment from 'moment'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import UserIcon from 'src/components/user/UserIcon'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { useConstructionWagon } from 'src/query/construction/construction'
import { ICheckInOut } from 'src/service/OrgTypes'
import { BadgeColor, BadgeColorMapper } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { capFirstLetter, classNames } from '../../utility/utils'
import { contentStyle } from '../MUtils'
import MCard from '../components/MCard'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'

const MChecksDetail = () => {
  const styleClass = {
    root: classNames('bg-mobile', 'w-screen', 'h-screen'),
    container: classNames('p-4', 'overflow-y-auto gap-2 flex flex-col'),
    row: classNames('flex', 'flex-nowrap', 'items-center'),
    label: classNames(
      'text-xs',
      'text-gray-600',
      'font-light',
      'p-2 pl-0',
      'min-w-24 ',
    ),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'my-2',
      'text-xs',
      'font-light',
    ),
  }

  const { t } = useTranslation()
  const history = useHistory()
  const { wid } = useParams<{ wid: string }>()
  const userContext: IUserContext = useContext(UserContext)
  const projectContext: IProjectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  const checkInText = () => [
    t(
      'area_in_which_the_wagon_will_now_start_has_no_outstanding_work_to_prevent_the_work_to_be_carried_out_in_this_wagon',
    ),
    t(
      'area_has_no_material_or_equipment_left_in_the_area_that_hinders_the_work',
    ),
    t('area_has_been_cleared_and_vacuumed'),
    t('area_is_safe_to_work_in'),
    t(
      'all_damage_that_has_occurred_to_floors_walls_ceilings_and_any_installations_or_equipment_is_registered_before_start_up',
    ),
  ]

  const checkOutText = () => [
    t(
      'carriage_that_now_leaves_the_roof_area_has_no_outstanding_work_to_prevent_the_next_carriages',
    ),
    t(
      'carriage_that_now_leaves_the_area_has_no_material_or_equipment_left_in_the_area',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_cleared_and_vacuumed_the_area',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_checked_that_the_area_is_safe_for_others_to_move_in',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_registered_all_damage_that_has_occurred_to_the_floor_walls_roof_and_any_installations_or_equipment',
    ),
  ]

  const getLabel = (index: number, isCheckIn: boolean): JSX.Element => {
    return (
      <div className={'text-xs font-light text-gray-700 py-1'}>
        {isCheckIn ? checkInText()[index] : checkOutText()[index]}
      </div>
    )
  }

  const onBackClick = () => {
    history.goBack()
  }

  const { data: wagon, isPending: loading } = useConstructionWagon(+wid)

  const onEdit = (checkIn: ICheckInOut) => {
    const checkInOutDiscipline = wagon?.activity_disciplines?.find(
      (discipline) => discipline.id === checkIn.discipline_id,
    )
    history.push({
      pathname: `/mobile/check-in-out/${wid}`,
      state: {
        isCheckIn: checkIn.is_check_in,
        checkData: { ...checkIn, checkInOutDiscipline },
      },
    })
  }

  const { width } = useWindowDimensions()

  return (
    <MPage bgColor={'mobile'}>
      <>
        <MHeader
          title={capFirstLetter(t('check_in_out_detail'))}
          showHomeBtn={true}
          showUser={false}
          onBackClick={onBackClick}
          user={userContext.state.user}
        />
        <div style={contentStyle} className={styleClass.container}>
          {loading ||
          !wagon ||
          !wagon.check_ins ||
          wagon.check_ins?.length === 0 ? (
            <div className={'w-full h-full grid place-items-center'}>
              <SpinnerMobile />
            </div>
          ) : (
            wagon.check_ins.map((check) => {
              const discipline = wagon.activity_disciplines?.find(
                (discipline) => discipline.id === check.discipline_id,
              )
              return (
                <MCard key={check.id}>
                  <div className={'flex flex-col w-full'}>
                    <div className="flex justify-between items-center">
                      <span className="text-sm">{`${check.is_check_in ? t('check_in') : t('check_out')} - ${discipline?.name ?? ''} `}</span>
                      <Button
                        onClick={() => onEdit(check)}
                        width={(width - 75) / 2}
                        inMobile={true}
                        type={Button.ButtonType.SUCCESS}
                        size={ButtonSize.XSMALL}
                      >
                        <Icon
                          className={'w-4 h-4 mr-2 flex items-center'}
                          icon={Icons.EDIT_WHITE}
                        />
                        <p className="first-capitalize text-base">
                          {t('edit')}
                        </p>
                      </Button>
                    </div>

                    <div className={`flex mb-3 w-full`}>
                      <div
                        className={
                          'flex text-xs text-gray-500 flex-auto min-w-0'
                        }
                      >
                        {!isEmpty(check) && (
                          <p>
                            {moment(check?.check_in_date).format(
                              'ddd MMM DD YYYY',
                            )}
                          </p>
                        )}
                      </div>
                    </div>
                    {check.check_in_parameters.map((checkInParameter, i) => (
                      <div
                        key={checkInParameter.id}
                        className={
                          'flex items-start text-xs font-medium text-gray-600 mb-1 justify-between'
                        }
                      >
                        <div className={'mr-1 min-w-18'}>
                          {getLabel(i, check.is_check_in)}
                        </div>

                        <div className={'flex transform scale-75'}>
                          {!isEmpty(check) && (
                            <>
                              <div
                                className={`rounded-full flex w-6 h-6 bg-${checkInParameter} hover:border-white focus:border-white mr-2`}
                              />
                              <div
                                onClick={() =>
                                  checkInParameter.tasks.length > 0 &&
                                  history.push({
                                    pathname: `/mobile/${projectId}/issues/checkinout`,
                                    state: {
                                      tasks: checkInParameter.tasks.map(
                                        (task) => ({
                                          ...task,
                                          construction_locomotive: wagon,
                                          control_area: wagon.control_area,
                                        }),
                                      ),
                                    },
                                  })
                                }
                                className={twMerge(
                                  `text-lg flex justify-center items-center rounded-full mr-1 w-12 h-12 bg-gray-mobile text-gray-400 border border-gray-300 bg-green-300 ${BadgeColorMapper()[checkInParameter.color as BadgeColor]}`,
                                )}
                              >
                                {
                                  checkInParameter.tasks.filter(
                                    (task) => task.status === 'done',
                                  ).length
                                }
                                /{checkInParameter.tasks.length}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                    {!check.is_check_in && (
                      <>
                        <div
                          className={'flex items-start text-xs justify-between'}
                        >
                          <div className={'font-light text-gray-700 py-1'}>
                            {t('actual_working_hours')}
                          </div>

                          <div className={'font-normal text-gray-800'}>
                            {wagon?.actual_work_hours ?? 0}
                          </div>
                        </div>

                        <div
                          className={'flex items-start text-xs justify-between'}
                        >
                          <div className={'font-light text-gray-700 py-1'}>
                            {t('actual_staffing')}
                          </div>

                          <div className={'font-normal text-gray-800'}>
                            {wagon?.actual_staffing ?? 0}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  <div className={'my-4 flex flex-nowrap items-center'}>
                    <div className={'w-1/3 text-gray-700 text-xs'}>
                      {t('signed_by')}:
                    </div>
                    <div className={'w-2/3'}>
                      <UserIcon
                        userId={check?.check_in_by_id}
                        firstName={check?.check_in_by?.firstName}
                        lastName={check?.check_in_by?.lastName}
                        image_url={check?.check_in_by?.image_url}
                      />
                    </div>
                  </div>
                  <div className={'my-4 flex flex-nowrap items-center'}>
                    <div className={'w-1/3 text-gray-700 text-xs'}>
                      {t('signature')}:
                    </div>
                    <div className={'w-2/3'}>
                      <img
                        width="100%"
                        height={'auto'}
                        src={check?.signature}
                        alt={`${check?.check_in_by?.firstName} ${t('signature')}`}
                      />
                    </div>
                  </div>
                </MCard>
              )
            })
          )}
        </div>
      </>
    </MPage>
  )
}

export default MChecksDetail

import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { classNames } from '../../utility/utils'

interface IUserIconProps {
  firstName?: string
  lastName?: string
  image_url?: string
  medium?: boolean
  large?: boolean
  inTable?: boolean
  noBorder?: boolean
  userId?: number
  noTextWrap?: boolean
  onMobileClick?: () => void
  icon?: JSX.Element
}

const styleClass = {
  img: (
    large: boolean | undefined,
    medium: boolean | undefined,
    noBorder: boolean | undefined,
    inTable: boolean | undefined,
  ) =>
    classNames(
      'rounded-full',
      'inline',
      large ? 'h-8' : medium ? 'h-10' : 'h-6',
      large ? 'w-8' : medium ? 'w-10' : 'w-6',
      noBorder && 'border-none',
      inTable && 'border-none',
    ),
}

const UserIcon = (props: IUserIconProps): any => {
  const {
    inTable,
    noBorder,
    large,
    medium,
    image_url,
    icon,
    firstName,
    lastName,
    userId,
    noTextWrap,
    onMobileClick,
  } = props

  const [imgUrl, setImgUrl] = useState<string>(
    image_url ? image_url : '/user_profile.png',
  )
  const history = useHistory()

  useEffect(() => {
    setImgUrl(image_url ? image_url : '/user_profile.png')
  }, [props])

  const handleClick = async (e: any) => {
    e.stopPropagation()
    if (onMobileClick) {
      onMobileClick()
      return
    }
    if (!userId) return
    const url = '/users/' + userId
    history.push(url)
  }

  const onError = (e: any) => {
    e.target.onerror = null
    e.target.src = '/user_profile.png'
  }

  return (
    <div className={'flex items-center'}>
      <div
        title={'Click to view profile'}
        className={'flex flex-shrink-0'}
        onClick={handleClick}
      >
        {icon ?? (
          <img
            className={styleClass.img(large, medium, noBorder, inTable)}
            src={imgUrl}
            alt=""
            onError={onError}
          />
        )}
      </div>
      <div>
        {
          <p
            className={`${
              inTable ? '' : 'text-gray-900'
            } text-sm leading-5  ml-2 ${noTextWrap ? 'whitespace-nowrap' : ''}`}
          >
            {`${firstName ?? ''} ${lastName ?? ''}`}
          </p>
        }
      </div>
    </div>
  )
}

export default UserIcon

import { updateWallToken } from 'src/service/TheWallService'
import {
  getDocBaseUrl,
  getOrgBaseUrl,
  getSubcontractorOrgUrl,
} from 'src/service/service-utils'

// change byte to kb, mb, gb
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const getUploadUrl = (url: string) => {
  if (url.includes('{ORG_URL}')) {
    return getOrgBaseUrl(url)
  }
  if (url.includes('{SUBCONTRACTOR}')) {
    return getSubcontractorOrgUrl(url)
  }
  return getDocBaseUrl(url)
}

export const uploadFile = async (
  file: File,
  uploadUrl: string,
  onProgress: (progress: number, loaded: number) => void,
  onSuccess: (response: any) => void,
  onError: (error: any) => void,
  accessToken?: string,
): Promise<XMLHttpRequest> => {
  const url = getUploadUrl(uploadUrl)

  const token = accessToken || `Bearer ${await updateWallToken()}`
  const formData = new FormData()
  formData.append('filepond', file, file.name)

  const request = new XMLHttpRequest()
  request.open('POST', url)
  request.setRequestHeader('Authorization', token)
  request.setRequestHeader('Access-Control-Allow-Origin', '*')

  request.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const uploadProgress = Math.floor((e.loaded / file.size) * 100)
      onProgress(uploadProgress, e.loaded)
    }
  }

  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      const res = JSON.parse(request.response)
      onSuccess(res)
    } else {
      onError(request.response)
    }
  }

  setTimeout(() => {
    request.send(formData)
  }, 1000)
  return request
}

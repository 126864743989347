import * as React from 'react'
import { useTranslation } from 'react-i18next'
import MultiSelector from 'src/components/multi-selector/MultiSelector'
import { IMetaValue } from '../../types/IMetaData'
import DocumentMetaDataBooleanInput from './DocumentMetaDataBooleanInput'
import DocumentMetaDataEnumInput from './DocumentMetaDataEnumInput'
import DocumentMetaDataStringInput from './DocumentMetaDataStringInput'
import DocumentMetaDataTimeInput from './DocumentMetaDataTimeInput'

interface IDocumentMetaDataFieldsProps {
  title: string
  fields: IMetaValue[]
  required: boolean
  errorMessage?: string
  onFieldsUpdate: (values: IMetaValue[]) => void
}

const DocumentMetaDataFields: React.FC<IDocumentMetaDataFieldsProps> = ({
  title,
  fields,
  onFieldsUpdate,
  errorMessage,
  required,
}) => {
  const { t } = useTranslation()

  const onFieldValueUpdate = (
    fieldId: number,
    value?: string | boolean,
    list?: string[],
  ) => {
    const updatedValues = fields.map((metaValue) =>
      metaValue.user_defined_field_id === fieldId
        ? {
            ...metaValue,
            data_value: value,
            data_value_list: list,
            data_set: true,
            error: '',
          }
        : metaValue,
    )

    onFieldsUpdate(updatedValues)
  }

  return (
    <div className="mt-2 flex flex-row flex-wrap justify-between relative">
      <label className="ml-2 w-full block font-medium text-sm leading-5">
        {title}
        {errorMessage && (
          <span className={'text-red-600 text-sm pl-2'}>{errorMessage}</span>
        )}
      </label>
      {fields.length > 0 ? (
        fields.map((field, index) => {
          return field.data_type === 'time' ? (
            <div className="px-2 w-1/2 DocumentMetaDataFields" key={index}>
              <DocumentMetaDataTimeInput
                key={index}
                field={field}
                isPage={false}
                editMode={true}
                onChange={(value) =>
                  onFieldValueUpdate(field.user_defined_field_id, value)
                }
              />
            </div>
          ) : field.data_type === 'string' ? (
            <div className="w-1/2" key={index}>
              <DocumentMetaDataStringInput
                field={field}
                isPage={false}
                editMode={true}
                key={index}
                onChange={(value) =>
                  onFieldValueUpdate(field.user_defined_field_id, value)
                }
              />
            </div>
          ) : field.data_type === 'enum' ? (
            <div className="w-1/2" key={index}>
              <DocumentMetaDataEnumInput
                field={field}
                key={index}
                isPage={false}
                editMode={true}
                onChange={(value) =>
                  onFieldValueUpdate(field.user_defined_field_id, value)
                }
                required={required}
              />
            </div>
          ) : field.data_type === 'boolean' ? (
            <div className="pt-3 w-1/2" key={index}>
              <DocumentMetaDataBooleanInput
                field={field}
                isPage={false}
                editMode={true}
                onChange={(value) =>
                  onFieldValueUpdate(
                    field.user_defined_field_id,
                    value ? 'true' : 'false',
                  )
                }
              />
            </div>
          ) : field.data_type === 'multi_enum' ? (
            <div className="px-2 w-1/2" key={index}>
              <MultiSelector
                items={
                  Array.isArray(field.pattern)
                    ? field.pattern
                        .sort((patternA, patternB) =>
                          patternA?.localeCompare(patternB),
                        )
                        .map((pattern: string) => ({
                          id: pattern,
                          name: pattern,
                        }))
                    : []
                }
                onSelect={(value) => {
                  onFieldValueUpdate(
                    field.user_defined_field_id,
                    undefined,
                    value ? value : [],
                  )
                }}
                label={field.name}
                selectedItems={
                  field.data_value_list ? field.data_value_list : []
                }
                dataFields={['name']}
                fontWeight={'bold'}
                scroll={true}
                noBorder={true}
                bgColor={'white'}
                required={required}
                errorMessage={field.error}
              />
            </div>
          ) : (
            <span key={index} />
          )
        })
      ) : (
        <span className="pl-2 text-gray-600 text-[14px]">
          {required
            ? t('no_required_metadata_found')
            : t('no_optional_metadata_found')}
        </span>
      )}
    </div>
  )
}

export default DocumentMetaDataFields

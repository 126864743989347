import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { draftRecordIdColumn } from 'src/components/TableColumns/Columns'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import { setColorForIcon } from 'src/components/discipline/disciplineUtils'
import { BreadCrumbContext } from 'src/context/BreadCrumbContext/BreadCrumbContext'
import { wagonListExportTemplate } from 'src/export-templates/WagonExports'
import { wagonImportTemplate } from 'src/export-templates/WagonImportTemplate'
import { wagonListQRExportTemplate } from 'src/export-templates/WagonQrExport'
import useDraftMode from 'src/hooks/useDraftMode'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import {
  IWithProjectContext,
  withProjectContext,
} from '../../../context/withProjectContext'
import {
  deleteConstructionWagon,
  editConstructionWagon,
  getPaginatedProjectConstructionwagons,
  getPaginatedTrainConstructionwagons,
} from '../../../service/ConstructionWagonService'
import {
  IConstructionTrain,
  IConstructionWagon,
  IImportItemList,
} from '../../../service/OrgTypes'
import Button from '../../../ui-elements/button/Button'
import List from '../../../ui-elements/list/List'
import {
  filterType,
  IListColumns,
  ISorting,
} from '../../../ui-elements/list/ListTypes'
import Loader from '../../../ui-elements/loader/Loader'
import {
  concatinateValuesForTable,
  titleComparator,
} from '../../../utility/Utility'
import { constructFilterJson, IActiveFilter } from '../../../utility/utils'
import TableDatePicker from '../../datepicker/TableDatePicker'
import CleanZone from '../util/CleanZone'
import WagonForm from './WagonForm'
import WagonModal from './WagonModal'

interface IWagonList extends IWithProjectContext {
  controlAreaId?: number
  isUnderControlArea?: boolean
  trainId?: number
  train?: IConstructionTrain
  projectConfig?: boolean
  inModal?: boolean
  isExpandedElement?: boolean
  reloadParent?: () => void
  inTree?: boolean
  controlAreaGroupId?: number
  location?: string
}

const WagonList = ({
  trainId,
  projectConfig,
  projectContext,
  train,
  controlAreaId,
  isUnderControlArea,
  inModal,
  isExpandedElement,
  reloadParent,
  inTree = false,
  controlAreaGroupId,
  location,
}: IWagonList) => {
  /**
   * Initial load of component. fetches the wagons, refetches if porjectConstext changes.
   */

  const history = useHistory()
  const { getContractFilter, getDisciplineFilter, getTrainFilter } =
    useListHelper()
  const { t } = useTranslation()
  const [wagons, setWagons] = useState<IConstructionWagon[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [formModal, setFormModal] = useState<boolean>(false)
  const [selectedWagon, setSelectedWagon] = useState<IConstructionWagon>()
  const [totalPages, setTotalPages] = useState<number>(0)
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showInspector, setShowInspector] = useState(false)
  const { isConstructionManager } = projectContext.state

  const breadCurmbContext = useContext(BreadCrumbContext)
  const projectId = projectContext.state.currentProject.id
  const { draftMode, turnDraftModeOn } = useDraftMode()

  const wagonsRef = useRef(wagons)

  useEffect(() => {
    wagonsRef.current = wagons
  })

  useEffect(() => {
    getFilteredData([], {} as ISorting, 1)
  }, [projectId, controlAreaId, trainId])

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    if (isUnderControlArea && controlAreaId) {
      activeFilters['control_area'] = [controlAreaId]
    }
    activeFilters['sort'] = currentSorting
    wagonFilter(activeFilters, page)
  }

  const wagonFilter = (activeFilters: IActiveFilter, page: number) => {
    const wagonApi =
      projectConfig || (isUnderControlArea && controlAreaId)
        ? getPaginatedProjectConstructionwagons(
            projectId,
            page,
            activeFilters,
            50,
            draftMode,
          )
        : trainId &&
          getPaginatedTrainConstructionwagons(
            trainId,
            page,
            activeFilters,
            50,
            draftMode,
          )

    if (wagonApi) {
      wagonApi.then((res) => {
        setWagons(res.construction_locomotives)
        setTotalPages(res.pages)

        setLoading(false)
      })
    }
  }

  const reload = () => {
    setReloadTable((n) => !n)
    reloadParent?.()
  }

  useEffect(() => {
    setLoading(true)
    setBreadCrum()
    reload()
  }, [projectContext, draftMode])

  const openCreateModal = () => {
    turnDraftModeOn()
    setFormModal(true)
  }

  const onDelete = (item: IConstructionWagon) => {
    turnDraftModeOn()
    setSelectedWagon(item)
    setShowDeleteModal(true)
  }

  const onDeleteItemClick = () => {
    setLoading(true)
    if (selectedWagon) {
      deleteConstructionWagon(selectedWagon).then(() => {
        reload()
        setShowDeleteModal(false)
      })
    }
  }

  const setBreadCrum = () => {
    if (breadCurmbContext.actions) {
      breadCurmbContext.actions.setCrums(projectId, window.location.href)
    }
  }

  const onClick = (item: IConstructionWagon) => {
    if (inTree) {
      location === 'control-area'
        ? history.push(
            `/constructions/control_areas/control_areas/control_areas/${
              controlAreaId ? controlAreaId : item.control_area_id
            }/trains/${trainId ? trainId : item.construction_train_id}/wagons/${
              item.id
            }`,
          )
        : history.push(
            `/constructions/control_areas/control_area_groups/control_area_groups/${controlAreaGroupId}/control-areas/${
              controlAreaId ? controlAreaId : item.control_area_id
            }/trains/${trainId ? trainId : item.construction_train_id}/wagons/${
              item.id
            }`,
          )
    } else {
      history.push(
        `/constructions/control_areas/control_areas/control_areas/${
          controlAreaId ? controlAreaId : item.control_area_id
        }/trains/${trainId ? trainId : item.construction_train_id}/wagons/${
          item.id
        }`,
      )
    }
  }

  const onStartDateChange = (date: moment.Moment, key: number) => {
    const dataField = wagonsRef.current.filter((d) => d.id === key).pop()
    if (dataField) {
      dataField.startTime = date

      editConstructionWagon(dataField).then(() => {
        setLoading(true)
        reload()
      })
    }
  }

  const getActionButton = () => {
    return isConstructionManager ? (
      isExpandedElement ? (
        <Icon
          icon={Icons.PLUS}
          className={'py-1 text-center w-6'}
          onClick={openCreateModal}
        />
      ) : (
        <Button type={Button.ButtonType.PRIMARY} onClick={openCreateModal}>
          {t('new_wagon')}
        </Button>
      )
    ) : undefined
  }

  const importControlAreaTemplate: IImportItemList = {
    title: t('upload_wagon'),
    templateJson: wagonImportTemplate,
    type: isUnderControlArea
      ? `construction_locomotives/control_areas/${controlAreaId}`
      : trainId
        ? `construction_locomotives/construction_trains/${trainId}`
        : 'construction_locomotives',
    reload,
    label: 'construction_locomotives',
  }

  const columns: IListColumns[] = [
    draftRecordIdColumn(draftMode, t),
    {
      name: 'title',
      size: '300',
      id: 'title',
      dataField: 'title',
      sortingField: 'title',
      filterType: filterType.TEXT,
      filter: [],
    },
    {
      name: 'description',
      size: '180',
      id: 'subject',
      dataField: 'subject',
      sortingField: 'subject',
      filterType: filterType.TEXT,
      filter: [],
    },
    {
      name: 'start_date',
      size: '150',
      id: 'startTime',
      sortingField: 'startTime',
      dataField: 'startTime||update_access',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (data: any, row: number) => (
        <TableDatePicker
          date={data.startTime}
          rowId={row}
          inModal={inModal}
          onDateSubmit={onStartDateChange}
        />
      ),
    },
    {
      name: 'discipline',
      size: '200',
      id: 'discipline',
      dataField: 'discipline',
      sortingField: 'discipline',
      filterType: filterType.DEFAULT,
      filterDataField: 'discipline.shortName',
      filterDataValue: 'discipline.id',
      filter: [],
      getFilter: getDisciplineFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (discipline: any) =>
        discipline ? (
          <span className={'flex flex-row'}>
            <span className={'mr-2'}>
              {setColorForIcon({
                color: discipline.color,
                shape: discipline.shape,
              })}
            </span>
            <span>
              {discipline
                ? concatinateValuesForTable(
                    discipline.shortName,
                    discipline.name,
                  )
                : ''}
            </span>
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'contract',
      size: '200',
      id: 'contract',
      dataField: 'contract',
      sortingField: 'contract',
      filterType: filterType.DEFAULT,
      filter: [],
      getFilter: getContractFilter,
      filterDataField: 'contract.contractNumber||contract.contractName',
      filterDataValue: 'contract.id',
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? titleComparator(a, b, direction) : 0) : 0,
      cell: (contract: any) =>
        contract ? (
          <span>
            {concatinateValuesForTable(
              contract.contractNumber,
              contract.contractName,
            )}
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'duration_days',
      size: '200',
      id: 'duration',
      dataField: 'duration',
      sortingField: 'duration',
      filterType: filterType.NUMBER,
      filter: [],
    },
    {
      name: 'working_hours',
      size: '200',
      id: 'plannedExecutionHours',
      dataField: 'planned_execution_hours||duration',
      sortingField: 'planned_execution_hours',
      filterType: filterType.NUMBER,
      filter: [],
      cell: (data: any) => (
        <span>
          {Math.round(Number(data.planned_execution_hours) * 100) / 100}
        </span>
      ),
    },
    {
      name: 'average_staffing',
      size: '230',
      id: 'planeedStaffing',
      dataField: 'planned_staffing',
      sortingField: 'planned_staffing',
      filterType: filterType.NUMBER,
      filter: [],
      cell: (data: any) => <span>{Math.round(Number(data) * 100) / 100}</span>,
    },
    {
      name: 'clean_status',
      size: '165',
      id: 'clean_status',
      dataField: 'clean_status',
      sortingField: 'clean_status',
      filterType: filterType.TEXT,
      cell: (isClean: string) => (
        <span
          className={`${CleanZone.border(
            isClean,
          )} w-full p-1 border-2 border-solid`}
        >
          {CleanZone.text(t, isClean)}
        </span>
      ),
    },
    {
      name: 'created_at',
      size: '150',
      id: 'createdAt',
      dataField: 'created_at',
      sortingField: 'created_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (date) => <span>{moment(date).format('L')}</span>,
    },
    {
      name: 'updated_at',
      size: '150',
      id: 'updatedAt',
      dataField: 'updated_at',
      sortingField: 'updated_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const trainColumn = {
    name: 'train',
    size: '200',
    id: 'constructionTrain',
    dataField: 'construction_train',
    sortingField: 'construction_train',
    filterType: filterType.DEFAULT,
    filter: [],
    getFilter: getTrainFilter,
    filterDataField: 'construction_train.title',
    filterDataValue: 'construction_train.id',
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? titleComparator(a, b, direction) : 0) : 0,
    cell: (d: any) => (d ? <span>{d.title}</span> : <span />),
  }

  const getColumns = () => {
    if (!trainId) {
      columns.splice(3, 0, trainColumn)
    }
    return columns
  }

  const onPreviewClick = (e: any, data: any) => {
    e.preventDefault()
    e.stopPropagation()
    setShowInspector(true)
    setSelectedWagon(data)
  }

  return (
    <div className={'flex flex-col'}>
      <>
        <List
          actionButton={getActionButton()}
          columns={getColumns()}
          tableName={'wagonTable'}
          data={wagons}
          reload={reloadTable}
          pagination={true}
          totalPages={totalPages}
          onRowClick={onClick}
          getFilteredData={getFilteredData}
          itemsPerPage={0}
          importItem={
            isConstructionManager ? importControlAreaTemplate : undefined
          }
          sortBackend={true}
          filterResetOption={true}
          isExpandedElement={isExpandedElement}
          exportQrCodes={
            projectConfig
              ? wagonListQRExportTemplate(`projects/${projectId}`)
              : trainId &&
                wagonListQRExportTemplate(`construction_trains/${trainId}`)
          }
          exportTemplate={
            projectConfig
              ? wagonListExportTemplate(`projects/${projectId}`)
              : trainId &&
                wagonListExportTemplate(`construction_trains/${trainId}`)
          }
          onPreviewClick={onPreviewClick}
          actionMenu={
            isConstructionManager
              ? [
                  {
                    name: t('delete'),
                    action: onDelete,
                  },
                ]
              : undefined
          }
        />
      </>

      {formModal ? (
        <WagonForm
          open={formModal}
          parentId={trainId ? trainId : train && train.id ? train.id : 0}
          train={train}
          reloadParent={() => {
            reload()
          }}
          closeModal={() => {
            reload()
            setFormModal(false)
          }}
        />
      ) : null}

      {selectedWagon && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={() => setShowDeleteModal(false)}
          onDelete={onDeleteItemClick}
          itemIdnType={`${selectedWagon.record_id} (${t('wagon')})`}
          itemName={`${selectedWagon.record_id} - ${selectedWagon.title}`}
        />
      )}

      <div className={showInspector ? 'h-[380px]' : ''}>
        {selectedWagon && showInspector && (
          <WagonModal
            className={'h-[350px]'}
            open={showInspector}
            onClose={() => {
              setShowInspector(false)
              reload()
            }}
            onUpdate={reload}
            defaultIndex={0}
            wagonId={selectedWagon.id ? selectedWagon.id : 0}
            projectId={projectContext.state.currentProject.id}
          />
        )}
      </div>
      {loading && !isExpandedElement ? <Loader /> : null}
    </div>
  )
}

export default withProjectContext(WagonList)

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import TableSelectorEditor from 'src/components/table-ResponsibleEditor/TableSelectorEditor'
import Step from 'src/document/components/FolderDocument/Step'
import { IFileContainer } from 'src/document/types/IFileContainer'
import { IStepData } from 'src/document/types/IFlow'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import List from 'src/ui-elements/list/List'
import { IListColumns, ISelectType } from 'src/ui-elements/list/ListTypes'
import useProjectId from '../../../../components/hooks/useProjectId'
import { getStep } from '../../../../service/FileContainerService'
import { IUploadResponse } from '../types'

interface ImportedFilesListProps {
  uploadedFiles: UploadedFiles
  setMovedStep: (stepId: IStepData, docIds: number[]) => void
}

export interface UploadedFile extends IUploadResponse {
  fileName: string
}
interface UploadedFiles {
  [key: string]: IUploadResponse
}

type IStep = Pick<IStepData, 'id' | 'name' | 'sequence'>
type ImportList = (UploadedFile | IStep)[]

const selectStep = () => {
  return (
    <div className="flex justify-between">
      <div className="flex items-center">
        <MaterialIcon
          outline={true}
          icon={'do_not_disturb_on'}
          className="text-d-grey-light"
        />
        <span className="pl-4 text-d-grey-light font-extrabold text-xs uppercase">
          Select Step
        </span>
      </div>
      <MaterialIcon
        outline={true}
        icon={'expand_more'}
        className="text-d-fontchip"
      />
    </div>
  )
}

const ImportedFilesList: FC<ImportedFilesListProps> = ({
  uploadedFiles,
  setMovedStep,
}) => {
  const projectId = useProjectId()
  const [uploadedList, setUploadedList] = useState<ImportList>([])
  const [nextStep, setNextStep] = useState<any>({})
  const [reloadKey, setReloadKey] = useState(false)
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([])
  const [selectedStep, setSelectedStep] = useState<number>()

  const ref = useRef(uploadedList)
  const nextStepRef = useRef(nextStep)
  const selectedRef = useRef(selectedDocuments)

  const { t } = useMultipleKeysTranslation()

  const loadListData = useCallback(() => {
    const stepNames = Object.values(uploadedFiles).map((file) => {
      const { file_container: cont } = file
      return {
        name: cont?.current_step?.name ?? '',
        id: cont?.current_step?.id ?? 0,
        sequence: cont?.current_step?.sequence ?? 0,
      }
    })
    // get unique steps
    const uniqueStepNames = stepNames.filter(
      (step, i, a) => a.findIndex((s) => s.id === step.id) === i,
    )
    const files: (IStep | UploadedFile)[] = []
    uniqueStepNames.forEach((step) => {
      const fileNames = Object.keys(uploadedFiles).filter((fileName) => {
        const file: UploadedFile = { ...uploadedFiles[fileName], fileName }
        return file.file_container?.current_step?.id === step.id
      })
      files.push(step)
      files.push(
        ...fileNames.map((fileName) => ({
          ...uploadedFiles[fileName],
          fileName,
        })),
      )
    })
    if (uniqueStepNames.length === 0) {
      files.push({ name: t(['no', 'step']), id: -1, sequence: 0 })
      Object.keys(uploadedFiles).forEach((fileName) => {
        const file: UploadedFile = { ...uploadedFiles[fileName], fileName }
        files.push(file)
      })
    }

    setUploadedList(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles])

  useEffect(() => {
    loadListData()
  }, [loadListData])

  useEffect(() => {
    ref.current = uploadedList
    nextStepRef.current = nextStep
    selectedRef.current = selectedDocuments
  })

  const onStepChange = (docId: number, newStep: any) => {
    const cloneNext = { ...nextStepRef.current }
    const changeDocIds = [docId]
    selectedRef.current?.map((id) => {
      const item = ref.current.find((row) => !isStep(row) && row.id === id)
      if (item && !isStep(item) && item.file_container_id) {
        changeDocIds.push(item.file_container_id)
      }
    })
    changeDocIds.forEach((id) => {
      cloneNext[id] = newStep
    })
    setNextStep(cloneNext)
    setMovedStep(newStep, changeDocIds)
    setReloadKey((n) => !n)
  }

  const onRowDisable = (row: IStep | UploadedFile, _selectedStep?: number) => {
    if (!_selectedStep) return false
    if (isStep(row)) {
      return row.id !== selectedStep
    }
    return row.file_container?.current_step?.id !== _selectedStep
  }

  const isStep = (row: IStep | UploadedFile): row is IStep => {
    return (row as IStep).sequence !== undefined
  }

  const onSelect = (
    _selected: number[],
    data: ImportList,
    _clickedRow?: ISelectType,
  ) => {
    if (!_clickedRow || _clickedRow?.isHeader) return
    if (!_selected.length) {
      unSelectAll()
    }

    if (isStep(_clickedRow.rowData)) {
      if (_clickedRow.rowSelected) {
        setSelectedStep(_clickedRow.rowData.id)
        selectAllSteps(_clickedRow.rowData.id, data)
      } else {
        unSelectAll()
      }
    } else {
      selectStepOfRow(_selected[0], data)
      setSelectedDocuments(_selected)
    }
  }

  const unSelectAll = () => {
    setSelectedDocuments([])
    setSelectedStep(undefined)
  }

  const selectAllSteps = (stepId: number, data: ImportList) => {
    const stepData = data.filter(
      (row) => !isStep(row) && row.file_container?.current_step?.id === stepId,
    )
    setSelectedDocuments([...stepData.map((row) => row.id ?? -1), stepId])
  }

  const selectStepOfRow = (id: number, data: ImportList) => {
    const d = data.filter((row) => row.id === id).pop()
    if (d && !isStep(d)) {
      setSelectedStep(d.file_container?.current_step?.id)
    }
  }

  const columns: IListColumns[] = [
    {
      size: '300',
      id: 'uploaded_file',
      name: 'uploaded_file',
      dataField: 'fileName',
      cell: (name: string, _: number, index: number) => {
        const file = ref.current[index]
        if (isStep(file)) {
          return (
            <span className="text-d-grey-light font-extrabold text-xs uppercase">
              {file.name}
            </span>
          )
        }
        return <span>{name}</span>
      },
    },
    {
      size: '300',
      id: 'target_document',
      name: 'target_document',
      dataField: 'name',
      cell: (name: string, _: number, index: number) => {
        const file = ref.current[index]
        if (!isStep(file)) {
          return <span>{name}</span>
        }
        return <></>
      },
    },
    {
      size: '250',
      id: 'current_step',
      name: 'current_step',
      dataField: 'file_container',
      cell: (container?: IFileContainer, _?: number, index?: number) => {
        const file = ref.current[index ?? 0] ?? ''
        if (!isStep(file) && container?.current_step) {
          return <Step step={container.current_step} />
        }
        return <></>
      },
    },
    {
      size: '250',
      id: 'next_step',
      name: 'next_step',
      dataField: 'file_container',
      cell: (container?: IFileContainer, _?: number, index?: number) => {
        const file = ref.current[index ?? 0] ?? ''
        if (isStep(file) || !file.file_container_id || !container?.current_step)
          return <></>
        const nextStep = nextStepRef.current[file.file_container_id ?? 0] ?? ''
        return (
          <TableSelectorEditor
            rowId={file.file_container_id}
            selected={file.file_container?.current_step_id}
            getData={() =>
              getStep(
                projectId,
                file.file_container_id ?? 0,
                file.file_container?.current_step?.id ?? 0,
              )
            }
            dataFields={['name']}
            isUserSelector={false}
            showCancelButton={true}
            onDataSelected={(d) => onStepChange(file.file_container_id ?? 0, d)}
            displayContent={nextStep ? <Step step={nextStep} /> : selectStep()}
          />
        )
      },
    },
  ]

  return (
    <div key={reloadKey ? '1' : '2'}>
      <List
        columns={columns}
        disableColumnSelector={true}
        tableName={'ImportedFilesList'}
        disableConfigStore={true}
        data={uploadedList}
        isRowSelectable={true}
        onSelectRow={(d, c) => onSelect(d, uploadedList, c)}
        selectedRows={selectedDocuments}
        onRowDisable={(_: number, index: number) =>
          onRowDisable(uploadedList[index], selectedStep)
        }
        onRowClick={() => {
          /*noop */
        }}
        itemsPerPage={0}
      />
    </div>
  )
}

export default ImportedFilesList

import moment from 'moment'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { TabPanel } from 'react-tabs'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import useProjectId from 'src/components/hooks/useProjectId'
import UserIcon from 'src/components/user/UserIcon'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { getConstructionWagon } from 'src/service/ConstructionWagonService'
import { IConstructionWagon, ITaskData, IUserData } from 'src/service/OrgTypes'
import { editTask, getTask } from 'src/service/TaskService'
import { getUser } from 'src/service/UserService'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { TabsWrapper } from 'src/ui-elements/tabs/Tabs'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { capFirstLetter, classNames } from '../../utility/utils'
import { contentStyle } from '../MUtils'
import MCard from '../components/MCard'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MRowDisplay, { IMRowDisplayData } from '../components/MRowDisplay'
import MVognInfo from './MVognInfo'

const MIssueDetail = () => {
  const styleClass = {
    container: classNames('overflow-y-auto'),
    errorMessage: classNames('text-red-600', 'my-2', 'ml-2', 'text-xs'),
  }

  const { t } = useTranslation()
  const history = useHistory()
  const { iid, wid } = useParams<{ iid: string; wid: string }>()
  const userContext = React.useContext(UserContext)
  const projectContext = React.useContext(ProjectContext)
  const [reload, setReload] = useState<boolean>(false)
  const [issue, setIssue] = React.useState<ITaskData>({} as ITaskData)
  const [wagon, setWagon] = React.useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [reporter, setReporter] = React.useState<IUserData>({} as IUserData)
  const [isLoading, setIsLoading] = React.useState<boolean>(true)
  const [tabIndex, setTabIndex] = useState<number>(0)

  const onBackClick = () => {
    history.goBack()
  }

  const projectId = useProjectId()

  useEffect(() => {
    setIsLoading(true)
    getTask(projectId, +iid).then((res) => {
      setIssue(res)
      getUser(res.reporter_id).then((response) => setReporter(response))
      setIsLoading(false)
    })
    if (!isNaN(+wid)) {
      getConstructionWagon(+wid).then((res) => {
        setWagon(res)
      })
    }
  }, [projectContext, reload])

  const getStatusColor = (task: ITaskData) => {
    return task.status === 'done'
      ? Badge.BadgeColor.GREEN
      : moment(task.status).isBefore(moment(), 'day')
        ? Badge.BadgeColor.RED
        : Badge.BadgeColor.BLUE
  }

  const headerRowData = (): IMRowDisplayData => [
    { label: t('title'), value: issue?.title },
    { label: t('description'), value: issue?.description },
    {
      label: t('status'),
      value: (
        <Badge
          text={t(issue.status)}
          size={Badge.BadgeSize.LARGE}
          color={getStatusColor(issue)}
        />
      ),
    },
    {
      label: t('deadline'),
      value: issue.deadline
        ? moment(issue.deadline).format('ddd MMM DD YYYY')
        : '',
    },
    { label: t('discipline'), value: issue?.discipline?.name },
    {
      label: t('responsible'),
      value: (
        <UserIcon
          userId={issue.responsible?.id}
          firstName={issue?.responsible?.firstName}
          lastName={issue?.responsible?.lastName}
          image_url={issue?.responsible?.image_url}
        />
      ),
    },
    {
      label: t('created_by'),
      value: (
        <UserIcon
          userId={reporter?.id}
          firstName={reporter?.firstName}
          lastName={reporter?.lastName}
          image_url={reporter?.image_url}
        />
      ),
    },
    {
      label: t('created_at'),
      value: moment(issue?.created_at).format('ddd MMM DD YYYY'),
    },
    {
      label: t('category'),
      value:
        issue.parent_type === 'CheckInParameter'
          ? t('check_in_check_out')
          : issue.parent_type === 'ConstructionLocomotive'
            ? capFirstLetter(t('wagon'))
            : issue.parent_type === 'ControlArea'
              ? capFirstLetter(t('control_area'))
              : capFirstLetter(t('board_meeting')),
    },
    { label: t('control_area'), value: issue?.control_area?.title ?? '' },
    { label: t('wagon'), value: issue?.construction_locomotive?.title ?? '' },
    { label: t('room'), value: issue?.location?.room_name ?? '' },
  ]

  const onMarkComplete = () => {
    const clone = { ...issue }
    clone.status = 'done'
    editTask(clone).then(() => setReload((prev) => !prev))
  }

  return (
    <MPage bgColor={'mobile'}>
      <>
        <MHeader
          showHomeBtn={true}
          showUser={false}
          title={t('issue_details')}
          onBackClick={onBackClick}
          user={userContext.state.user}
        />
        <div style={contentStyle} className={styleClass.container}>
          {isLoading ? (
            <div className={'w-full h-full grid place-items-center'}>
              <SpinnerMobile />
            </div>
          ) : (
            <>
              <div className={'bg-gray-200 mb-4 px-3  py-6 z-50'}>
                <MCard>
                  {wagon && !isEmpty(wagon) && (
                    <MVognInfo wagon={wagon} noPadding={true} />
                  )}
                  <MRowDisplay
                    noPaddingX={true}
                    data={headerRowData()}
                    className={'pb-0 pt-0'}
                  />
                  {issue.status !== 'done' && (
                    <Button
                      onClick={onMarkComplete}
                      inMobile={true}
                      buttonType={Button.ButtonType.SUCCESS}
                      size={ButtonSize.XSMALL}
                    >
                      <p className={'text-green-400'}>
                        {t('mark_it_as_complete')}
                      </p>
                      <Icon
                        className={'w-6 h-6 ml-2 flex items-center'}
                        icon={Icons.CHECK_GREEN_CIRLCE}
                      />
                    </Button>
                  )}
                </MCard>
              </div>
              <div className={'mb-4 bg-gray-100 px-6'}>
                <TabsWrapper
                  tabIndex={tabIndex}
                  tabItems={[t('comments'), t('change_log')]}
                  onSelect={setTabIndex}
                >
                  <TabPanel>
                    <Comments parentId={issue.id} parentType="Task" />
                  </TabPanel>
                  <TabPanel>
                    <ChangeLog parentId={issue.id} parentType="Task" />
                  </TabPanel>
                </TabsWrapper>
              </div>
            </>
          )}
        </div>
      </>
    </MPage>
  )
}

export default MIssueDetail

import Edit from '@material-symbols/svg-500/rounded/edit.svg'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { checkInColor } from 'src/components/construction/wagon/CheckListDetail'
import {
  ITabContext,
  TabContext,
} from 'src/context/TabContextProvider/TabContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { getStatusColor } from 'src/mobile/pages/MWagonDetail'
import { getPrevNextWagon } from 'src/service/ConstructionWagonService'
import { getControlAreaWithCurrentVogns } from 'src/service/ControlAreaService'
import { IConstructionWagon } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import Icon from 'src/ui-elements/icon/Icon'
import useAlert from 'src/ui-elements/toast/useAlert'
import { capFirstLetter } from '../../utility/utils'
import { contentStyle, FullPageLoader } from '../MUtils'
import MCard from '../components/MCard'
import MChips from '../components/MChips'
import MContent from '../components/MContent'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MTabs, { IMTabItem } from '../components/MTabs'
import MCustomIssues, { CustomIssueTypes } from './MCustomIssues'

const prepareData = (wagon: IConstructionWagon, history: any, t: any) => {
  const checkIns =
    wagon.activity_disciplines?.map((discipline) => {
      const checkIn = wagon.check_ins?.find(
        (checkIn) =>
          checkIn.is_check_in && checkIn.discipline_id === discipline.id,
      )
      return {
        left: discipline.name,
        right: (
          <Badge
            text={!!checkIn ? t('yes') : t('no')}
            color={
              checkIn ? (checkInColor(checkIn) as BadgeColor) : BadgeColor.BLUE
            }
            size={Badge.BadgeSize.SMALL}
          />
        ),
        noBold: true,
      }
    }) ?? []
  const checkOuts =
    wagon.activity_disciplines?.map((discipline) => {
      const checkIn = wagon.check_ins?.find(
        (checkIn) =>
          !checkIn.is_check_in && checkIn.discipline_id === discipline.id,
      )
      return {
        left: discipline.name,
        right: (
          <Badge
            text={!!checkIn ? t('yes') : t('no')}
            color={
              checkIn ? (checkInColor(checkIn) as BadgeColor) : BadgeColor.BLUE
            }
            size={Badge.BadgeSize.SMALL}
          />
        ),
        noBold: true,
      }
    }) ?? []
  return [
    { left: t('wagon'), right: `${wagon.title}` },
    { left: t('description'), right: `${wagon.subject}` },
    {
      left: t('start_date'),
      right: moment(wagon.startTime).format('ddd MMM DD YYYY'),
    },
    {
      left: t('end_date'),
      right: moment(wagon?.startTime)
        .add(wagon?.extended_duration ? wagon.extended_duration - 1 : 0, 'days')
        .format('ddd MMM DD YYYY'),
    },
    { left: t('duration'), right: wagon.duration + ` (${t('days')})` },
    {
      left: t('working_hours'),
      right: !Number.isNaN(wagon?.planned_staffing)
        ? Number(wagon?.planned_execution_hours).toFixed(1)
        : 0,
    },
    {
      left: t('number_of_workers'),
      right: !Number.isNaN(wagon?.planned_staffing)
        ? Number(wagon?.planned_staffing).toFixed(1)
        : '0',
    },
    {
      left: t('checked_in'),
      right: wagon.check_ins?.some((checkIn) => checkIn.is_check_in) ? (
        <div
          className="p-2 border bg-blue-100 text-blue-500 font-medium rounded-full cursor-pointer flex justify-between"
          onClick={() => {
            history.push(`/mobile/${wagon.id}/checks`)
          }}
        >
          <span className="first-capitalize text-sm">{t('details')}</span>
          <Edit className="text-lg fill-blue-500" />
        </div>
      ) : (
        <></>
      ),
    },
    ...checkIns,
    {
      left: t('checked_out'),
      right: wagon.check_ins?.some((checkIn) => !checkIn.is_check_in) ? (
        <div
          className="p-2 border bg-blue-100 text-blue-500 font-medium rounded-full cursor-pointer flex justify-between"
          onClick={() => {
            history.push(`/mobile/${wagon.id}/checks`)
          }}
        >
          <span className="first-capitalize text-sm">{t('details')}</span>
          <Edit className="text-lg fill-blue-500" />
        </div>
      ) : (
        <></>
      ),
      underline: true,
    },
    ...checkOuts,
    {
      left: t('status'),
      right: (
        <Badge
          text={t(getStatusColor(wagon) ?? 'not_checked_in')}
          color={
            (getStatusColor(wagon) as BadgeColor | undefined) ??
            BadgeColor.DEFAULT
          }
          size={Badge.BadgeSize.SMALL}
        />
      ),
      underline: true,
    },
  ]
}

const MControlArea = (_props: any) => {
  moment.tz.setDefault('Europe/Oslo')

  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  const history = useHistory()
  const { caid } = useParams<{ caid: string }>()
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any[]>([])
  const [wagons, setWagons] = useState<IConstructionWagon[]>([])
  const tabContext: ITabContext = useContext(TabContext)
  const { tabIndex } = tabContext.state
  const { setCurrentIndex } = tabContext.actions

  useEffect(() => {
    fetchControlArea(caid)
  }, [])

  const fetchControlArea = async (id: string) => {
    if (id) {
      setLoading(true)
      const controlArea = await getControlAreaWithCurrentVogns(+id)
      const dataRow = [
        { id: controlArea.id },
        { currentWeekWagons: controlArea?.this_weeks_locomotives },
        { left: t('control_area'), right: `${controlArea.title}` },
        { left: t('area'), right: `${controlArea.total_area}` },
        {
          left: t('control_area_group'),
          right: `${controlArea?.control_area_group?.title}`,
        },
      ]
      setData(dataRow)
      const wagons = controlArea?.this_weeks_locomotives
      if (wagons && wagons.length > 0) {
        setWagons(wagons)
      }
      setLoading(false)
    }
  }

  const getWeekTitle = (item: IConstructionWagon) => {
    const startDate = moment(item.startTime)
    const endDate = moment(item.endTime)
    const startWeek = startDate.isoWeek()
    const endWeek = endDate.isoWeek()
    if (endWeek === startWeek) {
      return t('week_number_w_start_week', { startWeek })
    } else {
      return t('week_number_w_start_and_end_week', { startWeek, endWeek })
    }
  }

  const getHeader = (week: string): JSX.Element => {
    return (
      <div className={'flex justify-end mb-2'}>
        <MChips text={week} color={'green'} />
      </div>
    )
  }

  const NoVognErrorMessage = (): JSX.Element => (
    <div
      className={
        'shadow-sm p-3 rounded-xl bg-m-warning-yellow text-sm text-gray-600 flex font-light'
      }
    >
      <Icon icon={Icon.IconType.WARNING_YELLOW} className={'w-4 h-4 mr-3'} />
      <span className={'font-medium'}>{t('note')}:&nbsp;</span>&nbsp;
      {capFirstLetter(t('wagon'))}
      <span className={'font-semibold'}>&nbsp;{t('not_caps')}&nbsp;</span>{' '}
      {t('found_for_this_week')}
    </div>
  )

  const onWagonClick = (wagon: IConstructionWagon) => {
    history.push(`/mobile/wagons/${wagon.id}?tabIndex=0`)
  }

  const getNeighbouringWagon = (
    wagon: IConstructionWagon,
    index: number,
    prev: boolean,
  ) => {
    if (wagon && wagon.id) {
      if (
        (prev && wagon.has_previous === false) ||
        (!prev && wagon.has_next === false)
      ) {
        addAlert({
          type: 'error',
          title: t('an_error_occurred'),
          description: t('no_wagon_found'),
          autoClose: true,
        })
        return
      }
      getPrevNextWagon(wagon.id, prev ? 'previous' : 'next').then((wagon) => {
        if (wagon && wagon?.message === 'Item not found') {
          addAlert({
            type: 'error',
            title: t('an_error_occurred'),
            description: t('no_wagon_found'),
            autoClose: true,
          })
          return
        }
        const clone = [...wagons]
        clone[index] = wagon
        setWagons(clone)
      })
    }
  }

  const footer = (wagon: IConstructionWagon, key: number) => (
    <div className={'mt-2'}>
      <Button
        width={'100%'}
        onClick={() => onWagonClick(wagon)}
        inMobile={true}
        size={Button.ButtonSize.XSMALL}
        type={Button.ButtonType.PRIMARY}
      >
        {t('go_to_wagon')} &gt;{' '}
      </Button>
      <div className={'w-full flex justify-between'}>
        <Button
          width={'40%'}
          disabled={wagon?.has_previous === false}
          onClick={() => getNeighbouringWagon(wagon, key, true)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          ❮ {t('previous')}
        </Button>
        <Button
          width={'40%'}
          disabled={wagon?.has_next === false}
          onClick={() => getNeighbouringWagon(wagon, key, false)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          {t('next')} ❯
        </Button>
      </div>
    </div>
  )

  const ControlAreaDetail = () =>
    loading ? (
      <FullPageLoader />
    ) : (
      <MContent>
        <>
          {data?.length > 0 ? (
            <div className={'p-6 pt-4'}>
              <p className={'text-gray-600 text-sm mb-1 first-capitalize'}>
                {t('selected_control_area')}
              </p>
              <MCard data={data} />
              <div className={'my-4'}>
                {wagons.length > 0 ? (
                  <>
                    <p className={'text-gray-600 text-sm mb-1'}>{`Vogner`}</p>
                    <div
                      className={
                        'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'
                      }
                    >
                      {wagons.map((wagon, key: number) => (
                        <MCard
                          key={key}
                          data={prepareData(wagon, history, t)}
                          header={getHeader(getWeekTitle(wagon))}
                          footer={footer(wagon, key)}
                        />
                      ))}
                    </div>
                  </>
                ) : (
                  <NoVognErrorMessage />
                )}
              </div>
            </div>
          ) : (
            <div className={'flex justify-center m-8'}>
              <Badge
                text={t('no_results_found')}
                size={Badge.BadgeSize.LARGE}
                color={Badge.BadgeColor.TEAL}
              />
            </div>
          )}
        </>
      </MContent>
    )

  const tabItems: IMTabItem[] = [
    {
      name: t('control_area'),
      content: <ControlAreaDetail />,
      icon: 'CONSTRUCTION_GRAY',
      activeIcon: 'CONSTRUCTION_BLUE',
      disabledIcon: 'CONSTRUCTION_GRAY',
    },
    {
      name: t('cases_native'),
      content: (
        <MCustomIssues
          parentId={+caid}
          parentType={CustomIssueTypes.ControlArea}
        />
      ),
      icon: 'ISSUE',
      activeIcon: 'ISSUE_ACTIVE',
      disabledIcon: 'ISSUE_DISABLED',
    },
  ]

  return (
    <MPage>
      <>
        <MHeader
          title={t('control_area_details')}
          onBackClick={() => history.goBack()}
          user={user}
        />
        <div
          style={tabIndex >= 0 ? contentStyle : {}}
          className={`w-full ${tabIndex >= 0 ? 'absolute bottom-0 z-20' : ''}`}
        >
          <MTabs
            tabIndex={tabIndex}
            tabItems={tabItems}
            setTabIndex={(index) => setCurrentIndex(index)}
          />
        </div>
      </>
    </MPage>
  )
}

export default MControlArea

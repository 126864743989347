import { IBimServiceRequest } from 'src/page/bimsync/types/IBimServiceRequest'
import { getSessionShareToken } from 'src/utility/shareToken'
import {
  getBimServiceBaseUrl,
  getCurrentProject,
  getOrganizationBaseUrl,
  getTableKeeperBaseUrl,
} from '../utility/utils'
import { updateWallToken } from './TheWallService'

export const request = <T = any>(
  url: string,
  method: string,
  body?: any,
  text?: boolean,
  shareToken?: string,
): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    const maybeShareToken = shareToken ?? getSessionShareToken()
    const token = !maybeShareToken ? await updateWallToken() : undefined
    return fetch(`${getOrganizationBaseUrl()}${url}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        ...(maybeShareToken && { 'Share-Token': maybeShareToken }),
        'Project-Id': getCurrentProject() ?? '',
      },
      body: JSON.stringify(body),
      method: method ? method : 'GET',
    })
      .then(async (res: Response) => {
        if (res.status === 404) {
          const val = await res.json()
          return { status: 404, message: val.message }
        }
        if (res.status >= 400 && res.status < 600) {
          return reject(res.json())
        }
        return text ? res.text() : res.json()
      })
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })
}

export const modularRequest = <T = any>(
  url: string,
  {
    method,
    body,
    text,
  }: {
    method?: string
    body?: any
    text?: boolean
  } = { method: 'GET' },
): Promise<T> => {
  return new Promise((resolve, reject) => {
    updateWallToken().then((token) => {
      return fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Project-Id': getCurrentProject() ?? '',
        },
        body: JSON.stringify(body),
        method: method ? method : 'GET',
      })
        .then(async (res: any) => {
          if (res.status === 404) {
            const val = await res.json()
            return { status: 404, message: val.message }
          }
          if (res.status >= 400 && res.status < 600) {
            return reject('Bad response from server')
          }
          if (res.headers.get('Content-Length') === '0' || res.status === 204) {
            return
          } else {
            return text ? res.text() : res.json()
          }
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  })
}

export const tableKeeperRequest = (
  url: string,
  method: string,
  body?: any,
  text?: boolean,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    updateWallToken().then((token) => {
      return fetch(`${getTableKeeperBaseUrl()}${url}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'Project-Id': getCurrentProject() ?? '',
        },
        body: JSON.stringify(body),
        method: method ? method : 'GET',
      })
        .then(async (res: any) => {
          if (res.status === 404) {
            const val = await res.json()
            return { status: 404, message: val.message }
          }
          if (res.status >= 400 && res.status < 600) {
            return reject('Bad response from server')
          }

          return text ? res.text() : res.json()
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  })
}

export const getDocBaseUrl = (url: string): string => {
  return `${getOrganizationBaseUrl()}/projects/${getCurrentProject()}/${url}`
}

export const getOrgBaseUrl = (url: string) => {
  return `${getOrganizationBaseUrl()}/projects/${getCurrentProject()}/${url}`.replace(
    '{ORG_URL}',
    '',
  )
}

export const getSubcontractorOrgUrl = (url: string) => {
  return `${getOrganizationBaseUrl()}/subcontractors/${url}`.replace(
    '{SUBCONTRACTOR}',
    '',
  )
}

export const bimServiceRequest = ({
  url,
  method,
  body,
  headers,
  isBlob = false,
  isFile = false,
  isText = false,
}: IBimServiceRequest): Promise<any> => {
  return new Promise((resolve, reject) => {
    updateWallToken().then((token) => {
      return fetch(`${getBimServiceBaseUrl()}${url}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        body: isFile ? (body as File) : JSON.stringify(body),
        method: method ?? 'GET',
      })
        .then(async (res: any) => {
          if (res.status === 404) {
            const val = await res.json()
            return { status: 404, message: val.message }
          }
          if (res.status >= 400 && res.status < 600) {
            return reject('Bad response from server')
          }

          if (isBlob) return res.blob()
          if (isText) return res.text()
          return res.json()
        })
        .then((data) => resolve(data))
        .catch((err) => reject(err))
    })
  })
}

export const FdvuSubcontractorRequest = <T = any>(
  url: string,
  accessToken?: string,
  {
    method,
    body,
    text,
    blob,
  }: {
    method?: string
    body?: any
    text?: boolean
    blob?: boolean
  } = { method: 'GET' },
): Promise<T> => {
  return new Promise((resolve, reject) => {
    return fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: accessToken || '',
        'Project-Id': getCurrentProject() ?? '',
      },
      body: blob ? (body as File) : JSON.stringify(body),
      method: method || 'GET',
    })
      .then(async (res: any) => {
        if (res.status === 404) {
          const val = await res.json()
          return { status: 404, message: val.message }
        }
        if (res.status >= 400 && res.status < 600) {
          return reject('Bad response from server')
        }
        return text ? res.text() : blob ? res.blob() : res.json()
      })
      .then((data) => resolve(data))
      .catch((err) => reject(err))
  })
}

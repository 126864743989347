import { useQueryClient } from '@tanstack/react-query'
import {
  ColumnFiltersState,
  RowSelectionState,
  VisibilityState,
} from '@tanstack/react-table'
import { FC, useMemo, useState } from 'react'
import SystemInspectorPanel from 'src/components/system/SystemInspectorPanel'
import { useSystemTableColumns } from 'src/components/system/system-table/useSystemTableColumns'
import { useSystemMetaData } from 'src/query/systems/syntaxMetadata'
import { useSystemListFiltered } from 'src/query/systems/systems'
import { ISystem } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import { classNames } from 'src/utility/utils'

interface ISystemsListProps {
  tableName: string
  onSelectedSystems: (systems: number[]) => void
  initialSelection?: number[]
  onRowClick?: (system: ISystem) => void
  filter?: Omit<TableFilter, 'sort'>
  isSingleSelect?: boolean
  defaultFilter?: ColumnFiltersState
}

const SystemSelectTable: FC<ISystemsListProps> = ({
  tableName,
  onSelectedSystems,
  initialSelection,
  filter,
  onRowClick,
  isSingleSelect = false,
  defaultFilter,
}) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }
  const [selectedSystem, setSelectedSystem] = useState<number>()
  const [selectionState, setSelectionState] = useState<RowSelectionState>({})
  const [showInspector, setShowInspector] = useState(false)
  const { data: metaData } = useSystemMetaData()

  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['systemListFiltered'] })
  }

  const handleSelectedSystems = (rows: RowSelectionState) => {
    setSelectionState(rows)
    onSelectedSystems(Object.keys(rows).map((row) => +row))
  }

  const { defaultOrdering, legacyColumns, newColumns } = useSystemTableColumns({
    writeAccess: false,
  })

  const handlePreviewClick = (data: ISystem) => {
    if (data.id) {
      setSelectedSystem(data.id)
    }
    setShowInspector(true)
  }

  const initialColumnVisibility = useMemo(
    () =>
      metaData?.reduce((acc, field) => {
        acc[`meta_data_${field?.id}`] = false
        return acc
      }, {} as VisibilityState),
    [metaData],
  )

  return (
    <div className={styleClass.root}>
      <Table
        selectionState={selectionState}
        name={tableName}
        selectable={!isSingleSelect}
        setSelectedRowsParent={handleSelectedSystems}
        onRowClick={onRowClick}
        useDataQuery={useSystemListFiltered}
        legacyColumns={legacyColumns}
        onPreviewClick={handlePreviewClick}
        columns={newColumns}
        defaultOrdering={defaultOrdering}
        initialColumnVisibility={initialColumnVisibility}
        initialSelectedRowIds={initialSelection}
        initialFilter={filter}
        defaultFilter={defaultFilter}
        disableTableKeeper={!!defaultFilter}
      />
      {selectedSystem && showInspector && (
        <SystemInspectorPanel
          systemId={selectedSystem}
          open={showInspector}
          onClose={() => setShowInspector(false)}
          onUpdate={reload}
        />
      )}
    </div>
  )
}

export default SystemSelectTable

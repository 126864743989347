import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import MConstructionTaskItem from 'src/mobile/components/MConstructionTaskItem'
import { getConstructionWagonTaskFilter } from 'src/service/ConstructionTaskService'
import { getConstructionWagon } from 'src/service/ConstructionWagonService'
import { IConstructionTask, IConstructionWagon } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { classNames } from '../../utility/utils'
import MVognInfo from './MVognInfo'

interface IMWorkOps {
  onAddWorkOps: () => void
}

const MWorkOps = ({ onAddWorkOps }: IMWorkOps) => {
  useEffect(() => {
    getTasks()
  }, [])

  const { vid } = useParams<{ caid: string; vid: string }>()

  const getTasks = async () => {
    if (vid) {
      setLoading(true)
      const wagonTasks = await getConstructionWagonTaskFilter(+vid, {}, 1, 50)
      setTasks(wagonTasks.tasks)
      await getConstructionWagon(+vid).then((res) => setWagon(res))
      setLoading(false)
    }
  }

  const styleClass = {
    root: classNames('bg-mobile'),
  }

  const { t } = useTranslation()
  const [wagon, setWagon] = useState<IConstructionWagon>()
  const [tasks, setTasks] = useState<IConstructionTask[]>()
  const [loading, setLoading] = useState<boolean>(false)

  return (
    <div className={styleClass.root}>
      {wagon && (
        <MVognInfo
          isWorkOps={true}
          wagon={wagon}
          onFloatingBtnClick={onAddWorkOps}
        />
      )}
      {loading ? (
        <div className={'w-full h-full grid place-items-center'}>
          <SpinnerMobile />
        </div>
      ) : (
        <section className={'flex flex-col'}>
          {tasks && tasks.length > 0 ? (
            <div
              className={
                'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 md:px-4 md:py-2'
              }
            >
              {wagon &&
                wagon.activity_disciplines?.map((discipline) => {
                  const tasksInDiscipline = tasks.filter(
                    (task) => task.discipline_id === discipline.id,
                  )
                  return (
                    <div key={discipline.id}>
                      <span className="ml-2 block w-full truncate font-semibold text-gray-600">
                        {discipline.name}
                      </span>
                      {tasksInDiscipline.map((task) => (
                        <MConstructionTaskItem
                          key={task.id}
                          task={task}
                          wagon={wagon}
                        />
                      ))}
                    </div>
                  )
                })}
            </div>
          ) : (
            <Badge
              text={t('no_results_found')}
              size={Badge.BadgeSize.LARGE}
              color={Badge.BadgeColor.YELLOW}
            />
          )}
        </section>
      )}
    </div>
  )
}

export default MWorkOps

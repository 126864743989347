import Cancel from '@icons/cancel-fill.svg'
import moment, { Moment } from 'moment'
import * as React from 'react'
import { useState, MouseEvent, useEffect } from 'react'
import DateTime from 'react-datetime'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

interface IDateTimeInput {
  id?: string
  date?: string | Moment
  width?: number
  includeTime?: boolean
  onSave: (newValue?: string) => void
  validate?: (newTime?: Moment) => string | undefined
  setErrorMessage?: (error: string) => void
  disabled?: boolean
  cancelButton?: boolean
  inputClassName?: string
  deadline?: boolean
}

const DateTimeInput: React.FC<IDateTimeInput> = ({
  id,
  date,
  width,
  includeTime = false,
  onSave,
  validate,
  setErrorMessage,
  disabled = false,
  cancelButton,
  inputClassName,
  deadline = false,
}) => {
  const { t, i18n } = useTranslation()

  const [selectedDate, setSelectedDate] = useState<
    string | Moment | undefined
  >()
  const [hasError, setHasError] = useState(false)
  const [reload, setReload] = useState(0)

  useEffect(() => {
    setSelectedDate(date ? moment(date) : undefined)
    setReload((r) => (r === 0 ? 1 : 0))
  }, [date])

  const clearSelection = () => {
    setSelectedDate(undefined)
    setErrorMessage?.('')
    onSave(undefined)
    setReload((r) => (r === 0 ? 1 : 0))
  }

  const getErrorMessage = (value?: Moment | string) => {
    if (cancelButton && !value) {
      return ''
    }
    if (
      !value ||
      !moment(value).isValid() ||
      moment(value).isAfter(moment('9999-12-30T21:00:00.000Z'))
    ) {
      return t('invalid_date')
    }
    if (validate) {
      return validate(moment(value)) || ''
    }
    return ''
  }

  const validateDate = (value?: Moment | string): boolean => {
    const errorMessage = getErrorMessage(value)
    if (setErrorMessage) {
      setErrorMessage(errorMessage)
    }
    setHasError(errorMessage.length > 0)
    return !errorMessage
  }

  const save = () => {
    if (
      !moment(selectedDate).isSame(moment(date)) &&
      validateDate(selectedDate)
    ) {
      const dateToSave = selectedDate ? moment(selectedDate) : undefined
      const adjustedDate = includeTime ? dateToSave : dateToSave?.utc(true)
      onSave(adjustedDate?.toISOString())
    }
  }

  const renderDay = (props: any, date: Moment) => {
    const isFirstDayOfWeek =
      moment(date).isoWeekday() === moment(date).startOf('week').day()
    return (
      <td {...props} className={twMerge(props.className, 'relative')}>
        {date.date()}
        {isFirstDayOfWeek && (
          <div
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
            className="cursor-default absolute right-9 top-1 text-xxs text-gray-800 bg-gray-200 w-4 h-4"
          >
            {date.week()}
          </div>
        )}
      </td>
    )
  }

  const isValidDate = (currentDate: Moment | string) =>
    !moment(currentDate).isAfter(moment('9999-12-30T21:00:00.000Z'))

  return (
    <div
      className={'flex h-full text-sm'}
      id={id}
      onClick={(e) => e.stopPropagation()}
    >
      <DateTime
        inputProps={{
          className: twMerge(
            'bg-transparent rounded-sm h-full',
            !setErrorMessage && hasError && 'bg-red-status',
            inputClassName,
            deadline && moment(date).isBefore(moment()) && 'text-red',
          ),
          placeholder: '__',
          disabled,
          style: {
            width: width,
          },
        }}
        value={selectedDate}
        onChange={setSelectedDate}
        onClose={save}
        locale={i18n.language}
        timeFormat={includeTime}
        renderDay={renderDay}
        key={`dateTimeInput-${reload}`}
        input
        isValidDate={isValidDate}
      />
      {cancelButton && !disabled && (
        <Cancel
          className={
            'cursor-pointer fill-gray-700 text-sm mx-0.5 self-center hover:fill-blue-root-focus'
          }
          onClick={(e: MouseEvent) => {
            e.preventDefault()
            e.stopPropagation()
            clearSelection()
          }}
        />
      )}
    </div>
  )
}

export default DateTimeInput

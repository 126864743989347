import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import {
  IConstructionWagon,
  IContract,
  IDiscipline,
  ITaskData,
} from 'src/service/OrgTypes'
import { actionSource } from 'src/service/SystemValues'
import { getFilterTasks, getWagonTasksFiltered } from 'src/service/TaskService'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { capFirstLetter } from '../../utility/utils'
import MContent from '../components/MContent'
import MHeader from '../components/MHeader'
import MIssueFilter, { IIssueListData } from '../components/MIssueFilter'
import MPage from '../components/MPage'
import { CustomIssueTypes } from './MCustomIssues'
import MVognInfo from './MVognInfo'

interface IMIssueListProps {
  wagonId?: number
  wagon?: IConstructionWagon
  onAddIssue: () => void
  parentId?: number
  parentType?: CustomIssueTypes
  parentInfo?: JSX.Element
}

const MIssueList = ({
  wagonId,
  wagon,
  onAddIssue,
  parentId,
  parentType,
  parentInfo,
}: IMIssueListProps) => {
  moment.tz.setDefault('Europe/Oslo')
  const userContext = useContext(UserContext)
  const { size } = useParams<{ size: string }>()
  const location = useLocation<{
    tasks: ITaskData[]
    myDisciplines: IDiscipline[]
    myContracts: IContract[]
  }>()
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  const page = useRef<number>(1)
  const totalPages = useRef<number>(1)
  const totalItems = useRef<number>(0)
  const intialRender = useRef<boolean>(true)
  const { t } = useTranslation()
  const history = useHistory()
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [selectedControlAreas, setSelectedControlAreas] = useState<number[]>([])
  const [selectedRooms, setSelectedRooms] = useState<number[]>([])

  const [openSelected, setOpenSelected] = useState<boolean>(true)
  const [delayedSelected, setDelayedSelected] = useState<boolean>(false)
  const [selectedTags, setSelectedTags] = useState<number[]>([])
  const [issues, setIssues] = useState<ITaskData[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(true)

  useEffect(() => {
    if (intialRender.current) {
      intialRender.current = false
    }
  }, [])

  useDidMountEffect(() => {
    if (!intialRender.current) {
      fetchIssues(1, false)
    }
  }, [reload])

  useEffect(() => {
    setShowMoreButton(showFilter && page.current < totalPages.current)
  }, [page.current, totalPages.current])

  const fetchIssues = async (pageNumber: number, append: boolean) => {
    if (location?.state?.tasks?.length > 0) {
      const issuesWithCategory = location.state.tasks.map((item: ITaskData) => {
        item.category = actionSource(t)
          .filter((pt) => pt.id === item.parent_type)
          .map((pt) => pt.name)
          .pop()
        return item
      })
      setIssues(issuesWithCategory)
      setShowFilter(false)
      return
    }

    if (!append) {
      setLoading(true)
    }

    const wagonIssues = wagonId
      ? await getWagonTasksFiltered(wagonId, prepareFilters(), pageNumber, 24)
      : await getFilterTasks(
          projectId,
          prepareFilters(),
          pageNumber,
          24,
          'Aksjon',
        )

    const tasks = wagonIssues.tasks
    totalPages.current = wagonIssues?.pages ?? 1
    totalItems.current = wagonIssues?.total_items ?? 0

    const tasksWithCategory = tasks.map((item: ITaskData) => {
      item.category = actionSource(t)
        .filter((pt) => pt.id === item.parent)
        .map((pt) => pt.name)
        .pop()
      return item
    })
    if (append) {
      setIssues((prevIssues: any) => [...prevIssues, ...tasksWithCategory])
      setPaginationLoading(false)
    } else {
      setIssues(tasksWithCategory)
      page.current = 1
    }
    setLoading(false)
  }

  const getOpenStatus = () => (openSelected ? ['open', 'in_progress'] : [])

  const prepareFilters = () => {
    const filters = {
      discipline: [...selectedDisciplines],
      responsible: [...selectedUsers],
      status: getOpenStatus(),
      tag: [...selectedTags],
      control_area: [...selectedControlAreas],
      location: [...selectedRooms],
    }

    if (parentType && parentId) {
      Object.assign(filters, {
        parent_type: [parentType],
        parent_id: [parentId],
      })
    }

    if (!wagonId && !parentType && !parentId) {
      Object.assign(filters, {
        parent_type: [
          'CheckInParameter',
          'ChecklistParameter',
          'ConstructionLocomotive',
          'ControlArea',
          'Room',
          'System',
        ],
      })
    }

    if (delayedSelected) {
      Object.assign(filters, { delayed: true })
    }

    if (size === 'current') {
      Object.assign(filters, {
        deadline: [moment().startOf('week'), moment().endOf('week')],
      })
    }

    if (size === 'myDisciplines') {
      Object.assign(filters, {
        deadline: [moment().startOf('week'), moment().endOf('week')],
        status: ['open', 'in_progress'],
      })
    }

    return filters
  }

  const hasActiveFilters = () => {
    return (
      selectedDisciplines?.length > 0 ||
      selectedUsers?.length > 0 ||
      openSelected ||
      delayedSelected ||
      selectedTags?.length > 0
    )
  }

  const handleClick = (task: ITaskData) => {
    if (task?.parent_type === 'ConstuctionLocomotive') {
      history.push(`/mobile/issues/${task.parent_id}/${task.id}`)
      return
    }
    history.push(`/mobile/issues/global/${task.id}`)
  }

  const getItem = (issue: any, key: number) => {
    return (
      <div
        key={key}
        onClick={() => handleClick(issue)}
        className={'bg-white px-4 py-2 flex md:rounded'}
      >
        <div className={'shrink-0 grow-0 flex items-center pr-4'}>
          <Icon
            icon={Icons.ISSUE_CIRCLED}
            className={'w-6 h-6 flex flex-shrink-0'}
          />
        </div>

        <div className={'flex-auto flex flex-col min-w-0'}>
          <p className={'text-md font-semibold text-gray-800 truncate'}>
            {issue.title}
          </p>
          {!parentType && (
            <p className={'text-sm font-thin text-gray-600'}>{`${capFirstLetter(
              t('category'),
            )}: ${issue.category}`}</p>
          )}
          {parentType !== 'ControlArea' &&
            !wagonId &&
            issue.parent_type !== 'System' && (
              <p
                className={'text-sm font-thin text-gray-600 truncate'}
              >{`${capFirstLetter(t('control_area'))}: ${
                issue?.control_area?.title ?? ''
              }`}</p>
            )}
          {parentType !== 'ControlArea' &&
            !wagonId &&
            issue.parent_type !== 'System' &&
            issue.parent_type !== 'Room' &&
            issue.parent_type !== 'ControlArea' && (
              <p
                className={'text-sm font-thin text-gray-600 truncate'}
              >{`${capFirstLetter(t('wagon'))}: ${
                issue?.construction_locomotive?.title ?? ''
              }`}</p>
            )}
          {parentType !== 'System' && issue.parent_type === 'System' && (
            <p
              className={'text-sm font-thin text-gray-600 truncate'}
            >{`${capFirstLetter(t('system'))}: ${issue?.parent?.name ?? ''}`}</p>
          )}
          {parentType !== 'Room' && issue.parent_type === 'Room' && (
            <p
              className={'text-sm font-thin text-gray-600 truncate'}
            >{`${capFirstLetter(t('room'))}: ${
              issue?.parent?.room_name ?? ''
            }`}</p>
          )}
          <p className={'text-sm font-light text-gray-600'}>{`${capFirstLetter(
            t('deadline'),
          )}: ${moment(issue.deadline).format('ddd MMM DD YYYY')}`}</p>
        </div>

        <div className={'flex items-center'}>
          <Icon
            className={'w-6 h-6 ml-2 flex items-center'}
            icon={getStatusIcon(issue)}
          />
        </div>
      </div>
    )
  }

  const getStatusIcon = (issue: ITaskData): Icons => {
    const delayed =
      issue.status !== 'done' && moment().isAfter(issue.deadline, 'day')
    return delayed
      ? Icons.CHECK_RED_CIRLCE
      : issue.status === 'done'
        ? Icons.CHECK_GREEN_CIRLCE
        : Icons.CHECK_GRAY_CIRLCE
  }

  const getItemsString = () => {
    return `(${issues?.length ?? ''} of ${totalItems.current})`
  }

  const translateParentType = (type: string) => {
    if (type === 'ControlArea') {
      return capFirstLetter(t('control_area'))
    }
    if (type === 'ConstructionLocomotive') {
      return capFirstLetter(t('wagon'))
    }
    if (type === 'Room') {
      return capFirstLetter(t('room'))
    }
    if (type === 'System') {
      return t('system')
    }
    return type
  }

  const getTitle = () => {
    return capFirstLetter(
      size === 'current'
        ? `${t('active_issues_on_week_number', {
            week: moment().isoWeek(),
          })} ${getItemsString()}`
        : hasActiveFilters()
          ? `${t('filtered_issues')} ${getItemsString()}`
          : wagonId
            ? `${t('type_level_issues', {
                type: capFirstLetter(t('wagon')),
              })} ${getItemsString()}`
            : parentType
              ? `${t('type_level_issues', {
                  type: translateParentType(parentType),
                })} ${getItemsString()}`
              : `${t('total_issues')} ${getItemsString()}`,
    )
  }

  const onLoadMore = () => {
    setPaginationLoading(true)
    if (page.current && totalPages.current) {
      page.current = page.current + 1
      if (page.current <= totalPages.current) {
        fetchIssues(page.current, true)
      }
    }
  }

  const onFiltersChange = (filters: IIssueListData) => {
    if (
      filters.selectedDisplineIds &&
      selectedDisciplines !== filters.selectedDisplineIds
    ) {
      setSelectedDisciplines(filters.selectedDisplineIds)
    }
    if (filters.selectedUserIds && selectedUsers !== filters.selectedUserIds) {
      setSelectedUsers(filters.selectedUserIds)
    }
    if (filters.selectedTagsIds && selectedTags !== filters.selectedTagsIds) {
      setSelectedTags(filters.selectedTagsIds)
    }
    if (delayedSelected !== filters.showDelayedItems) {
      setDelayedSelected(filters.showDelayedItems)
    }
    if (
      filters.selectedControlAreaIds &&
      selectedControlAreas !== filters.selectedControlAreaIds
    ) {
      setSelectedControlAreas(filters.selectedControlAreaIds)
    }
    if (filters.selectedRoomIds && selectedRooms !== filters.selectedRoomIds) {
      setSelectedRooms(filters.selectedRoomIds)
    }
    setOpenSelected(filters.showOpenItems)
    setReload((prev) => !prev)
  }

  const onFilterClear = () => {
    page.current = 1
    totalPages.current = 1
  }

  const Loader = () => (
    <div className={'w-full h-full grid place-items-center relative z-n1'}>
      <SpinnerMobile />
    </div>
  )

  const getListWithPagination = () => (
    <section className={'flex flex-col'}>
      {issues?.length > 0 ? (
        <div>
          <div
            className={
              'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:px-4 md:py-2'
            }
          >
            {issues.map((task: any, i: number) => getItem(task, i))}
          </div>
          {showMoreButton && (
            <div className={'flex justify-center items-center my-2 h-10'}>
              {paginationLoading ? (
                <div className={'transform scale-25'}>
                  <SpinnerMobile />
                </div>
              ) : (
                <Button
                  onClick={onLoadMore}
                  type={Button.ButtonType.PRIMARY}
                  size={Button.ButtonSize.XSMALL}
                >
                  {t('load_next_page')}
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={'w-full h-full grid place-items-center my-4'}>
          <Badge
            text={t('no_results_found')}
            size={Badge.BadgeSize.LARGE}
            color={Badge.BadgeColor.YELLOW}
          />
        </div>
      )}
    </section>
  )

  const getMyDisiplines = () => {
    if (location?.state) {
      const { myContracts, myDisciplines } = location?.state
      if (myContracts && myDisciplines) {
        return { myDisciplines, myContracts }
      }
    }
    return
  }

  const getFilter = (inWagonTab: boolean, filterName: string) => (
    <div className={'flex justify-between sticky top-n1 bg-mobile'}>
      <p className={'text-gray-600 text-sm p-4'}>
        {size === 'myDisciplines' ? t('my_discipline_issues') : getTitle()}
      </p>
      <MIssueFilter
        projectId={projectId}
        showUser={true}
        onFiltersChange={onFiltersChange}
        onFilterClear={onFilterClear}
        onOpen={(val) => setOpen(val)}
        inWagonTab={inWagonTab}
        filterName={
          size === 'myDisciplines' ? 'MyDisciplineIssueList' : filterName
        }
        myDisciplinesOnly={size === 'myDisciplines'}
        myFilters={getMyDisiplines()}
      />
    </div>
  )

  const getWagonContent = (): JSX.Element => (
    <div className={`bg-mobile relative ${open ? 'overflow-hidden' : ''}`}>
      {showFilter && getFilter(true, 'WagonIssueListFilter')}
      {wagon && !isEmpty(wagon) && (
        <MVognInfo
          wagon={wagon}
          onFloatingBtnClick={onAddIssue}
          sticky={false}
        />
      )}
      {loading ? Loader() : getListWithPagination()}
    </div>
  )

  const getParentContent = (): JSX.Element => (
    <div className={`bg-mobile relative ${open ? 'overflow-hidden' : ''}`}>
      {showFilter && getFilter(true, 'IssuesList_' + parentType)}
      {parentInfo && <div className={'mb-2 bg-white'}>{parentInfo}</div>}
      {loading ? Loader() : getListWithPagination()}
    </div>
  )

  const getContent = () => (
    <MContent disableScrolling={open} setVh={wagonId ? false : true}>
      <div className={'relative'}>
        {showFilter && getFilter(false, 'GlobalIssueListFilter')}
        {loading ? Loader() : getListWithPagination()}
      </div>
    </MContent>
  )

  return (
    <div className={'z-50'}>
      {wagonId ? (
        getWagonContent()
      ) : parentType ? (
        getParentContent()
      ) : (
        <MPage>
          <>
            <MHeader
              onBackClick={() => history.goBack()}
              user={userContext.state.user}
            />
            {getContent()}
          </>
        </MPage>
      )}
    </div>
  )
}

export default MIssueList

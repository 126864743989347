import Engineering from '@icons/engineering-fill.svg'
import Settings from '@icons/settings.svg'
import moment from 'moment'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from 'src/components/user/UserIcon'
import { ISubcontractor } from 'src/fdvu/types/ISubcontractor'
import { IActivityLog, IUserData } from 'src/service/OrgTypes'
import { formatDateRelativeToNow } from 'src/utility/dateUtils'
import { classNames } from 'src/utility/utils'

interface ActivityLogItemHeaderProps {
  user?: IUserData
  subcontractor?: ISubcontractor
  changedBy?: string
  date: string | moment.Moment | Date
  activityLog?: IActivityLog
  isSystemNotification?: boolean
  className?: string
}

const ActivityLogItemHeader = ({
  user,
  subcontractor,
  changedBy,
  date,
  isSystemNotification = false,
  children,
  className = '',
}: PropsWithChildren<ActivityLogItemHeaderProps>) => {
  const { t, i18n } = useTranslation()

  const userData: {
    userId?: number
    name: string
    image_url?: string
    icon?: JSX.Element
  } = isSystemNotification
    ? {
        name: t('system_notification'),
        icon: <Settings className={'h-8 w-8'} />,
      }
    : subcontractor
      ? {
          name: `${t('subcontractor')}: ${subcontractor.name}`,
          icon: <Engineering className={'h-8 w-8'} />,
        }
      : user
        ? {
            name: `${user.firstName} ${user.lastName}`,
            image_url: user.image_url,
            userId: user.id,
          }
        : { name: changedBy ?? '' }

  return (
    <div className={classNames('flex items-center', className)}>
      <div className="flex-[0_0_auto]">
        <UserIcon
          userId={userData?.userId}
          firstName={userData?.name}
          image_url={userData?.image_url}
          icon={userData?.icon}
          large
        />
      </div>
      {children}
      <div className="text-xxs font-normal text-gray-500 flex-shrink-0 ml-auto">
        {formatDateRelativeToNow(date, i18n.language)}
      </div>
    </div>
  )
}

export default ActivityLogItemHeader

import { ExportTemplate } from 'src/context/ExportContext/ExportTypes'

export const objectExportTemplate: ExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/systems/export',
    method: 'POST',
  },
  name: 'objects',
  displayName: 'objects',
  ignoreListColumns: true,
  runInBg: true,
  columns: [
    'system_number',
    'system_name',
    'description',
    'file_containers',
    'system_type',
    'system_type_description',
    'system_type_unique',
    'parent_system',
    'location',
    'disciplines',
    'contracts',
    'mmi',
    'fmi',
    'system_status',
    'test_system_group',
    'test_work_group',
    'system_connections',
    'active',
    'is_testable',
    'is_in_bim',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'name',
    'description',
    'file_containers->record_id',
    'system_type.type_code||system_type.name',
    'system_type.description',
    'system_type_unique.type_code||system_type_unique.name',
    'parent_system.record_id||parent_system.name',
    'location',
    'disciplines->shortName||disciplines->name',
    'contracts->contractNumber||contracts->contractName',
    'system_mmi.name',
    'system_fmi.name',
    'system_status.name',
    'test_system_groups->record_id',
    'test_work_groups->record_id',
    'connected_systems->record_id',
    'is_active',
    'is_testable',
    'is_in_bim',
    'created_at',
    'updated_at',
  ],
}

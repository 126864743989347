import { QrExportTemplate } from 'src/context/ExportContext/ExportTypes'

export const systemPerProjectListQRExportTemplate: QrExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/systems/pages/1/1000',
    method: 'POST',
  },
  name: 'Systems',
  displayName: 'System',
  rows: ['id', 'record_id', 'name'],
  columns: ['Id', 'Systemnr', 'Systemnavn'],
  qrUrlTemplate: '{currentUrl}?modal=system&id=$id&project={projectId}',
}

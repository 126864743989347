import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getSystemSyntaxes } from 'src/service/SystemSyntaxService'

export const useSystemSyntaxes = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['system_settings', 'systemSyntaxes', projectId],
    queryFn: getSystemSyntaxes,
  })
}

import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  ITabContext,
  TabContext,
} from 'src/context/TabContextProvider/TabContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { getConstructionWagon } from 'src/service/ConstructionWagonService'
import { IConstructionWagon, ITaskData } from 'src/service/OrgTypes'
import { createTask } from 'src/service/TaskService'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import useAlert from 'src/ui-elements/toast/useAlert'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MTabs, { IMTabItem } from '../components/MTabs'
import MAddIssue from './MAddIssue'
import MIssueList from './MIssueList'
import MTavlamote from './MTavlamote'
import MWagonDetail from './MWagonDetail'
import MWorkOps from './MWorkOps'

const MWagon = (_props: any) => {
  const projectContext: IProjectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext?.state?.currentProject
  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  const history = useHistory()
  const { vid } = useParams<{ caid: string; vid: string }>()

  useEffect(() => {
    fetchWagon(vid)
  }, [])

  const fetchWagon = async (id: string) => {
    if (id) {
      const wagon = await getConstructionWagon(Number(id))
      setWagonData(wagon)
    }
  }

  const setWagonData = (wagon: IConstructionWagon) => {
    setWagonRes(wagon)
  }

  const [wagonRes, setWagonRes] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const tabContext: ITabContext = useContext(TabContext)
  const { tabIndex } = tabContext.state
  const { setCurrentIndex } = tabContext.actions

  const onAddIssueClick = () => {
    if (tabIndex !== 0) {
      setCurrentIndex(0)
    }
    setShowTaskModal(true)
  }

  const onCloseIssueModal = (done?: boolean) => {
    setShowTaskModal(false)
    if (done) {
      setCurrentIndex(3)
    }
  }

  const onAddTavlamote = () => {
    if (
      wagonRes.check_ins?.some((check_in) => check_in.is_check_in) &&
      !!wagonRes.has_checked_out
    ) {
      addAlert({
        type: 'error',
        title: t('an_error_occurred'),
        description: t('not_able_to_add_after_checked_out'),
        autoClose: true,
      })
    } else {
      history.push(`/mobile/add-tavlamote/${wagonRes.id}`)
    }
  }

  const onAddWorkOps = () => {
    history.push(`/mobile/add-workops/add/${wagonRes.id}`)
  }

  const onWagonChange = (newWagon: IConstructionWagon) => {
    setWagonData(newWagon)
  }

  const tabItems: IMTabItem[] = [
    {
      name: t('wagon'),
      content: (
        <MWagonDetail
          onWagonChange={onWagonChange}
          onAddIssue={onAddIssueClick}
        />
      ),
      icon: 'LOCOMOTIVE',
      activeIcon: 'LOCOMOTIVE_BLUE',
      disabledIcon: 'LOCOMOTIVE_DISABLED',
    },
    {
      name: t('board_meetings'),
      content: <MTavlamote onAddTavlamote={onAddTavlamote} />,
      icon: 'TAVLAMOTE',
      activeIcon: 'TAVLAMOTE_ACTIVE',
      disabledIcon: 'TAVLAMOTE_DISABLED',
    },
    {
      name: t('activities'),
      content: <MWorkOps onAddWorkOps={onAddWorkOps} />,
      icon: 'WORK_OPS',
      activeIcon: 'WORK_OPS_ACTIVE',
      disabledIcon: 'WORK_OPS_DISABLED',
    },
    {
      name: t('cases_native'),
      content: (
        <MIssueList
          onAddIssue={onAddIssueClick}
          wagonId={+vid}
          wagon={wagonRes}
        />
      ),
      icon: 'ISSUE',
      activeIcon: 'ISSUE_ACTIVE',
      disabledIcon: 'ISSUE_DISABLED',
    },
  ]

  const handleBackBtn = () => {
    history.goBack()
  }

  const getWagonDeadline = () => {
    return moment(wagonRes.startTime).add(wagonRes.extended_duration, 'days')
  }

  const submitTask = (task: ITaskData) => {
    if (!isEmpty(task)) {
      createTask(task).then(() => {
        setShowTaskModal(false)
        setCurrentIndex(3)
      })
    }
  }

  return (
    <MPage bgColor={'mobile'}>
      {!showTaskModal ? (
        <>
          <MHeader onBackClick={handleBackBtn} user={user} />
          <MTabs
            tabIndex={tabIndex}
            tabItems={tabItems}
            setTabIndex={(index) => setCurrentIndex(index)}
            disabledIndexs={[]}
          />
        </>
      ) : (
        <MAddIssue
          projectId={projectId}
          wagon={wagonRes}
          controlAreaId={wagonRes?.control_area_id}
          parentDeadline={getWagonDeadline()}
          parentType="ConstructionLocomotive"
          parentId={wagonRes?.id}
          onClose={onCloseIssueModal}
          submitTask={(task) => submitTask(task)}
          title={t('add_w_param', { param: t('wagon_issue') })}
        />
      )}
    </MPage>
  )
}

export default MWagon

import ArrowBack from '@icons/arrow_back.svg'
import ArrowForward from '@icons/arrow_forward.svg'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import SystemConnectionTypeForm from 'src/components/system/system-connection-type/SystemConnectionTypeForm'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import { useProjectSystemConnectionTypes } from 'src/query/systems/systemConnections'
import {
  ISystemConnectionType,
  SystemConnectionDirectionEnum,
} from 'src/service/OrgTypes'
import { deleteSystemConnectionType } from 'src/service/SystemService'
import {
  dateColumn,
  defaultTextColumn,
  textColumn,
} from 'src/ui-elements/Table/Columns'
import TableBody from 'src/ui-elements/Table/TableBody'
import useTable from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'

export const renderConnectionDirection = (
  direction: SystemConnectionDirectionEnum = SystemConnectionDirectionEnum.NONE,
) => {
  switch (direction) {
    case SystemConnectionDirectionEnum.RIGHT:
      return {
        text: 'a_dependant_on_b',
        icon: (
          <ArrowForward
            className={'fill-blue-root text-lg w-4 flex justify-center '}
          />
        ),
      }
    case SystemConnectionDirectionEnum.LEFT:
      return {
        text: 'b_dependant_on_a',
        icon: (
          <ArrowBack
            className={'fill-blue-root text-lg w-4 flex justify-center'}
          />
        ),
      }
    default:
      return {
        text: 'no_direction',
        icon: (
          <div className={'text-xl text-blue-root w-4 flex justify-center'}>
            {'-'}
          </div>
        ),
      }
  }
}

const SystemConnectionTypeList = () => {
  const { t } = useTranslation()
  const { data: systemConnectionTypes, refetch } =
    useProjectSystemConnectionTypes()
  const [selectedSystemConnectionType, setSelectedSystemConnectionType] =
    useState<ISystemConnectionType>()
  const [showAddForm, setShowAddForm] = useState(false)
  const [showEditForm, setShowEditForm] = useState(false)

  const openCreateModal = () => {
    setShowAddForm(true)
  }

  const onRowClick = (row: ISystemConnectionType) => {
    setSelectedSystemConnectionType(row)
    setShowEditForm(true)
  }

  const { confirmDelete } = useDeleteModal()
  const onDeleteItemClick = async (row: ISystemConnectionType) => {
    const confirm = await confirmDelete({
      itemName: row.name,
      itemIdnType: row.name,
    })
    if (!confirm) return
    await deleteSystemConnectionType(row)
    refetch()
  }

  const columnHelper = createColumnHelper<ISystemConnectionType>()

  const columns = [
    textColumn('name', { name: t('name') }),
    textColumn('code', { name: t('code') }),
    textColumn('role_a', { name: t('role_a') }),
    columnHelper.accessor('direction', {
      ...defaultTextColumn<ISystemConnectionType>('direction', {
        name: capitalize(t('dependency_direction')),
      }),
      size: 100,
      cell: (props) => {
        const { icon } = renderConnectionDirection(props.getValue())
        return (
          <div
            className={twMerge(
              'w-full items-center mx-2 text-blue-root',
              props.getValue() === SystemConnectionDirectionEnum.NONE && 'ml-3',
            )}
          >
            {icon}
          </div>
        )
      },
    }),
    textColumn('role_b', { name: t('role_b') }),
    textColumn('description', { name: t('description') }),
    dateColumn('created_at', { name: t('created_at') }),
    dateColumn('updated_at', { name: t('updated_at') }),
  ] as ColumnDef<ISystemConnectionType>[]

  const { table } = useTable({
    name: 'SystemConnectionTypeList',
    data: systemConnectionTypes ?? [],
    legacyColumns: [],
    columns,
    selectable: false,
    onRowClick: onRowClick,
    enableFilters: false,
    enableSorting: false,
    onDeleteClick: onDeleteItemClick,
  })

  return (
    <div className={TabPageStyleClass.root}>
      <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
        {t('new_w_param', {
          param: t('system_connection_type').toLowerCase(),
        })}
      </Button>

      <TableBody table={table} enablePagination={false} />
      {showAddForm && (
        <SystemConnectionTypeForm
          editingMode={false}
          closeModal={() => {
            setShowAddForm(false)
            refetch()
          }}
        />
      )}

      {showEditForm && selectedSystemConnectionType && (
        <SystemConnectionTypeForm
          editingMode={true}
          systemConnectionType={selectedSystemConnectionType}
          closeModal={() => {
            setShowEditForm(false)
            refetch()
          }}
        />
      )}
    </div>
  )
}

export default SystemConnectionTypeList

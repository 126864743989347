import Edit from '@material-symbols/svg-500/rounded/edit.svg'
import moment from 'moment-timezone'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { checkInColor } from 'src/components/construction/wagon/CheckListDetail'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { getStatusColor } from 'src/mobile/pages/MWagonDetail'
import {
  getCurrentConstructionWagons,
  getPaginatedProjectConstructionwagons,
  getPrevNextWagon,
} from 'src/service/ConstructionWagonService'
import {
  IConstructionWagon,
  IContract,
  IDiscipline,
} from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import useAlert from 'src/ui-elements/toast/useAlert'
import { capFirstLetter } from '../../utility/utils'
import { useQuery } from '../MUtils'
import MCard from '../components/MCard'
import MChips from '../components/MChips'
import MContent from '../components/MContent'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MWagonFilter, { IWagonListData } from '../components/MWagonFilter'

const MWagonList = (_props: any) => {
  moment.tz.setDefault('Europe/Oslo')
  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const location = useLocation<{
    myDisciplines: IDiscipline[]
    myContracts: IContract[]
  }>()

  const getLocationData = (): {
    myDisciplineIds: number[]
    myContractIds: number[]
  } => {
    if (location?.state?.myDisciplines && location?.state?.myContracts) {
      const contracts = location?.state?.myContracts.map((c) => c.id)
      const disciplines = location?.state?.myDisciplines.map((d) => d.id)
      return { myDisciplineIds: disciplines, myContractIds: contracts }
    }
    return { myDisciplineIds: [], myContractIds: [] }
  }

  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  const history = useHistory()
  const { pid } = useParams<{ pid: string }>()
  const page = useRef<number>(1)
  const totalPages = useRef<number>(1)
  const totalItems = useRef<number>(0)
  const intialRender = useRef<boolean>(true)
  const query = useQuery()
  const [loading, setLoading] = useState<boolean>(false)
  const [wagons, setWagons] = useState<IConstructionWagon[]>([])
  const initState = useRef<IWagonListData>({
    selectedContractIds: getLocationData().myContractIds,
    selectedDisplineIds: getLocationData().myDisciplineIds,
    checkedIn: null,
    checkedOut: null,
    startTime: moment().startOf('week'),
    endTime: moment().endOf('week'),
  })

  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [checkedIn, setCheckedIn] = useState<boolean | null>(false)
  const [checkedOut, setCheckedOut] = useState<boolean | null>(false)
  const [rangeStartDate, setRangeStartDate] = useState<moment.Moment>(
    initState.current.startTime,
  )
  const [rangeEndDate, setRangeEndDate] = useState<moment.Moment>(
    initState.current.endTime,
  )

  const [open, setOpen] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(true)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false)

  useEffect(() => {
    if (intialRender.current) {
      intialRender.current = false
    }
  }, [])

  useDidMountEffect(() => {
    if (!intialRender.current) {
      fetchWagons(1, false)
    }
  }, [reload])

  useEffect(() => {
    setShowMoreButton(showFilter && page.current < totalPages.current)
  }, [page.current, totalPages.current])

  useEffect(() => {
    setShowFilter(query.get('show') === 'myDisciplines')
  }, [query])

  useDidMountEffect(() => {
    const isApplied =
      !rangeStartDate.isSame(initState.current.startTime) ||
      !rangeEndDate.isSame(initState.current.endTime) ||
      checkedIn ||
      checkedOut ||
      selectedDisciplines.length > 0
    setFiltersApplied(isApplied)
  }, [rangeStartDate, rangeEndDate, checkedIn, checkedOut, selectedDisciplines])

  const fetchWagons = async (pageNumber: number, append: boolean) => {
    if (!append) {
      setLoading(true)
    }
    let wagons: IConstructionWagon[] = []
    if (showFilter) {
      const res = await getPaginatedProjectConstructionwagons(
        +pid,
        pageNumber,
        prepareFilters(),
        25,
      )
      wagons = res.construction_locomotives
      totalPages.current = res?.pages ?? 1
      totalItems.current = res?.total_items ?? 0
    } else {
      const res = await getCurrentConstructionWagons(+pid)
      wagons = res
    }

    if (append) {
      setWagons((prevWagons) => [...prevWagons, ...wagons])
      setPaginationLoading(false)
    } else {
      setWagons(wagons)
      page.current = 1
    }
    setLoading(false)
  }

  const prepareFilters = () => {
    return Object.assign(
      {},
      {
        activity_in_disciplines: [...selectedDisciplines],
        active_in_date: [rangeStartDate, rangeEndDate],
        has_checked_in: checkedIn,
        has_checked_out: checkedOut,
      },
    )
  }

  const prepareData = (wagon: IConstructionWagon) => {
    const checkIns =
      wagon.activity_disciplines?.map((discipline) => {
        const checkIn = wagon.check_ins?.find(
          (checkIn) =>
            checkIn.is_check_in && checkIn.discipline_id === discipline.id,
        )
        return {
          left: discipline.name,
          right: (
            <Badge
              text={!!checkIn ? t('yes') : t('no')}
              color={
                checkIn
                  ? (checkInColor(checkIn) as BadgeColor)
                  : BadgeColor.BLUE
              }
              size={Badge.BadgeSize.SMALL}
            />
          ),
          noBold: true,
        }
      }) ?? []
    const checkOuts =
      wagon.activity_disciplines?.map((discipline) => {
        const checkIn = wagon.check_ins?.find(
          (checkIn) =>
            !checkIn.is_check_in && checkIn.discipline_id === discipline.id,
        )
        return {
          left: discipline.name,
          right: (
            <Badge
              text={!!checkIn ? t('yes') : t('no')}
              color={
                checkIn
                  ? (checkInColor(checkIn) as BadgeColor)
                  : BadgeColor.BLUE
              }
              size={Badge.BadgeSize.SMALL}
            />
          ),
          noBold: true,
        }
      }) ?? []
    return [
      { left: t('control_area'), right: `${wagon.control_area?.title}` },
      { left: t('wagon'), right: `${wagon.title}` },
      { left: t('description'), right: `${wagon.subject}` },
      {
        left: t('start_date'),
        right: moment(wagon.startTime).format('ddd MMM DD YYYY'),
      },
      {
        left: t('end_date'),
        right: moment(wagon?.startTime)
          .add(
            wagon?.extended_duration ? wagon.extended_duration - 1 : 0,
            'days',
          )
          .format('ddd MMM DD YYYY'),
      },
      { left: t('duration'), right: wagon.duration + ` (${t('days')})` },
      {
        left: t('working_hours'),
        right: !Number.isNaN(wagon?.planned_staffing)
          ? Number(wagon?.planned_execution_hours).toFixed(1)
          : 0,
      },
      {
        left: t('number_of_workers'),
        right: !Number.isNaN(wagon?.planned_staffing)
          ? Number(wagon?.planned_staffing).toFixed(1)
          : '0',
      },
      {
        left: t('checked_in'),
        right: wagon.check_ins?.some((checkIn) => checkIn.is_check_in) ? (
          <div
            className="p-2 border bg-blue-100 text-blue-500 font-medium rounded-full cursor-pointer flex justify-between"
            onClick={() => {
              history.push(`/mobile/${wagon.id}/checks`)
            }}
          >
            <span className="first-capitalize text-sm">{t('details')}</span>
            <Edit className="text-lg fill-blue-500" />
          </div>
        ) : (
          <></>
        ),
        underline: true,
      },
      ...checkIns,
      {
        left: t('checked_out'),
        right: wagon.check_ins?.some((checkIn) => !checkIn.is_check_in) ? (
          <div
            className="p-2 border bg-blue-100 text-blue-500 font-medium rounded-full cursor-pointer flex justify-between"
            onClick={() => {
              history.push(`/mobile/${wagon.id}/checks`)
            }}
          >
            <span className="first-capitalize text-sm">{t('details')}</span>
            <Edit className="text-lg fill-blue-500" />
          </div>
        ) : (
          <></>
        ),
        underline: true,
      },
      ...checkOuts,
      {
        left: t('status'),
        right: (
          <Badge
            text={t(getStatusColor(wagon) ?? 'not_checked_in')}
            color={
              (getStatusColor(wagon) as BadgeColor | undefined) ??
              BadgeColor.DEFAULT
            }
            size={Badge.BadgeSize.SMALL}
          />
        ),
      },
    ]
  }
  const onWagonClick = (wagon: IConstructionWagon) => {
    history.push(`/mobile/wagons/${wagon.id}?tabIndex=0`)
  }

  const getWeekTitle = (item: IConstructionWagon) => {
    const startDate = moment(item.startTime)
    const endDate = moment(item.endTime)
    const startWeek = startDate.isoWeek()
    const endWeek = endDate.isoWeek()
    if (endWeek === startWeek) {
      return t('week_number_w_start_week', { startWeek })
    } else {
      return t('week_number_w_start_and_end_week', { startWeek, endWeek })
    }
  }

  const getHeader = (week: string): JSX.Element => {
    return (
      <div className={'flex justify-end mb-2'}>
        <MChips text={week} color={'green'} />
      </div>
    )
  }

  const getNeighbouringWagon = (
    wagon: IConstructionWagon,
    index: number,
    prev: boolean,
  ) => {
    if (wagon && wagon.id) {
      if (
        (prev && wagon.has_previous === false) ||
        (!prev && wagon.has_next === false)
      ) {
        addAlert({
          type: 'error',
          title: t('an_error_occurred'),
          description: t('no_wagon_found'),
          autoClose: true,
        })
        return
      }
      getPrevNextWagon(wagon.id, prev ? 'previous' : 'next').then((res) => {
        if (res && res?.message === 'Item not found') {
          addAlert({
            type: 'error',
            title: t('an_error_occurred'),
            description: t('no_wagon_found'),
            autoClose: true,
          })
          return
        }
        const newWagons = [...wagons]
        newWagons[index] = res
        setWagons(newWagons)
      })
    }
  }

  const footer = (wagon: IConstructionWagon, key: number) => (
    <div className={'mt-2'}>
      <Button
        width={'100%'}
        onClick={() => onWagonClick(wagon)}
        inMobile={true}
        size={Button.ButtonSize.XSMALL}
        type={Button.ButtonType.PRIMARY}
      >
        {t('go_to_wagon')} &gt;{' '}
      </Button>
      <div className={'w-full flex justify-between'}>
        <Button
          width={'40%'}
          disabled={wagon.has_previous === false}
          onClick={() => getNeighbouringWagon(wagon, key, true)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          ❮ {t('previous')}
        </Button>
        <Button
          width={'40%'}
          disabled={wagon?.has_next === false}
          onClick={() => getNeighbouringWagon(wagon, key, false)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          {t('next')} ❯
        </Button>
      </div>
    </div>
  )

  const onFiltersChange = (filters: IWagonListData) => {
    if (
      filters.selectedDisplineIds &&
      selectedDisciplines !== filters.selectedDisplineIds
    ) {
      setSelectedDisciplines(filters.selectedDisplineIds)
    }
    if (checkedIn !== filters.checkedIn) {
      setCheckedIn(filters.checkedIn)
    }
    if (checkedOut !== filters.checkedOut) {
      setCheckedOut(filters.checkedOut)
    }

    if (filters.startTime) {
      setRangeStartDate(moment(filters.startTime))
    }

    if (filters.endTime) {
      setRangeEndDate(moment(filters.endTime))
    }
    setReload((prev) => !prev)
  }

  const onFilterClear = () => {
    initState.current.selectedContractIds = []
    initState.current.selectedDisplineIds = []
    setSelectedDisciplines([])
    setCheckedIn(null)
    setCheckedOut(null)
    setRangeStartDate(initState.current.startTime)
    setRangeEndDate(initState.current.endTime)
    setFiltersApplied(false)
  }

  const onLoadMore = () => {
    setPaginationLoading(true)
    if (page.current && totalPages.current) {
      page.current = page.current + 1
      if (page.current <= totalPages.current) {
        fetchWagons(page.current, true)
      }
    }
  }

  const getMyDisiplines = () => {
    if (location?.state) {
      const { myContracts, myDisciplines } = location?.state
      if (myContracts && myDisciplines) {
        return { myDisciplines, myContracts }
      }
    }
    return
  }

  const getFilter = (filterName: string) => (
    <div className={'flex justify-between sticky top-n1 bg-mobile'}>
      <p className={'text-gray-600 text-sm p-4'}>{getTitle()}</p>
      <MWagonFilter
        projectId={+pid}
        onFiltersChange={onFiltersChange}
        onFilterClear={onFilterClear}
        filterName={filterName}
        onOpen={(val) => setOpen(val)}
        filtersApplied={filtersApplied}
        initState={initState}
        myFilters={getMyDisiplines()}
      />
    </div>
  )

  const getItemsString = () => {
    return `(${wagons?.length ?? ''} of ${totalItems.current})`
  }

  const getTitle = () => {
    return !showFilter
      ? `${capFirstLetter(t('active_wagons'))} ${getItemsString()}`
      : selectedDisciplines?.length > 0 &&
          selectedDisciplines === initState.current.selectedDisplineIds
        ? `${t('my_discipline_wagons')} ${getItemsString()}`
        : `${t('filtered_wagons')} ${getItemsString()}`
  }

  const PageLoader = () => (
    <div className={'w-full h-full grid place-items-center relative z-n1'}>
      <SpinnerMobile />
    </div>
  )

  return (
    <MPage>
      <>
        <MHeader onBackClick={() => history.goBack()} user={user} />
        <MContent disableScrolling={open} setVh={true}>
          <div className={'relative'}>
            {showFilter && getFilter('MWagonListFilterNew2')}
            {loading ? (
              <PageLoader />
            ) : wagons.length > 0 ? (
              <div
                className={
                  'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 px-6 py-4'
                }
              >
                {wagons.map((wagon, key) => (
                  <MCard
                    key={key}
                    data={prepareData(wagon)}
                    header={getHeader(getWeekTitle(wagon))}
                    footer={footer(wagon, key)}
                  />
                ))}
                {showMoreButton && (
                  <div className={'flex justify-center items-center my-2 h-10'}>
                    {paginationLoading ? (
                      <div className={'transform scale-25'}>
                        <SpinnerMobile />
                      </div>
                    ) : (
                      <Button
                        onClick={onLoadMore}
                        type={Button.ButtonType.PRIMARY}
                        size={Button.ButtonSize.XSMALL}
                      >
                        {t('load_next_page')}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className={'flex justify-center pt-4'}>
                <Badge
                  text={t('no_results_found')}
                  size={Badge.BadgeSize.LARGE}
                  color={Badge.BadgeColor.TEAL}
                />
              </div>
            )}
          </div>
        </MContent>
      </>
    </MPage>
  )
}

export default MWagonList

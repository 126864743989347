import moment from 'moment'
import { FunctionComponent, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useProjectId from 'src/components/hooks/useProjectId'
import UserIcon from 'src/components/user/UserIcon'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { useTestApprovals } from 'src/query/test'
import { modalUrlBuilder } from 'src/utility/utils'

interface ApprovalInspectorTableProps {
  testExecutionId: number
}

const ApprovalInspectorTable: FunctionComponent<
  ApprovalInspectorTableProps
> = ({ testExecutionId }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const modalContext = useContext(ModalContext)
  const projectId = useProjectId()

  const { data: approvals = [] } = useTestApprovals(testExecutionId)

  return (
    <>
      <InspectorSectionTable
        headerColumns={[
          t('responsible'),
          t('status'),
          t('signatures'),
          t('created_at'),
        ]}
        rowsData={approvals.map((approval) => {
          const { user } = approval
          return {
            cells: [
              <UserIcon
                key={user.id}
                userId={user.id}
                firstName={user.firstName}
                lastName={user.lastName}
                image_url={user.image_url}
              />,
              t(approval.status),
              approval.signatures[0]?.signature ? (
                <img
                  key={user.id + 'signature'}
                  width={'50'}
                  height={'50'}
                  src={approval.signatures.shift()?.signature}
                  alt={`${user.firstName} signature`}
                />
              ) : (
                '-'
              ),
              moment(approval.created_at).format('L'),
            ],
            id: approval.user_id,
          }
        })}
        handleClick={(id) => {
          const modalURL = modalUrlBuilder(projectId, 'user', id)
          history.push(modalURL)
          modalContext.actions.checkURL()
        }}
      />
    </>
  )
}

export default ApprovalInspectorTable

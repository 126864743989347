import { IConstructionTask } from './OrgTypes'
import { request } from './service-utils'

export const createConstructionTask = (
  task:
    | NullablePartial<IConstructionTask>
    | { construction_task_type_ids: number[] },
  trainId: number,
): Promise<IConstructionTask> => {
  return request(
    `construction_locomotives/${trainId}/construction_tasks`,
    'POST',
    task,
  )
}

export const editConstructionTask = (
  taskType: NullablePartial<IConstructionTask>,
): Promise<IConstructionTask> => {
  return request(`construction_tasks/${taskType.id}`, 'PUT', taskType)
}

export const getConstructionTask = (
  constrtuctionTaskId: number,
): Promise<IConstructionTask> => {
  return request(`construction_tasks/${constrtuctionTaskId}`, 'GET')
}

export const getConstructionWagonTasks = (
  wagonId: number,
): Promise<IConstructionTask[]> => {
  return request(
    `construction_locomotives/${wagonId}/construction_tasks`,
    'GET',
  )
}

export const deleteConstructionTask = (task: IConstructionTask) => {
  return request(`/construction_tasks/${task.id}`, 'DELETE', {}, true)
}

export const getConstructionTaskFilter = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
  draftMode = false,
): Promise<{ tasks: IConstructionTask[]; pages: number }> => {
  return request(
    `/projects/${projectId}/construction_tasks/pages/${currentPage}/${itemsPerPage}?drafting=${draftMode}`,
    'POST',
    filter,
  )
}

export const getConstructionWagonTaskFilter = (
  wagonId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
  draftMode = false,
): Promise<{ tasks: IConstructionTask[]; pages: number }> => {
  return request(
    `/construction_locomotives/${wagonId}/construction_tasks/pages/${currentPage}/${itemsPerPage}?drafting=${draftMode}`,
    'POST',
    filter,
  )
}

export const bulkDeleteConstructionTasks = (ids: number[]): Promise<void> => {
  return request(`/construction_tasks/bulk_delete`, 'POST', {
    ids,
  })
}

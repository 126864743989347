import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useInterval from 'src/components/hooks/UseInterval'
import useProjectId from 'src/components/hooks/useProjectId'
import { ImportTask } from 'src/context/ImportContext/ImportTypes'
import BackgroundJobService from 'src/service/BackgroundJobService'
import useAlert from 'src/ui-elements/toast/useAlert'

const useBackgroundJob = (type: 'import' | 'download' | 'upload') => {
  const projectId = useProjectId()
  const [taskId, setTaskId] = useState<number | null>(null)
  const [task, setTask] = useState<ImportTask>()
  const [pullInterval, setPullInterval] = useState<number | null>(null)

  const { addAlert } = useAlert()
  const { t } = useTranslation()

  const errorAlert = () => {
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: t(`${type}_failed`),
    })
  }

  const successAlert = () => {
    addAlert({
      type: 'success',
      title: t(type),
      description: t(`${type}_complete`),
    })
  }

  const poll = () => {
    if (taskId) {
      BackgroundJobService.getBackgroundJobStatus(projectId, taskId).then(
        (res) => {
          setTask(res)
          if (res.status === 'failed') errorAlert()
          if (res.status !== 'in_progress') setPullInterval(null)
          if (res.status === 'completed') successAlert()
        },
      )
    } else {
      setPullInterval(null)
    }
  }

  useInterval(async () => {
    poll()
  }, pullInterval)

  const inProgress = task?.status === 'in_progress'
  const taskComplete = task?.status === 'completed'

  useEffect(() => {
    taskId && setPullInterval(5000)
  }, [taskId, setPullInterval])

  return { setTaskId, task, inProgress, taskComplete }
}

export default useBackgroundJob

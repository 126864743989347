import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getProjectRooms } from 'src/service/RoomService'

export const useRooms = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['rooms', projectId],
    queryFn: () => getProjectRooms(projectId),
  })
}

import AddCircle from '@icons/add_circle-fill.svg'
import Error from '@icons/error-fill.svg'
import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import DateTimeInlineInputComponent from '../../../../ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import { IStringDropDownItem } from '../../../../ui-elements/page-display/inline-components/IDropDown'
import InlineComponentsWrapper from '../../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import MultiSelectorInlineInputComponent from '../../../../ui-elements/page-display/inline-components/MultiSelectorInlineInputComponent'
import SelectorInlineInputComponent from '../../../../ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from '../../../../ui-elements/page-display/inline-components/TextInlineInputComponent'
import BooleanInput from '../../BooleanInput'
import { DATA_TYPES, ImportDataFiled } from '../selectors/DataSelectors'

interface DocValidationCellProps {
  parentId: number
  value?: string | object | string[]
  errors?: string[]
  onChange: (value: string | string[] | null) => void
  dataFiled: ImportDataFiled
  openSystemTypeForm: () => void
}

const _getEnumValue = (
  value: string | object | null,
  selector: string,
): IStringDropDownItem => {
  const name =
    typeof value === 'object'
      ? (selector
          .split('.')
          .map((s) => value?.[s] ?? '')
          .join(' ') ?? '')
      : value
  return {
    id: typeof value === 'object' ? (value?.['id'] ?? '') : `${value}`,
    name,
    [selector]: name,
  }
}

const _getLabelValue = (selector: string, value?: string | object | null) => {
  if (!value) return ''
  return typeof value === 'object'
    ? (selector
        .split('.')
        .map((s) => value?.[s] ?? '')
        .join(' ') ?? '')
    : value
}

const _getMultiEnumValue = (
  values: (object | string | null)[],
  selector: string,
): IStringDropDownItem[] => {
  return (
    values?.map((value) => ({
      id: typeof value === 'object' ? (value?.['id'] ?? '') : `${value}`,
      name:
        typeof value === 'object'
          ? (selector
              .split('.')
              .map((s) => value?.[s] ?? '')
              .join(' ') ?? '')
          : value,
    })) ?? []
  )
}

const DocValidationCell: FC<DocValidationCellProps> = ({
  value,
  errors,
  onChange: _onChange,
  dataFiled,
  openSystemTypeForm,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const onChange = (id: string | string[] | null) => {
    _onChange(id)
  }

  const getData = useCallback(async (): Promise<IStringDropDownItem[]> => {
    if (!dataFiled.getData) {
      return Promise.resolve([])
    }

    const data = await dataFiled.getData(projectId)
    return data as IStringDropDownItem[]
  }, [projectId])

  const dataComponent = (
    dataValue: string | boolean | object | object[] | undefined | null,
    dataType?: DATA_TYPES,
  ) => {
    if (dataType === DATA_TYPES.date) {
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <DateTimeInlineInputComponent
            selectedTime={`${dataValue ?? ''}`}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            cancelButton={true}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.boolean) {
      return (
        <BooleanInput
          value={dataValue as boolean}
          onChange={(value) => onChange(`${value}`)}
        />
      )
    } else if (dataType === DATA_TYPES.string) {
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <TextInlineInputCompontent
            fullWidth={true}
            value={dataValue as string}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.enum) {
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <SelectorInlineInputComponent<string, any>
            getItems={() =>
              getData?.() ?? Promise.resolve(dataFiled?.listItems?.(t) ?? [])
            }
            items={dataFiled?.listItems?.(t) ?? []}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            selectedId={dataValue?.['id']}
            initialItem={_getEnumValue(
              dataValue as object,
              dataFiled?.selector ?? '',
            )}
            getItemLabel={(item) => _getLabelValue(dataFiled?.selector, item)}
            cancelButton={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.constantEnum) {
      const value = dataValue as string
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <SelectorInlineInputComponent<string, any>
            items={dataFiled?.listItems?.(t) ?? []}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            selectedId={value}
            initialItem={{ id: value, name: t(value) }}
            getItemLabel={(item) => item.name ?? ''}
            cancelButton={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      )
    } else if (dataType === DATA_TYPES.multiEnum) {
      const dataValueList = dataValue ? (dataValue as object[]) : []
      const selectedIds = dataValueList.map((it) => it?.['id'])
      return (
        <InlineComponentsWrapper inputWidth={'w-full'}>
          <MultiSelectorInlineInputComponent<string, IStringDropDownItem>
            getItems={() => getData?.() ?? Promise.resolve([])}
            onValueSubmitted={(value) => {
              if (value) onChange(value)
            }}
            selectedIds={selectedIds}
            initialItems={_getMultiEnumValue(
              dataValueList,
              dataFiled?.selector,
            )}
            getItemLabel={(item) => item.name ?? ''}
            cancelButton={true}
          />
        </InlineComponentsWrapper>
      )
    }

    return ''
  }

  const missingSystemType = errors
    ?.flat()
    .includes('system_type_not_configured_for_syntax')

  return (
    <div className="flex content-center items-center w-full">
      {!!errors?.length && (
        <Tooltip
          message={errors?.map((error) => t(error ?? '')).join(', ')}
          isError
          minWidth={'128'}
          textWrap={'max-w-48 text-wrap'}
        >
          <Error className={'fill-red-danger text-xl cursor-default'} />
        </Tooltip>
      )}
      {missingSystemType && (
        <Tooltip message={t('create_new_system_type')}>
          <AddCircle
            onClick={openSystemTypeForm}
            className={'fill-blue-root text-xl hover:fill-blue-root-focus'}
          />
        </Tooltip>
      )}
      {dataComponent(value ?? '', dataFiled?.dataType)}
    </div>
  )
}

export default DocValidationCell

import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { IConstructionWagon } from '../../service/OrgTypes'
import { capFirstLetter } from '../../utility/utils'
import { getInfoWrapperStyle } from '../MUtils'
import FloatingButton from '../components/FloatingButton'

interface IMVognInfoProps {
  wagon: IConstructionWagon
  noPadding?: boolean
  isCard?: boolean
  onFloatingBtnClick?: () => void
  sticky?: boolean
  isTavlamote?: boolean
  isWorkOps?: boolean
  openDetails?: boolean
}

const MVognInfo = ({
  wagon,
  noPadding = false,
  isCard = false,
  onFloatingBtnClick,
  sticky = true,
  isTavlamote = false,
  isWorkOps = false,
  openDetails = false,
}: IMVognInfoProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={getInfoWrapperStyle(
        noPadding,
        onFloatingBtnClick,
        isCard,
        sticky,
      )}
    >
      {onFloatingBtnClick && (
        <FloatingButton
          isTavlamote={isTavlamote}
          isWorkOps={isWorkOps}
          floating={false}
          onClick={onFloatingBtnClick}
        />
      )}
      <div className={`w-full border-t-green-300 pl-2`}>
        <details
          open={openDetails}
          className={'-ml-2 border border-gray-300 rounded-md py-1.5'}
        >
          <summary
            className={'font-semibold text-gray-600 text-xs px-3'}
          >{`${capFirstLetter(t('wagon_details'))}`}</summary>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium'}>
              {capFirstLetter(t('control_area'))}
            </div>
            <div className={'w-1/2 font-light pl-2'}>
              {wagon?.control_area?.title}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium'}>
              {capFirstLetter(t('wagon'))}
            </div>
            <div className={'w-1/2 font-light pl-2'}>{wagon?.title}</div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium'}>
              {capFirstLetter(t('start_date'))}
            </div>
            <div className={'w-1/2 font-light pl-2'}>
              {moment(wagon?.startTime).format('ddd MMM DD YYYY')}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium'}>
              {capFirstLetter(t('end_date'))}
            </div>
            <div className={'w-1/2 font-light pl-2'}>
              {moment(wagon?.startTime)
                .add(
                  wagon?.extended_duration ? wagon.extended_duration - 1 : 0,
                  'days',
                )
                .format('ddd MMM DD YYYY')}
            </div>
          </div>
          <div
            className={
              'flex justify-between items-center my-1 text-gray-500 text-xs px-2'
            }
          >
            <div className={'w-1/2 font-medium capitalize'}>
              {t('description')}
            </div>
            <div className={'w-1/2 font-light pl-2'}>{wagon?.subject}</div>
          </div>
        </details>
      </div>
    </div>
  )
}

export default MVognInfo

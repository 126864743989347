import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

const stringy = <T>(value: T): string => {
  return JSON.stringify({ value: value })
}

const parse = <T>(str: string): T => {
  return JSON.parse(str).value
}

const usePersistedState = <T>(
  name: string,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(defaultValue)
  const nameRef = useRef(name)

  useEffect(() => {
    try {
      const storedValue = localStorage.getItem(name)
      if (storedValue !== null) {
        setValue(parse<T>(storedValue))
      } else {
        localStorage.setItem(name, stringy(defaultValue))
      }
    } catch {
      setValue(defaultValue)
    }
  }, [])

  useEffect(() => {
    try {
      localStorage.setItem(nameRef.current, stringy(value))
    } catch {
      /**/
    }
  }, [value])

  useEffect(() => {
    const lastName = nameRef.current
    if (name !== lastName) {
      try {
        localStorage.setItem(name, stringy(value))
        nameRef.current = name
        localStorage.removeItem(lastName)
      } catch {
        /**/
      }
    }
  }, [name])

  return [value, setValue]
}

export default usePersistedState

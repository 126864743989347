import { ExportData } from 'src/context/ExportContext/ExportTypes'

export const deliveriesExportData: ExportData = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/deliveries_filter/pages/0/0',
    method: 'POST',
    body: {},
  },
  name: 'deliveries',
}

export const deliveryForPlanningExport = {
  ...deliveriesExportData,
  displayName: 'deliveries',
  columns: [
    'id',
    'actions',
    'title',
    'status',
    'responsible',
    'comments',
    'deadline',
    'duration',
    'delay',
    'closed_date',
    'children',
    'milestones',
    'mainprocess',
    'theme',
    'type',
    'improvements',
    'discipline',
    'contract',
    'reporter',
    'baseline',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'taskCtrl_child_0',
    'name',
    'status',
    'responsible.firstName||responsible.lastName',
    'taskCtrl_child_1',
    'endTime',
    'duration',
    'delay',
    'closed_date',
    'open_children',
    'mile_stone.record_id||mile_stone.name',
    'main_process.name',
    'team.name',
    'tag.name',
    'improvement.title',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'reporter.firstName||reporter.lastName',
    'baseline',
    'created_at',
    'updated_at',
  ],
}

import { Moment } from 'moment/moment'
import { TreeNodeInArray } from 'react-simple-tree-menu'
import { RGJsonData } from 'relation-graph-react'
import { IDataPoint } from 'src/components/charts/dashboard/CustomLineChart'
import {
  ISystem as IGridSystem,
  ISystemConfig,
} from 'src/components/grid/Grid/types/ISystem'
import { FmiStatus } from 'src/components/system/system-fmi/FmiSelectorsWithComment'
import { IFilterObject } from 'src/fdvu/types/IFilterObject'
import { ISystemGroups } from 'src/fdvu/types/IRequirement'
import { ICreateSystemsConnection } from 'src/page/system/SelectSystemConnections'
import { IMetaField } from '../components/system/system-type-fields/SystemTypeFieldsForm'
import { ImportTask } from '../context/ImportContext/ImportTypes'
import {
  IPaginatedSystemImportHistories,
  IPaginatedSystemTypes,
  IPaginatedSystemTypeUniques,
  IPaginateResult,
  ISystem,
  ISystemCompact,
  ISystemConnectionTableType,
  ISystemConnectionType,
  ISystemFMI,
  ISystemFMIGroup,
  ISystemFMIStatusMapper,
  ISystemImportHistory,
  ISystemMMI,
  ISystemRecordUpdate,
  ISystemTask,
  ISystemType,
  ISystemTypeUnique,
  ITestSystemGroup,
  IValidateResponse,
} from './OrgTypes'
import { request } from './service-utils'

export const createSystem = (
  system: Omit<ISystem, 'record_id'>,
  projectId: number,
  systemSettingId: number,
  parentIds?: number[],
): Promise<ISystem> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/systems`,
    'POST',
    {
      ...system,
      parent_ids: parentIds,
    },
  )
}

export const editSystem = (
  system: NullablePartial<ISystem>,
): Promise<ISystem> => {
  return request(`systems/${system.id}`, 'PUT', system)
}

export const massEditSystem = (
  projectId: number,
  systemIds: number[],
  system: Partial<ISystem>,
) => {
  return request(`projects/${projectId}/systems/mass_update`, 'POST', {
    ...system,
    system_ids: systemIds,
  })
}

export const getSystems = (projectId: number): Promise<ISystemCompact[]> => {
  return request(`projects/${projectId}/systems`, 'GET')
}

export const getFileContainerSystems = (
  projectId: number,
  fileContainerId: number,
): Promise<ISystem[]> => {
  return request(
    `projects/${projectId}/file_containers/${fileContainerId}/systems`,
    'GET',
  )
}

export const updateSystemFileContainers = (
  systemId: number,
  fileContainerIds: number[],
  removedFileContainerIds: number[],
): Promise<ISystem[]> => {
  return request(`/systems/${systemId}/add_file_containers`, 'POST', {
    file_container_ids: fileContainerIds,
    removed_file_container_ids: removedFileContainerIds,
  })
}

export const massUpdateSystemFileContainers = (
  systemIds: number[],
  fileContainerIds: number[],
  removedFileContainerIds: number[],
  overwrite?: boolean,
): Promise<ISystem[]> => {
  return request(`/systems/mass_add_file_containers`, 'POST', {
    system_ids: systemIds,
    file_container_ids: fileContainerIds,
    removed_file_container_ids: removedFileContainerIds,
    overwrite,
  })
}

export const updateTestSystemGroups = (
  systemId: number,
  testSystemGroupIds: number[],
): Promise<ISystem[]> => {
  return request(`/systems/${systemId}/add_test_system_groups`, 'POST', {
    test_system_group_ids: testSystemGroupIds,
  })
}

export const massUpdateTestSystemGroups = (
  systemIds: number[],
  testSystemGroupIds: number[],
  overwrite: boolean,
): Promise<ISystem[]> => {
  return request(`/systems/mass_add_test_system_groups`, 'POST', {
    system_ids: systemIds,
    test_system_group_ids: testSystemGroupIds,
    overwrite,
  })
}

export const massRemoveSystemsFromTestSystemGroup = (
  systemIds: number[],
  testSystemGroupId: number,
): Promise<ITestSystemGroup> => {
  return request(
    `/systems/mass_remove_systems_from_test_system_group`,
    'POST',
    {
      system_ids: systemIds,
      test_system_group_id: testSystemGroupId,
    },
  )
}

export const updateSystemDisciplines = (
  projectId: number,
  systemId: number,
  disciplinesIds: number[],
): Promise<ISystem[]> => {
  return request(
    `projects/${projectId}/systems/${systemId}/add_disciplines`,
    'POST',
    {
      discipline_ids: disciplinesIds,
    },
  )
}

export const getSystem = (id: number): Promise<ISystem> => {
  return request(`/systems/${id}`, 'GET')
}

export const getUserDefinedFieldsForSystems = (
  projectId: number,
  systemIds: number[],
): Promise<IMetaField[]> => {
  return request(`projects/${projectId}/systems/user_defined_fields`, 'POST', {
    system_ids: systemIds,
  })
}
export const getTestSystemGroups = (id: number): Promise<ISystem[]> => {
  return request(`/test_system_groups/${id}/systems`, 'GET')
}

export const getSystemsWithPage = (
  projectId: number,
  page: number,
  perPage: number,
  filter?: any,
): Promise<IPaginateResult<ISystem>> => {
  return request(
    `projects/${projectId}/systems/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getSystemsWithoutPage = (
  projectId: number,
  filter?: any,
): Promise<ISystem[]> =>
  getSystemsWithPage(projectId, 0, 0, filter).then((res) => res.items)

export const getProjectSystems = (projectId: number): Promise<ISystem[]> => {
  return request(`projects/${projectId}/systems`, 'GET')
}

export const deleteSystem = (system: ISystem) => {
  return request(`systems/${system.id}`, 'DELETE', {}, true)
}

export const createSystemType = (
  projectId: number,
  systemSettingId: number,
  system: Omit<ISystemType, 'id'>,
): Promise<ISystemType> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/system_types`,
    'POST',
    system,
  )
}

export const validateSystemType = (
  projectId: number,
  systemSettingId: number,
  systemType: Partial<ISystemType>,
): Promise<{ system_type_exists: boolean; system_type_valid: boolean }> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/validate_system_type`,
    'POST',
    systemType,
  )
}

export const editSystemType = (
  systemType: Partial<ISystemType>,
): Promise<ISystemType> => {
  return request(`system_types/${systemType.id}`, 'PUT', systemType)
}

export const massEditSystemType = (
  projectId: number,
  systemTypeIds: number[],
  systemType: Partial<ISystemType>,
) => {
  return request(`projects/${projectId}/system_types/mass_update`, 'POST', {
    ...systemType,
    system_type_ids: systemTypeIds,
  })
}

export const getSystemType = (systemTypeId: number): Promise<ISystemType> => {
  return request(`system_types/${systemTypeId}`, 'GET')
}

export const getSystemListCategories = (
  projectId: number,
  filter: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/systems_list/categories`,
    'POST',
    filter,
  )
}

export const getSystemsList = (
  projectId: number,
  filter: any,
  page: number,
  per_page = 50,
): Promise<{
  system_configs: Record<number, ISystemConfig>
  systems: IGridSystem[]
  total_pages: number
}> => {
  return request(
    `projects/${projectId}/systems_list/${page}/${per_page}`,
    'POST',
    filter,
  )
}

export const getSystemsSyntaxTree = (
  projectId: number,
  openNodes: string[],
  reload?: boolean,
): Promise<{ [key: string]: TreeNodeInArray }> => {
  return request(`projects/${projectId}/systems_setting_tree`, 'POST', {
    reload: reload,
    open_nodes: openNodes,
  })
}

export const getProjectSystemTypes = (
  projectId: number,
): Promise<ISystemType[]> => {
  return request(`projects/${projectId}/system_types_only`, 'GET')
}

export const getProjectSystemTypesOnly = (
  projectId: number,
  systemSettingId?: number,
): Promise<ISystemType[]> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/system_types_only`,
    'GET',
  )
}

export interface ISystemStatsDisciplineBreakdown {
  total_systems: number
  discipline_id: number
  discipline: {
    id: number
    name: string
    shortName: string
    color: string
    shape: string
  }
  system_statuses: Array<{
    id: number
    status: string
    name: string
    system_count: number
  }>
  done_systems: number
  test_work_group_stats: {
    total: number
    statuses: Array<{
      id: number
      status: string
      name: string
      count: number
    }>
  }
  test_system_group_stats: {
    total: number
    statuses: Array<{
      id: number
      status: string
      name: string
      count: number
    }>
  }
}
export interface ISystemStats {
  per_discipline_breakdown: ISystemStatsDisciplineBreakdown[]
  per_category_breakdown: Array<{
    name: string
    system_syntax_categories: Array<{
      category: {
        id: number
        syntax_string: string
        name: string
      }
      total_systems: number
      system_statuses: Array<{
        id: number
        status: string
        name: string
        system_count: number
      }>
    }>
  }>
  system_stats: {
    total_systems: number
    system_statuses: Array<{
      id: number
      status: string
      name: string
      system_count: number
    }>
  }
  system_statuses: Array<{
    id: number
    name: string
    description: string
    parent_type: string
    status: string
  }>
  test_work_group_stats: {
    total: number
    statuses: Array<{
      id: number
      status: string
      name: string
      count: number
    }>
  }
  test_work_group_statuses: Array<{
    id: number
    name: string
    description: string
    parent_type: string
    status: string
  }>
  test_system_group_stats: {
    total: number
    statuses: Array<{
      id: number
      status: string
      name: string
      count: number
    }>
  }
  test_system_group_statuses: Array<{
    id: number
    name: string
    description: string
    parent_type: string
    status: string
  }>
}
export const getProjectSystemStats = (
  projectId: number,
  reload: boolean,
): Promise<ISystemStats | null | undefined> => {
  return request(`projects/${projectId}/systems/stats_breakdown`, 'POST', {
    reload: reload,
  })
}
export const getSystemsCreatedAt = (
  projectId: number,
  startDate: Moment,
  endDate: Moment,
  timeView: string,
) => {
  return request<IDataPoint[]>(
    `projects/${projectId}/systems/stats/created_at?start_date=${startDate.format(
      'YYYY-MM-DD',
    )}&end_date=${endDate.format('YYYY-MM-DD')}&time_view=${timeView}`,
    'GET',
  )
}

export const getChildrenSystemStats = (
  projectId: number,
  page: number,
  perPage: number,
  filter?: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/systems_stats/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getProjectCategorySystemTypes = (
  projectId: number,
  categoryId: number,
  page: number,
  perPage: number,
  filter?: any,
): Promise<IPaginatedSystemTypes> => {
  return request(
    `projects/${projectId}/system_syntax_categories/${categoryId}/system_types/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getCategoryMatchingSystemTypes = (
  projectId: number,
  categoryId: number,
  page: number,
  perPage: number,
  filter?: any,
): Promise<IPaginatedSystemTypes> => {
  return request(
    `projects/${projectId}/system_syntax_categories/${categoryId}/matching_system_types/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const addSystemTypesToCategory = (
  projectId: number,
  categoryId: number,
  systemTypeIds: number[],
): Promise<ISystemType[]> => {
  return request(
    `projects/${projectId}/system_syntax_categories/${categoryId}/add_system_types`,
    'POST',
    { system_type_ids: systemTypeIds },
  )
}

export const getPaginatedSystemsTypes = (
  projectId: number,
  systemSettingId: number,
  page: number,
  perPage: number,
  filter?: any,
): Promise<IPaginatedSystemTypes> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/system_types/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getPaginatedSystemImportHistories = (
  projectId: number,
  systemSettingId: number,
  page: number,
  perPage = 30,
  filter?: any,
): Promise<IPaginatedSystemImportHistories> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/system_import_histories/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getSingleSystemHistory = (
  systemImportHistory: number,
): Promise<ISystemImportHistory> => {
  return request(`system_import_histories/${systemImportHistory}`, 'GET')
}

export const deleteSystemType = (systemTypeId: number): Promise<any> => {
  return request(`system_types/${systemTypeId}`, 'DELETE', {}, true)
}

export const getProjectSystemConnectionTypes = (
  projectId: number,
): Promise<ISystemConnectionType[]> => {
  return request(`projects/${projectId}/system_connection_types`, 'GET')
}

export const getSystemConnectionGraph = (
  projectId: number,
  systemId: number,
): Promise<RGJsonData> => {
  return request(
    `projects/${projectId}/systems/system_connections_graph`,
    'POST',
    { system_id: systemId },
  )
}

export const expandSystemConnectionGraph = (
  projectId: number,
  systemId: number,
  openTargetNodes: number[],
): Promise<RGJsonData> => {
  return request(
    `projects/${projectId}/systems/system_connections_graph/expand`,
    'POST',
    { system_id: systemId, open_sibling_node_ids: openTargetNodes },
  )
}

export const createSystemConnectionType = (
  projectId: number,
  systemConnectionType: Omit<ISystemConnectionType, 'id'>,
): Promise<ISystemConnectionType> => {
  return request(
    `/projects/${projectId}/system_connection_types`,
    'POST',
    systemConnectionType,
  )
}

export const editSystemConnectionType = (
  projectId: number,
  systemConnectionType: ISystemConnectionType,
): Promise<ISystemConnectionType> => {
  return request(
    `/system_connection_types/${systemConnectionType.id}`,
    'PUT',
    systemConnectionType,
  )
}

export const deleteSystemConnectionType = (
  systemConnectionType: ISystemConnectionType,
): Promise<any> => {
  return request(
    `system_connection_types/${systemConnectionType.id}`,
    'DELETE',
    {},
    true,
  )
}

export const getProjectSystemMMIs = (
  projectId: number,
): Promise<ISystemMMI[]> => {
  return request(`projects/${projectId}/system_mmis`, 'GET')
}

export const getProjectSystemMMIsOnly = (
  projectId: number,
): Promise<ISystemMMI[]> => {
  return request(`projects/${projectId}/system_mmis_only`, 'GET')
}

export const addSystemMMI = (
  projectId: number,
  systemMMI: Omit<ISystemMMI, 'id'>,
): Promise<ISystemMMI> => {
  return request(`/projects/${projectId}/system_mmis`, 'POST', systemMMI)
}

export const editSystemMMI = (
  projectId: number,
  systemMMI: ISystemMMI,
): Promise<ISystemMMI> => {
  return request(`/system_mmis/${systemMMI.id}`, 'PUT', systemMMI)
}

export const getProjectSystemFMIs = (
  projectId: number,
): Promise<ISystemFMI[]> => {
  return request(`projects/${projectId}/system_fmis`, 'GET')
}

export const getGroupSystemFMIs = (groupId: number): Promise<ISystemFMI[]> => {
  return request(`system_fmis/system_fmi_groups/${groupId}`, 'GET')
}
export const getProjectSystemFMIsOnly = (
  projectId: number,
): Promise<ISystemFMI[]> => {
  return request(`projects/${projectId}/system_fmis_only`, 'GET')
}

export const addSystemFMI = (
  projectId: number,
  systemFMI: Omit<ISystemFMI, 'id'>,
): Promise<ISystemFMI> => {
  return request(`/projects/${projectId}/system_fmis`, 'POST', systemFMI)
}

export const editSystemFMI = (systemFMI: ISystemFMI): Promise<ISystemFMI> => {
  return request(`/system_fmis/${systemFMI.id}`, 'PUT', systemFMI)
}

export const deleteSystemFMI = (systemFMI: ISystemFMI): Promise<any> => {
  return request(`system_fmis/${systemFMI.id}`, 'DELETE', {}, true)
}

export const getProjectSystemFMIGroups = (
  projectId: number,
): Promise<ISystemFMIGroup[]> => {
  return request(`projects/${projectId}/system_fmi_groups`, 'GET')
}

export const addSystemFMIGroup = (
  projectId: number,
  systemFMIGroup: Omit<ISystemFMIGroup, 'id'>,
): Promise<ISystemFMIGroup> => {
  return request(
    `/projects/${projectId}/system_fmi_groups`,
    'POST',
    systemFMIGroup,
  )
}

export const editSystemFMIGroup = (
  systemFMIGroup: ISystemFMIGroup,
  projectId: number,
): Promise<ISystemFMIGroup> => {
  return request(
    `/projects/${projectId}/system_fmi_groups/${systemFMIGroup.id}`,
    'PUT',
    systemFMIGroup,
  )
}

export const deleteSystemFMIGroup = (
  systemFMIGroup: ISystemFMIGroup,
  projectId: number,
): Promise<any> => {
  return request(
    `/projects/${projectId}/system_fmi_groups/${systemFMIGroup.id}`,
    'DELETE',
    {},
    true,
  )
}

export const addSystemFMIStatusMapper = (
  projectId: number,
  systemFmiStatusMapper: Omit<ISystemFMIStatusMapper, 'id'>,
): Promise<ISystemFMIStatusMapper> => {
  return request(
    `/projects/${projectId}/system_fmi_status_mappers`,
    'POST',
    systemFmiStatusMapper,
  )
}

export const updateSystemFMIStatusMapper = (
  status: FmiStatus,
  systemFmiStatusMapper: ISystemFMIStatusMapper,
  projectId: number,
): Promise<ISystemFMIStatusMapper> => {
  return request(
    `/projects/${projectId}/system_fmi_status_mappers/${systemFmiStatusMapper.id}`,
    'PUT',
    { systemFmiStatusMapper, status },
  )
}

export const deleteSystemMMI = (
  projectId: number,
  systemMMIId: number,
): Promise<any> => {
  return request(
    `/projects/${projectId}/system_mmis/${systemMMIId}`,
    'DELETE',
    {},
    true,
  )
}

export const systemImportFromJson = (
  projectID: number,
  systemSettingId: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectID}/system_settings/${systemSettingId}/systems/validate_for_import_job`,
    'POST',
    data,
  )
}

export const systemTypeImportFromJson = (
  projectId: number,
  systemSettingId: number,
  data: any,
  syntaxId?: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/system_types/import_from_json/${
      syntaxId ?? ''
    }`,
    'POST',
    data,
  )
}

export const getValidNewSystemData = (
  projectID: number,
  data: IValidateResponse,
  systemSettingId: number,
): Promise<any> => {
  return request(
    `projects/${projectID}/system_settings/${systemSettingId}/systems/valid_new_systems_data`,
    'POST',
    data,
  )
}

export const systemTypesBulkDelete = (
  projectID: number,
  ids: any,
): Promise<any> => {
  return request(
    `projects/${projectID}/system_types/remove_multiple`,
    'POST',
    {
      ids,
    },
    true,
  )
}

export const removeSystemTypesFromSyntaxCategory = (
  projectId: number,
  syntaxCategoryId: number,
  ids: number[],
): Promise<any> => {
  return request(
    `projects/${projectId}/system_syntax_categories/${syntaxCategoryId}/remove_system_types`,
    'POST',
    {
      system_type_ids: ids,
    },
    true,
  )
}

export const systemBulkDelete = (projectID: number, ids: any): Promise<any> => {
  return request(`projects/${projectID}/systems/remove_multiple`, 'POST', {
    ids,
  })
}

export const deleteBulkSystemTypeFields = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(
    `/projects/${projectId}/user_defined_fields/remove_multiple`,
    'POST',
    {
      ids: data,
    },
  )
}

export const validateUserDefinedFieldsName = (
  projectID: number,
  name: string,
): Promise<{ user_defined_field: IMetaField }> => {
  return request(
    `projects/${projectID}/user_defined_fields/validate_name`,
    'POST',
    {
      name,
    },
  )
}

export const getAllParentsOfSystem = (systemId: number) => {
  return request(`systems/${systemId}/ancestors`, 'GET')
}

export const getSystemHierarchy = (
  projectId: number,
  parent_type?: string,
  parent_id?: string,
  parent_key?: string,
  load_parents?: boolean,
) => {
  return request(`/projects/${projectId}/systems_status_tree`, 'POST', {
    parent_type: parent_type,
    parent_id: parent_id,
    parent_key: parent_key,
    load_parents: load_parents,
  })
}

export const getSystemsConnectedToSystem = (
  projectId: number,
  page: number,
  pageSize: number,
  filter: any = {},
): Promise<IPaginateResult<ISystemConnectionTableType>> => {
  return request(
    `projects/${projectId}/systems/system_connections/pages/${page}/${pageSize}`,
    'POST',
    filter,
  )
}

export const addSystemsConnectedToSystem = (
  projectId: number,
  systemId: number,
  systemsConnection: ICreateSystemsConnection,
): Promise<ISystemConnectionTableType> => {
  return request(
    `/projects/${projectId}/systems/${systemId}/add_system_connections`,
    'POST',
    systemsConnection,
  )
}

export const removeSystemsConnectedToSystem = (
  projectId: number,
  systemId: number,
  connectionIds: number[],
) => {
  return request(
    `/projects/${projectId}/systems/${systemId}/remove_system_connections`,
    'POST',
    { connection_ids: connectionIds },
    false,
  )
}

export const updateSystemsConnectedToSystem = (
  projectId: number,
  systemsConnectionId: number,
  data: any,
) => {
  return request(
    `/projects/${projectId}/system_connections/${systemsConnectionId}`,
    'PUT',
    data,
  )
}

export const getSystemTasks = (
  projectId: number,
  systemIds: number[],
  disciplineIds: number[],
): Promise<ISystemTask[]> => {
  return request(`/projects/${projectId}/system_tasks/show_many`, 'POST', {
    system_ids: systemIds,
    discipline_ids: disciplineIds,
  })
}

export const updateSystemTask = (
  projectId: number,
  id: number,
  data: Partial<ISystemTask>,
) => {
  return request(`/projects/${projectId}/system_tasks/${id}`, 'PUT', {
    ...data,
  })
}

export const massCreateOrUpdateSystemTasks = (
  projectId: number,
  systemTasks: Omit<ISystemTask, 'id'>[],
  tasksToDelete?: number[],
): Promise<ISystemTask[]> => {
  return request(
    `/projects/${projectId}/system_tasks/mass_create_or_update`,
    'POST',
    {
      system_tasks: systemTasks,
      delete_system_tasks: tasksToDelete,
    },
  )
}

export const systemTypesUniqueBulkDelete = (
  projectID: number,
  ids: number[],
) => {
  return request(
    `projects/${projectID}/system_type_uniques/remove_multiple`,
    'POST',
    {
      ids,
    },
    true,
  )
}

export const deleteSystemTypeUnique = (
  projectId: number,
  systemTypeUniqueId: number,
) => {
  return request(
    `projects/${projectId}/system_type_uniques/${systemTypeUniqueId}`,
    'DELETE',
    {},
    true,
  )
}

export const getPaginatedSystemsTypeUniques = (
  projectId: number,
  page: number,
  perPage: number,
  filter?: any,
): Promise<IPaginatedSystemTypeUniques> => {
  return request(
    `projects/${projectId}/system_type_uniques/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getSystemsTypeUniques = (
  projectId: number,
): Promise<ISystemTypeUnique[]> => {
  return request(`projects/${projectId}/system_type_uniques`, 'GET')
}

export const createSystemTypeUnique = (
  projectId: number,
  systemTyUnique: Omit<ISystemTypeUnique, 'id'>,
): Promise<ISystemTypeUnique> => {
  return request(
    `projects/${projectId}/system_type_uniques`,
    'POST',
    systemTyUnique,
  )
}

export const editSystemTypeUnique = (
  projectId: number,
  systemTyUnique: Partial<ISystemTypeUnique>,
): Promise<ISystemTypeUnique> => {
  return request(
    `projects/${projectId}/system_type_uniques/${systemTyUnique.id}`,
    'PUT',
    systemTyUnique,
  )
}

export const getSystemTypeUnique = (
  projectId: number,
  systemTypeUniqueId: number,
): Promise<ISystemTypeUnique> => {
  return request(
    `projects/${projectId}/system_type_uniques/${systemTypeUniqueId}`,
    'GET',
  )
}

export const validateSystemNumber = (
  projectId: number,
  systemSettingId: number,
  recordId: string,
  systemId: number,
): Promise<ISystemRecordUpdate> => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/validate_record_id`,
    'POST',
    { record_id: recordId, system_id: systemId },
  )
}

export const updateSystemNumber = (
  projectId: number,
  systemSettingId: number,
  systemId: number,
  update: ISystemRecordUpdate,
) => {
  return request(
    `projects/${projectId}/system_settings/${systemSettingId}/systems/${systemId}/update_record_id`,
    'POST',
    update,
  )
}

export const downloadSystemAttachments = (
  projectId: number,
  systemIds: number[],
): Promise<ImportTask> => {
  return request(`projects/${projectId}/systems/download_files`, 'POST', {
    system_ids: systemIds,
  })
}

export const searchForSystemStrings = (
  projectId: number,
  searchTerm: string,
  systemSyntaxCategoryId: number,
  systemSettingId: number,
): Promise<string[]> => {
  return request(`projects/${projectId}/systems/syntax_search`, 'POST', {
    search_term: searchTerm,
    system_syntax_category_id: systemSyntaxCategoryId,
    system_setting_id: systemSettingId,
  })
}

export const systemsBySyntaxFilter = (
  projectId: number,
  filterObjects: IFilterObject[],
): Promise<ISystemGroups> => {
  return request(`projects/${projectId}/systems/syntax_filter`, 'POST', {
    search_filters: filterObjects.map((object) => ({
      search_filters: object.syntaxSearchFilters,
      system_setting_id: object.systemSettingId,
      applies_to_levels: object.appliesToLevels,
    })),
  })
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { IStepData } from 'src/document/types/IFlow'
import Loader from 'src/ui-elements/loader/Loader'
import { IUploadResponse, MovedSteps } from '../types'
import DocUploadSummery from './DocUploadSummery'
import ImportedFilesList from './ImportedFilesList'
import RejectedFileList from './RejectedFilesList'

interface UploadSummeryProps {
  folderId: number
  setMovedSteps: (movedSteps: MovedSteps[]) => void
  uploadedFiles?: UploadedFiles
}

export interface Step {
  toStep: number
  stepName?: string
  stepSequence?: number
}

interface StepMove {
  step: IStepData
  movedDoc: number
}

export interface UploadedFiles {
  [key: string]: IUploadResponse
}

const UploadSummery: FC<UploadSummeryProps> = ({
  folderId,
  setMovedSteps,
  uploadedFiles,
}) => {
  const [fitFiles, setFitFiles] = useState<UploadedFiles>({})
  const [rejectFiles, setRejectFiles] = useState<UploadedFiles>({})
  const stepRef = useRef<StepMove[]>([])

  const getUploadFiles = useCallback(() => {
    if (!uploadedFiles) return
    const ret = {},
      unFit = {}
    Object.keys(uploadedFiles).forEach((fileName) => {
      const file = uploadedFiles[fileName]
      if (file.file_container_id) {
        ret[fileName] = file
      } else {
        unFit[fileName] = file
      }
    })
    setFitFiles(ret)
    setRejectFiles(unFit)
  }, [uploadedFiles])

  useEffect(() => {
    getUploadFiles()
  }, [getUploadFiles])

  const setMoveStep = (stepData: IStepData, docIds: number[]) => {
    const step = stepRef.current
    docIds.forEach((doc) => {
      if (!step.some((s) => s.movedDoc === doc)) {
        step.push({
          movedDoc: doc,
          step: stepData,
        })
      } else {
        step.forEach((s) => {
          if (s.movedDoc === doc) {
            s.step = stepData
          }
        })
      }
    })
    stepRef.current = step
    stepRefToMovedStep()
  }

  const stepRefToMovedStep = () => {
    const step = stepRef.current
    const moved: MovedSteps[] = []
    step.forEach((s) => {
      if (!moved.some((m) => m.step.id === s.step.id)) {
        moved.push({
          step: s.step,
          file_container_ids: [s.movedDoc],
        })
      } else {
        moved.forEach((m) => {
          if (m.step.id === s.step.id) {
            m.file_container_ids.push(s.movedDoc)
          }
        })
      }
    })
    setMovedSteps(moved)
  }

  return (
    <>
      {uploadedFiles ? (
        <div className="min-h-70vh w-fit my-4">
          <DocUploadSummery
            reject={false}
            quantity={
              Object.values(uploadedFiles).filter((v) => !!v.file_container_id)
                .length
            }
          >
            <ImportedFilesList
              setMovedStep={setMoveStep}
              uploadedFiles={fitFiles}
            />
          </DocUploadSummery>
          <DocUploadSummery
            reject={true}
            quantity={
              Object.values(uploadedFiles).filter((v) => !v.file_container_id)
                .length
            }
          >
            <RejectedFileList
              folderId={folderId}
              setMovedStep={setMoveStep}
              uploadedFiles={rejectFiles}
            />
          </DocUploadSummery>
        </div>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default UploadSummery

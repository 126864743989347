import { t } from 'i18next'
import { capitalize } from 'lodash'
import { MutableRefObject } from 'react'
import { twMerge } from 'tailwind-merge'
import { setColorForIcon } from 'src/components/discipline/disciplineUtils'
import {
  getProjectDisciplines,
  getUserDisciplines,
} from 'src/service/DisciplineService'
import { IContract, IDiscipline, IRoom, IUserData } from 'src/service/OrgTypes'
import {
  getProjectDisplineUsers,
  getProjectUsers,
} from 'src/service/UserService'
import { dualSelectorColumn, styleClass } from 'src/ui-elements/Table/Columns'
import { filterType } from 'src/ui-elements/list/ListTypes'
import {
  concatinateValuesForTable,
  contractComparator,
  displineComparator,
  userComparator,
} from 'src/utility/Utility'
import useListHelper from '../../ui-elements/list/UseListHelper'
import DualTableSelector from '../table-ResponsibleEditor/DualTableSelector'
import UserIcon from '../user/UserIcon'

const getUsers = async (
  projectId: number,
  projectUsers: any,
  id?: number,
): Promise<IUserData[]> => {
  if (id) {
    return getProjectDisplineUsers(projectId, id)
  }
  const users = projectUsers.current
  if (users.length > 0) {
    return Promise.resolve(users)
  } else {
    const pUsers = await getProjectUsers(projectId)
    projectUsers.current = pUsers
    return Promise.resolve(pUsers)
  }
}

const getDisciplines = async (
  projectId: number,
  projectDiscipline: any,
  id?: number,
): Promise<IDiscipline[]> => {
  if (id) {
    return getUserDisciplines(projectId, id)
  }
  const disciplines = projectDiscipline.current
  if (disciplines.length > 0) {
    return Promise.resolve(disciplines)
  } else {
    const pUsers = await getProjectDisciplines(projectId)
    projectDiscipline.current = pUsers
    return Promise.resolve(pUsers)
  }
}

/**
 * @deprecated Use ResponsibleAndDisciplineColumn instead
 */
export const ResponsibleColumn = (
  projectId: number,
  users: any,
  disciplines: any,
  updateResponsible: (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => void,
  disableEdit?: boolean,
) => {
  const { getUserFilterWithReporter } = useListHelper()
  return {
    name: 'responsible',
    size: '160',
    id: 'responsible',
    dataField: 'responsible||discipline',
    sortingField: 'responsible',
    filterType: filterType.DEFAULT,
    filterDataField: 'responsible.firstName||responsible.lastName',
    filterDataValue: 'responsible.id',
    getFilter: getUserFilterWithReporter,
    filter: [],
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? userComparator(a, b, direction) : 0) : 0,
    cell: ({ responsible, discipline }: any, row: number) => (
      <DualTableSelector
        rowId={row}
        selected={{
          primary: responsible,
          secondary: discipline,
        }}
        getAPI={{
          primary: (id) => getUsers(projectId, users, id),
          secondary: (id) => getDisciplines(projectId, disciplines, id),
        }}
        label={{ primary: 'responsible', secondary: 'discipline' }}
        dataFields={{
          primary: ['firstName', 'lastName'],
          secondary: ['shortName', 'name'],
        }}
        primaryUserSelector={true}
        onDataSelected={updateResponsible}
        displayContent={
          responsible ? (
            <UserIcon
              userId={responsible.id}
              firstName={responsible.firstName}
              lastName={responsible.lastName}
              image_url={responsible.image_url}
              inTable={true}
            />
          ) : (
            <span />
          )
        }
        disableEdit={disableEdit}
      />
    ),
  }
}

export const ResponsibleAndDisciplineColumn = (
  projectId: number,
  users: MutableRefObject<IUserData[]>,
  disciplines: MutableRefObject<IDiscipline[]>,
  updateResponsible: (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => void,
) => {
  const { getUserFilterWithReporter } = useListHelper()

  return dualSelectorColumn(
    'responsible',
    'discipline',
    {
      name: capitalize(t('responsible')),
      getFilter: getUserFilterWithReporter,
      field: 'responsible.firstName||responsible.lastName',
    },
    ['firstName', 'lastName'],
    ['shortName', 'name'],

    {
      primary: (id: number) => getUsers(projectId, users, id),
      secondary: (id: number) => getDisciplines(projectId, disciplines, id),
    },
    updateResponsible,
    (responsible?: IUserData) =>
      responsible ? (
        <UserIcon
          userId={responsible.id}
          firstName={responsible.firstName}
          lastName={responsible.lastName}
          image_url={responsible.image_url}
          inTable={true}
        />
      ) : (
        <span />
      ),
    undefined,
    true,
  )
}

export const DisciplineAndResponsibleColumn = (
  projectId: number,
  disciplines: MutableRefObject<IDiscipline[]>,
  users: MutableRefObject<IUserData[]>,
  updateDiscipline: (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => void,
) => {
  const { getDisciplineFilter } = useListHelper()

  return dualSelectorColumn(
    'discipline',
    'responsible',
    {
      name: capitalize(t('discipline')),
      getFilter: getDisciplineFilter,
      field: 'discipline.shortName||discipline.name',
    },
    ['shortName', 'name'],
    ['firstName', 'lastName'],
    {
      primary: (id: number) => getDisciplines(projectId, disciplines, id),
      secondary: (id: number) => getUsers(projectId, users, id),
    },
    updateDiscipline,
    undefined,
    undefined,
    false,
    true,
  )
}

/**
 * @deprecated Use DisciplineAndResponsibleColumn instead
 */
export const DisciplineColumn = (
  projectId: number,
  disciplines: any,
  users: any,
  updateDiscipline: (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => void,
  disableEdit?: boolean,
) => {
  const { getDisciplineFilter } = useListHelper()
  return {
    name: 'discipline',
    size: '200',
    id: 'discipline',
    dataField: 'discipline||responsible',
    sortingField: 'discipline',
    filterType: filterType.DEFAULT,
    filterDataField: 'discipline.shortName||discipline.name',
    filterDataValue: 'discipline.id',
    filter: [],
    getFilter: getDisciplineFilter,
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? displineComparator(a, b, direction) : 0) : 0,
    cell: ({ discipline, responsible }: any, row: number) => (
      <DualTableSelector
        rowId={row}
        selected={{
          primary: discipline,
          secondary: responsible,
        }}
        getAPI={{
          primary: (id) => getDisciplines(projectId, disciplines, id),
          secondary: (id) => getUsers(projectId, users, id),
        }}
        label={{ primary: 'discipline', secondary: 'responsible' }}
        dataFields={{
          primary: ['shortName', 'name'],
          secondary: ['firstName', 'lastName'],
        }}
        secondaryUserSelector={true}
        onDataSelected={updateDiscipline}
        displayContent={GetDisciplineUi(discipline)}
        disableEdit={disableEdit}
      />
    ),
  }
}

export const LocationColumn = (id = 'room', disabled: boolean) => {
  const { getRoomFilter } = useListHelper()
  return {
    name: id,
    size: '200',
    id: `${id}_id`,
    dataField: id,
    sortingField: 'room',
    filterType: filterType.DEFAULT,
    filterDataField: `${id}.functional_room_number||${id}.room_name`,
    filter: [],
    disabled,
    getFilter: getRoomFilter,
    cell: (room: IRoom) => (
      <span>
        {room ? `${room.functional_room_number} ${room.room_name}` : ''}
      </span>
    ),
  }
}

export const ContractColumn = (disabled?: boolean, hidden?: boolean) => {
  const { getContractFilter } = useListHelper()
  return {
    name: 'contract',
    size: '200',
    id: 'contract',
    dataField: 'contract',
    sortingField: 'contract',
    filterType: filterType.DEFAULT,
    filterDataField: 'contract.contractNumber||contract.contractName',
    filterDataValue: 'contract.id',
    filter: [],
    disabled: disabled,
    hidden: hidden,
    getFilter: getContractFilter,
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? contractComparator(a, b, direction) : 0) : 0,
    cell: (contract: IContract) => (
      <div className={styleClass.cell}>
        {contract
          ? concatinateValuesForTable(
              contract.contractNumber,
              contract.contractName,
            )
          : ''}
      </div>
    ),
  }
}

export const ContractsColumn = (disabled?: boolean, hidden?: boolean) => {
  const { getContractFilter } = useListHelper()
  return {
    name: 'contracts',
    size: '200',
    id: 'contracts',
    dataField: 'contracts',
    filterType: filterType.DEFAULT,
    filterDataField: 'contracts->contractNumber',
    filterDataValue: 'contract.id',
    filterFiled: 'contracts',
    filter: [],
    disabled: disabled,
    hidden: hidden,
    getFilter: getContractFilter,
    cell: (contracts: IContract[]) => (
      <div className={styleClass.cell}>
        {contracts
          ? contracts
              .map((contract) =>
                concatinateValuesForTable(
                  contract.contractNumber,
                  contract.contractName,
                ),
              )
              .join(', ')
          : ''}
      </div>
    ),
  }
}

export const DisciplineColumnWithOutEditing = (disabled?: boolean) => {
  const { getDisciplineFilter } = useListHelper()
  return {
    name: 'discipline',
    size: '200',
    id: 'discipline',
    dataField: 'discipline',
    sortingField: 'discipline',
    filterType: filterType.DEFAULT,
    filterDataField: 'discipline.shortName',
    filterDataValue: 'discipline.id',
    filter: [],
    disabled: disabled,
    getFilter: getDisciplineFilter,
    comparator: (a: any, b: any, direction: boolean) =>
      a ? (b ? displineComparator(a, b, direction) : 0) : 0,
    cell: (discipline: IDiscipline) =>
      discipline ? (
        <span className={twMerge(styleClass.cell, 'flex flex-row')}>
          <span className={'mr-2'}>
            {setColorForIcon({
              color: discipline.color,
              shape: discipline.shape,
            })}
          </span>
          <span>
            {discipline
              ? concatinateValuesForTable(discipline.shortName, discipline.name)
              : ''}
          </span>
        </span>
      ) : (
        <span />
      ),
  }
}

const GetDisciplineUi = (discipline: IDiscipline) => {
  return (
    <span>
      {discipline
        ? concatinateValuesForTable(discipline.shortName, discipline.name)
        : ''}
    </span>
  )
}

import { IEmail } from 'src/service/OrgTypes'

export enum ExportStatus {
  Success = 0,
  Failed = 1,
  Processing = 2,
  Initialized = 3,
  Finalizing = 4,
}

export interface ExportTask {
  userId: string
  uuId: string
  eventName: string
  createdAt: number
  status: ExportStatus
  result?: string
  error?: string
  progress?: number
  totalCount?: number
  fileName: string
}

export interface ExportResponse {
  value: ExportTask
}

export type ApiRequest = {
  url: string
  method: 'POST' | 'GET'
  body?: Record<string, string | number | number[] | string[]>
}

export type ExportData = {
  geniusApiRequest: ApiRequest
  name: string
  runInBg?: boolean
  // eslint-disable-next-line no-use-before-define
  childTemplate?: ChildExport[]
}

export interface ExportTemplate extends ExportData {
  displayName: string
  columns: string[]
  rows: string[]
  geniusRiskGroupDataRequest?: ApiRequest
  geniusMatrixApiRequest?: ApiRequest
  ignoreListColumns?: boolean
  dateFormat?: string
}

export interface ChildExport extends ExportTemplate {
  childProperty: string
  parentProperty: string
  parentName: string
  parentTableName: string
}

export interface MeetingExportTemplate extends ExportTemplate {
  maaSApiRequest: { url: string; method: 'POST'; body: IEmail }
  uploadApiRequest: ApiRequest
  ignoreListColumns: boolean
  sender: string
  frontendUrl: string
  projectLogo: string
  timeZone: number
}

export interface QrExportTemplate extends ExportTemplate {
  qrUrlTemplate: string
  projectLogo?: string
}

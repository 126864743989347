import { FC } from 'react'
import { ISystem } from '../../service/OrgTypes'
import List from '../../ui-elements/list/List'
import { filterType, IListColumns } from '../../ui-elements/list/ListTypes'
import CheckBox from '../switchHoc/CheckBox'

interface ISystemsListProps {
  systems: ISystem[]
  tableName: string
  inspectionModal?: boolean
  className?: string
}

const Systems: FC<ISystemsListProps> = ({
  systems,
  tableName,
  inspectionModal,
  className,
}) => {
  const inspectionColumns: IListColumns[] = [
    {
      name: 'system_number',
      id: 'record_id',
      size: '150',
      dataField: 'record_id',
      sortingField: 'record_id',
    },
    {
      name: 'system_name',
      id: 'name',
      size: '200',
      dataField: 'name',
      sortingField: 'name',
    },
    {
      name: 'location',
      id: 'location',
      size: '300',
      dataField: 'location',
      sortingField: 'location',
      filter: [],
      filterType: filterType.TEXT,
    },
  ]

  const columns: IListColumns[] = [
    {
      name: 'system_number',
      id: 'record_id',
      size: '150',
      dataField: 'record_id',
      sortingField: 'record_id',
    },
    {
      name: 'system_name',
      id: 'name',
      size: '200',
      dataField: 'name',
      sortingField: 'name',
    },
    {
      name: 'is_active',
      size: '120',
      id: 'isActive',
      dataField: 'is_active',
      sortingField: 'is_active',
      cell: (isActive: any) => (
        <div className={'pl-2 flex items-center'}>
          <CheckBox
            disable={true}
            disableTab={true}
            onChange={() => {}}
            valueProp={isActive}
          />
        </div>
      ),
    },
  ]

  return (
    <div className={className}>
      <List
        columns={inspectionModal ? inspectionColumns : columns}
        data={systems}
        tableName={tableName}
        itemsPerPage={50}
        pagination={true}
        totalPages={Math.ceil(systems.length / 50)}
      />
    </div>
  )
}

export default Systems

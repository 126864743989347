import Info from '@icons/info.svg'
import _ from 'lodash'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMetaValue } from 'src/document/types/IMetaData'
import FilteredSystemsList from 'src/fdvu/components/tabs/standard-requirement/standard-requirement-filter/FilteredSystemsList'
import SyntaxFilterObject from 'src/fdvu/components/tabs/standard-requirement/standard-requirement-filter/SyntaxFilterObject'
import { IFilterObject } from 'src/fdvu/types/IFilterObject'
import { useFilteredSystems } from 'src/query/fdvu/system'
import { useSystemSetting } from 'src/query/systems/systemSetting'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import {
  IDiscipline,
  IStatus,
  ISystem,
  ISystemFMI,
  ISystemMMI,
  ISystemType,
} from 'src/service/OrgTypes'
import {
  createSystem,
  editSystem,
  getProjectCategorySystemTypes,
  getProjectSystemFMIsOnly,
  getProjectSystemMMIsOnly,
  getSystem,
  getUserDefinedFieldsForSystems,
  getValidNewSystemData,
  massEditSystem,
  getSystemType,
} from 'src/service/SystemService'
import { getStatusesForType } from 'src/service/SystemStatusService'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { classNames } from 'src/utility/utils'
import DocumentMetaDataFields from '../../document/components/DocumentCreateModal/DocumentMetaDataFields'
import MultiSelector from '../multi-selector/MultiSelector'
import Selector from '../selectors/Selector'
import CheckBox from '../switchHoc/CheckBox'
import {
  getMetaDataValues,
  initializeMetaValues,
  setExistingValues,
} from './SystemUtil'

export interface ISystemFormProps {
  projectId: number
  closeModal: (refresh: boolean) => void
  show: boolean
  parentSystemId?: number
  parentRecordId?: string
  systemId?: number
  systemIds?: number[]
  systemSettingId: number
}
interface IInputErrorMessage {
  for: string
  errorMessage: string
}

interface IAvailableRecordIds {
  start: number
  end: number
}

interface IValidNewSystemsData {
  max_number_of_systems: number
  last_counter: number
  available_record_ids: IAvailableRecordIds[]
  sample_record_id: string
  remaining_systems: number
  is_given_system_type_valid: boolean
  is_given_parent_system_valid: boolean
  system_syntax_category_id: number
  system_syntax_category_ids: number[]
  valid_system_types: ISystemType[]
  errors: string
}

const styleClass = {
  inputGroup: classNames('w-full', 'flex', 'row', 'pt-1'),
  label: classNames(
    'flex',
    'items-center',
    'w-full',
    'font-medium',
    'text-sm',
    'text-gray-700',
    'my-2',
  ),
  wrapper: classNames('w-1/2'),
}

const SystemForm = ({
  projectId,
  closeModal,
  show,
  parentSystemId,
  systemId,
  systemIds,
  systemSettingId,
}: ISystemFormProps) => {
  const { t } = useTranslation()
  const editing = !!systemId
  const massEditing = !!systemIds
  const [optionalFields, setOptionalFields] = useState<IMetaValue[]>([])
  const [systemData, setSystemData] = useState<ISystem>({} as ISystem)
  const [errorMessages, setErrorMessages] = useState<IInputErrorMessage[]>([])
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [systemMmis, setSystemMmis] = useState<ISystemMMI[]>([])
  const [systemFmis, setSystemFmis] = useState<ISystemFMI[]>([])
  const [systemTypes, setSystemTypes] = useState<ISystemType[]>([])
  const [systemStatus, setSystemStatus] = useState<IStatus[]>([])
  const [saving, setSaving] = useState<boolean>(false)
  const [createMultiple, setCreateMultiple] = useState(false)
  const [recordId, setRecordId] = useState('')
  const [availableRecordIdGaps, setAvailableRecordIdGaps] = useState<
    IAvailableRecordIds[]
  >([])
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [systemMmiLoading, setSystemMmiLoading] = useState(false)
  const [systemStatusLoading, setSystemStatusLoading] = useState(false)
  const [maxSystemCounterCanBeUsed, setMaxSystemCounterCanBeUsed] = useState(0)
  const [maxSystemsCanBeCreated, setMaxSystemsCanBeCreated] = useState(0)
  const [lastCounter, setLastCounter] = useState(0)
  const [syntaxCategoryId, setSyntaxCategoryId] = useState<number | undefined>(
    undefined,
  )
  const [possibleCategoryIds, setPossibleCategoryIds] = useState<number[]>([])
  const { data: systemSyntax } = useSystemSetting(systemSettingId)
  const [searchFilters, setSearchFilters] = useState<Record<number, string[]>>(
    {},
  )
  const [appliedFilterLevels, setAppliedFilterLevels] = useState<number[]>([])
  const [syntaxError, setSyntaxError] = useState<string>()

  const createUnderParent = syntaxCategoryId
    ? possibleCategoryIds.includes(syntaxCategoryId)
    : true

  const filterObject: IFilterObject = {
    systemSettingId,
    appliesToLevels: appliedFilterLevels,
    syntaxSearchFilters: searchFilters,
  }

  const currentSyntax = systemSyntax?.find(
    (syntax) => syntax.id === syntaxCategoryId,
  )

  useEffect(() => {
    const searchAbleSyntaxes = systemSyntax?.filter(
      (syntax) => (syntax?.level ?? 0) < (currentSyntax?.level ?? 0),
    )
    const stillValidFilters = Object.entries(searchFilters)
      .filter(([id, _]) =>
        searchAbleSyntaxes?.some((syntax) => syntax.id === Number(id)),
      )
      .reduce((prev, [id, value]) => ({ ...prev, [id]: value }), {})
    setSearchFilters(stillValidFilters)
    setAppliedFilterLevels([
      systemSyntax?.find(
        (syntaxCategory) =>
          syntaxCategory?.level === (currentSyntax?.level ?? 0) - 1,
      )?.id ?? 0,
    ])
  }, [syntaxCategoryId])

  const { data: filteredSystems } = useFilteredSystems([filterObject])

  const fetchSystemData = () => {
    if (systemId) {
      getSystem(systemId).then((res) => {
        setRecordId(res.record_id)
        setSystemTypes(res.system_type ? [res.system_type] : [])
        const metaFields = initializeMetaValues(
          res?.system_type?.optional_fields ?? [],
          'System',
          systemId,
        )
        const metaData = getMetaDataValues(res.meta_data)
        setOptionalFields(setExistingValues(metaData ?? [], metaFields))
        setSelectedDisciplines(res.disciplines?.map((it) => it.id) ?? [])
        setSystemData(res)
        setSystemStatus(res.system_status ? [res.system_status] : [])
        setSystemMmis(res.system_mmi ? [res.system_mmi] : [])
      })
    }
  }

  const makeErrorMessage = (
    errorMessage: string,
    input: string,
  ): IInputErrorMessage => {
    return { for: input, errorMessage }
  }

  const setInitialSystemTypeError = (
    availableSystemCount: number,
    errorMessage?: string,
  ) => {
    const errors = []
    if (errorMessage) {
      errors.push(makeErrorMessage(t(errorMessage), 'system_type_id'))
    } else if (availableSystemCount === 0) {
      errors.push(
        makeErrorMessage(
          t('system_type_max_systems_created_for_configured_syntax'),
          'system_type_id',
        ),
      )
    }
    setSyntaxError(errorMessage)
    setErrorMessages(errors)
  }

  const getInitialCounterAndNumberOfSystem = (
    lastCounter: number = 0,
    maxSystems: number = 0,
    gaps: IAvailableRecordIds[] = [],
  ): { counter: number; numberOfSystems: number } => {
    if (lastCounter >= maxSystems && gaps?.length !== 0) {
      return {
        counter: gaps[0].start,
        numberOfSystems: gaps[0].end - gaps[0].start + 1,
      }
    }
    return {
      counter: lastCounter + 1,
      numberOfSystems: maxSystems - lastCounter,
    }
  }

  const getValidRecordId = (categoryId?: number) => {
    if (createUnderParent) {
      getValidNewSystemData(
        projectId,
        {
          system_syntax_category_id: categoryId,
          parent_system_id: parentSystemId,
        },
        systemSettingId,
      ).then((res: IValidNewSystemsData) => {
        setLastCounter(res.last_counter)
        setMaxSystemCounterCanBeUsed(res.max_number_of_systems ?? 0)
        setMaxSystemsCanBeCreated(res.remaining_systems ?? 0)
        setInitialSystemTypeError(res.remaining_systems, res.errors)
        setRecordId(res.sample_record_id ?? '')
        setSystemTypes(res.valid_system_types)
        const systemDataTemp = { ...systemData }
        systemDataTemp['starting_from'] = res?.last_counter + 1
        setSystemData(systemDataTemp)
        setSyntaxCategoryId(res.system_syntax_category_id)
        if (!categoryId) {
          setPossibleCategoryIds(res.system_syntax_category_ids)
        }
      })
    } else if (categoryId) {
      getProjectCategorySystemTypes(projectId, categoryId, 0, 0).then((res) => {
        setSystemTypes(res.system_types)
      })
      setRecordId('')
      setSyntaxCategoryId(categoryId)
    }
  }

  const getDisciplines = () => {
    getProjectDisciplines(projectId).then((res) => {
      setDisciplines(res)
    })
  }

  useEffect(() => {
    if (!systemIds) {
      if (systemId) {
        fetchSystemData()
      } else {
        getValidRecordId()
      }
    } else {
      getUserDefinedFieldsForSystems(projectId, systemIds).then((res) => {
        const metaFields = initializeMetaValues(res, 'System', undefined, true)
        setOptionalFields(metaFields)
      })
    }
    getDisciplines()
  }, [projectId, systemId, systemIds])

  const getSystemStatus = () => {
    setSystemStatusLoading(true)
    getStatusesForType(projectId, 'System').then((res) => {
      setSystemStatus(res)
      setSystemStatusLoading(false)
    })
  }

  const getSystemMmis = () => {
    setSystemMmiLoading(true)
    getProjectSystemMMIsOnly(projectId).then((res) => {
      setSystemMmis(res)
      setSystemMmiLoading(false)
    })
  }

  const getSystemFmis = () => {
    setSystemMmiLoading(true)
    getProjectSystemFMIsOnly(projectId).then((res) => {
      setSystemFmis(res)
      setSystemMmiLoading(false)
    })
  }

  const clearAnErrorMessage = (input: string) => {
    const copyErrMesg = [...errorMessages]
    const index = copyErrMesg.findIndex((er) => er.for === input)
    if (index > -1) {
      copyErrMesg.splice(index, 1)
    }
    setErrorMessages(copyErrMesg)
  }

  const onSystemTypeChange = async (systemTypeId: number) => {
    clearAnErrorMessage('system_type_id')
    const systemType = await getSystemType(systemTypeId)
    if (systemType.optional_fields) {
      const metaFields = initializeMetaValues(
        systemType.optional_fields,
        'System',
        systemId,
      )
      setOptionalFields(
        setExistingValues(systemData.optional_fields ?? [], metaFields),
      )
    } else {
      setOptionalFields([])
    }
    getValidNewSystemData(
      projectId,
      {
        parent_system_id: parentSystemId,
        system_type_id: systemTypeId,
        system_syntax_category_id: syntaxCategoryId,
      },
      systemSettingId,
    ).then((res: IValidNewSystemsData) => {
      const { counter, numberOfSystems } = getInitialCounterAndNumberOfSystem(
        res.last_counter,
        res.max_number_of_systems,
        res.available_record_ids,
      )
      setLastCounter(res.last_counter)
      const name = systemData?.name ?? systemType.name
      setSystemData({
        ...systemData,
        name,
        system_type_id: systemTypeId,
        starting_from: counter,
      })
      setRecordId(res.sample_record_id ?? '')
      setMaxSystemsCanBeCreated(numberOfSystems)
      setMaxSystemCounterCanBeUsed(res.max_number_of_systems ?? 0)
      setInitialSystemTypeError(res.remaining_systems, res.errors)
      setAvailableRecordIdGaps(res.available_record_ids)
    })
  }

  const returnCurrentGapEnd = (value: number): number | undefined => {
    let gapEnd
    availableRecordIdGaps?.some((gap) => {
      if (value >= gap.start && value <= gap.end) {
        gapEnd = gap.end
        return true
      }
      return false
    })
    return gapEnd
  }

  const onChangeInput = (
    field: string,
    value: string | number | boolean | string[] | number[],
  ) => {
    const systemDataTemp = { ...systemData }
    const errors = []
    systemDataTemp[field] = value
    if (field === 'starting_from' && typeof value === 'string') {
      if (value?.length === 0) {
        setMaxSystemsCanBeCreated(0)
      } else {
        value = parseInt(value)
        const gapEnd = returnCurrentGapEnd(value)

        if (gapEnd) {
          setMaxSystemsCanBeCreated(gapEnd - value + 1)
        } else if (value < lastCounter + 1) {
          errors.push(
            makeErrorMessage(t('counter_not_available'), 'starting_from'),
          )
          setMaxSystemsCanBeCreated(0)
        } else if (value > maxSystemCounterCanBeUsed) {
          errors.push(
            makeErrorMessage(
              t('should_be_less_than', { value: maxSystemCounterCanBeUsed }),
              'starting_from',
            ),
          )
        } else {
          setMaxSystemsCanBeCreated(maxSystemCounterCanBeUsed - value + 1)
        }
      }
    }
    clearAnErrorMessage(field)
    if (field === 'system_type_id' && typeof value === 'number') {
      clearAnErrorMessage('number_of_systems')
    }
    setSystemData(systemDataTemp)
    setErrorMessages(errors)
  }

  const getErrorMessage = (input: string): string => {
    const error = errorMessages.find((err) => err.for === input)
    return error ? error.errorMessage : ''
  }

  const getValueForString = (filed: string): string => {
    return systemData && systemData[filed] ? systemData[filed] : ''
  }

  const getValueForBoolean = (field: string): boolean => {
    return systemData && systemData[field] ? systemData[field] : false
  }

  const onSubmit = (e: FormEvent) => {
    let errorVal = false
    e.preventDefault()
    setSaving(true)
    setErrorMessages([])
    const errors = []

    if (!systemId) {
      if (
        createUnderParent &&
        (!(parseInt(getValueForString('number_of_systems'), 10) > 0) ||
          parseInt(getValueForString('number_of_systems'), 10) >
            maxSystemsCanBeCreated)
      ) {
        errors.push(
          makeErrorMessage(
            t('number_of_systems_value_range', { maxSystemsCanBeCreated }),
            'number_of_systems',
          ),
        )
        errorVal = true
      } else if (getErrorMessage('record_id')) {
        errors.push(makeErrorMessage(t('invalid_record_id'), 'record_id'))
        errorVal = true
      }

      if (maxSystemsCanBeCreated === 0 && availableRecordIdGaps.length === 0) {
        errors.push(
          makeErrorMessage(
            t('system_type_max_systems_created_for_configured_syntax'),
            'system_type_id',
          ),
        )
        errorVal = true
      }

      if (!systemData?.system_type_id || systemData?.system_type_id <= 0) {
        errors.push(makeErrorMessage(t('select_system_type'), 'system_type_id'))
        errorVal = true
      }

      if (!systemData?.starting_from) {
        errors.push(
          makeErrorMessage(
            t('fill_out_w_param', { param: t('starting_from') }),
            'starting_from',
          ),
        )
        errorVal = true
      } else if (
        createUnderParent &&
        systemData?.starting_from < lastCounter + 1 &&
        !returnCurrentGapEnd(systemData?.starting_from)
      ) {
        errors.push(
          makeErrorMessage(
            t('should_be_above', { value: lastCounter }),
            'starting_from',
          ),
        )
      } else if (systemData?.starting_from > maxSystemCounterCanBeUsed) {
        errors.push(
          makeErrorMessage(
            t('should_be_less_than', { value: maxSystemCounterCanBeUsed }),
            'starting_from',
          ),
        )
      }
    }

    if (!systemData?.name) {
      errors.push(
        makeErrorMessage(
          t('fill_out_w_param', { param: t('system_name') }),
          'name',
        ),
      )
      errorVal = true
    }

    if (!errorVal && systemData) {
      const systemDataWithOutRecordId = _.omit(systemData, 'record_id')
      const systemDataUpdate = {
        ...systemDataWithOutRecordId,
        parent_system_id: parentSystemId,
        optional_fields: optionalFields,
        system_syntax_category_id: syntaxCategoryId,
        discipline_ids: selectedDisciplines,
      }
      const api = editing
        ? editSystem(systemDataUpdate)
        : createSystem(
            {
              ...systemDataUpdate,
              is_active: true,
            },
            projectId,
            systemSettingId,
            createUnderParent
              ? undefined
              : Object.values(filteredSystems ?? {})?.flatMap((group) =>
                  group.systems.map((system) => system.id),
                ),
          )
      api
        .then((res: ISystem) => {
          if (res?.error_code) {
            setSaving(false)
          } else {
            if (createMultiple) {
              setSystemData({ ...systemData, name: '' })
              setSaving(false)
            } else {
              closeModal(true)
              setSaving(false)
            }
          }
        })
        .catch(
          (
            res: Promise<{
              error_code: string
              errors: { attribute: string; type: string }[]
            }>,
          ) => {
            setSaving(false)
            res.then((error) => {
              if (
                error.errors.some(
                  (error) =>
                    error.attribute === 'record_id' && error.type === 'taken',
                )
              ) {
                errorVal = true
                setErrorMessages([
                  makeErrorMessage(t('one_record_id_taken'), 'starting_from'),
                ])
              }
            })
          },
        )
    } else {
      setErrorMessages(errors)
      setSaving(false)
    }
  }

  const onMassEdit = (e: FormEvent) => {
    e.preventDefault()
    setSaving(true)
    if (systemIds) {
      const discipline_ids = selectedDisciplines.length
        ? selectedDisciplines
        : undefined
      const systemDataUpdate = {
        ...systemData,
        optional_fields: optionalFields,
        discipline_ids,
      }
      massEditSystem(projectId, systemIds, systemDataUpdate)
        .then(() => {
          setSaving(false)
          closeModal(true)
        })
        .catch(() => setSaving(false))
    }
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setOptionalFields(values)
  }

  const onCategorySelect = (levelId: number) => {
    setSyntaxCategoryId(levelId)
    getValidRecordId(levelId)
    setSystemData({} as ISystem)
  }

  const syntaxCategories = () => {
    return (
      <div className="ml-1 flex mb-2">
        {systemSyntax
          ?.filter((a) => a.syntax_string)
          ?.map((level, index) => {
            const disabled = false
            const selected = syntaxCategoryId === level.id
            return (
              <div key={level.id} className="flex flex-row items-center">
                {index !== 0 && (
                  <div className="text-d-fontgrey">{level.spacer}</div>
                )}
                <Button
                  type={selected ? ButtonType.PRIMARY : ButtonType.DEFAULT}
                  disabled={disabled}
                  onClick={(e) => {
                    e.preventDefault()
                    onCategorySelect(level.id)
                  }}
                >
                  {level.syntax_string}
                </Button>
              </div>
            )
          })}
      </div>
    )
  }

  const getAvailableRecordIdTooltipText = () => {
    const gapListItems = availableRecordIdGaps?.map((gap, index) => (
      <li key={index}>
        {gap.end <= gap.start
          ? gap.start
          : t('from_to', { start: gap.start, end: gap.end })}
      </li>
    ))

    const lastCounterCondition =
      lastCounter + 1 === maxSystemCounterCanBeUsed
        ? lastCounter + 1
        : lastCounter !== maxSystemCounterCanBeUsed && (
            <li>
              {t('from_to', {
                start: lastCounter + 1,
                end: maxSystemCounterCanBeUsed,
              })}
            </li>
          )

    const gapInfo =
      availableRecordIdGaps.length > 0 &&
      t('fill_one_gap', {
        gapStart: availableRecordIdGaps[0].start,
        maxNumber:
          availableRecordIdGaps[0].end - availableRecordIdGaps[0].start + 1,
      })

    const availableText =
      (gapListItems?.length > 0 || lastCounterCondition) && !syntaxError
        ? t('available_numbers')
        : t('no_available_numbers')

    return (
      <div className={'overflow-auto max-h-60'}>
        {availableText}
        {!syntaxError && (
          <ul>
            {gapListItems}
            {lastCounterCondition}
          </ul>
        )}
        <br />
        {gapInfo}
      </div>
    )
  }

  return (
    <Modal
      show={show}
      title={
        massEditing
          ? t('edit_multiple_system')
          : editing
            ? t('edit_system')
            : t('add_system')
      }
      closeModal={() => closeModal(false)}
      maxWidth={800}
    >
      <form
        className={'flex flex-col'}
        onSubmit={massEditing ? onMassEdit : onSubmit}
      >
        <div className={styleClass.inputGroup}>
          <Input
            label={t('system_name')}
            value={getValueForString('name')}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChangeInput('name', e.target.value)
            }
            block={true}
            required={!massEditing && !editing}
            errorMessage={getErrorMessage('name')}
          />
        </div>
        {!massEditing && (
          <>
            {!editing && (
              <div className="mt-2 flex flex-col">
                <label
                  className={
                    'ml-2 block font-medium text-sm leading-5 text-gray-700'
                  }
                >
                  {t('level')}
                </label>
                <div className={styleClass.inputGroup}>
                  {syntaxCategories()}
                </div>
              </div>
            )}
            {!createUnderParent && !editing && (
              <>
                <label
                  className={
                    'ml-2 block font-medium text-sm leading-5 text-gray-700'
                  }
                >
                  {t('choose_parents')}
                </label>
                <SyntaxFilterObject
                  filterObject={filterObject}
                  onChange={(syntaxFilterObject) => {
                    setSearchFilters({
                      ...syntaxFilterObject.syntaxSearchFilters,
                    })
                    setAppliedFilterLevels([
                      ...syntaxFilterObject.appliesToLevels,
                    ])
                  }}
                  currentLevel={
                    systemSyntax?.find(
                      (syntax) => syntax.id === syntaxCategoryId,
                    )?.level
                  }
                  disableSyntaxSelector
                />
                <FilteredSystemsList syntaxFilterObjects={[filterObject]} />
                <div className={styleClass.inputGroup}>
                  <div className={styleClass.wrapper}>
                    <Selector
                      items={systemTypes}
                      onSelect={onSystemTypeChange}
                      selectedItemId={getValueForString('system_type_id')}
                      label={t('system_type')}
                      dataFields={['type_code', 'name']}
                      fontSize={'sm'}
                      fontWeight={'bold'}
                      required={true}
                      disabled={editing}
                      errorMessage={getErrorMessage('system_type_id')}
                    />
                  </div>
                  <div className={'w-1/2'}>
                    <Input
                      label={t('number_of_systems', {
                        maxValue: 1000,
                      })}
                      value={getValueForString('number_of_systems')}
                      type={'number'}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChangeInput('number_of_systems', e.target.value)
                      }
                      block={true}
                      required={true}
                      minValue={1}
                    />
                  </div>
                </div>
                <div className={`${styleClass.wrapper} px-0`}>
                  <Input
                    label={t('starting_from')}
                    value={getValueForString('starting_from')}
                    type={'number'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangeInput('starting_from', e.target.value)
                    }
                    block={true}
                    minValue={1}
                    required={false}
                    errorMessage={getErrorMessage('starting_from')}
                  />
                </div>
              </>
            )}

            {createUnderParent && (
              <div className={styleClass.inputGroup}>
                <div className={styleClass.wrapper}>
                  <Selector
                    items={systemTypes}
                    onSelect={onSystemTypeChange}
                    selectedItemId={getValueForString('system_type_id')}
                    label={t('system_type')}
                    dataFields={['type_code', 'name']}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    required={true}
                    disabled={editing}
                    errorMessage={getErrorMessage('system_type_id')}
                  />
                </div>
                <div className={styleClass.wrapper}>
                  <Input
                    label={t('system_number')}
                    value={recordId}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setRecordId(e.target.value)
                    }
                    block={true}
                    required={false}
                    disabled={true}
                    errorMessage={''}
                  />
                </div>
              </div>
            )}
          </>
        )}
        {createUnderParent && (
          <div className={styleClass.inputGroup}>
            {!systemId && !massEditing && (
              <>
                <div className={`${styleClass.wrapper} px-0`}>
                  <Input
                    label={t('starting_from')}
                    value={getValueForString('starting_from')}
                    type={'number'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangeInput('starting_from', e.target.value)
                    }
                    block={true}
                    minValue={1}
                    maxValue={maxSystemCounterCanBeUsed}
                    disabled={
                      maxSystemCounterCanBeUsed === 0 &&
                      availableRecordIdGaps.length === 0
                    }
                    required={false}
                    errorMessage={getErrorMessage('starting_from')}
                  />
                </div>
                <Tooltip
                  message={getAvailableRecordIdTooltipText()}
                  classNames="mr-8"
                  minWidth={'[200px]'}
                  offset={1}
                >
                  <Info
                    className={
                      'text-xl fill-blue-root mt-11 relative float-end '
                    }
                  />
                </Tooltip>
                <div className={'w-7/12'}>
                  <Input
                    label={t('number_of_systems', {
                      maxValue: maxSystemsCanBeCreated ?? 0,
                    })}
                    value={getValueForString('number_of_systems')}
                    type={'number'}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChangeInput('number_of_systems', e.target.value)
                    }
                    block={true}
                    required={true}
                    minValue={1}
                    maxValue={maxSystemsCanBeCreated}
                    disabled={maxSystemsCanBeCreated === 0}
                    errorMessage={getErrorMessage('number_of_systems')}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <div className={`${styleClass.inputGroup} items-center`}>
          <Selector
            items={systemStatus}
            onOpenSelectFunction={getSystemStatus}
            loading={systemStatusLoading}
            selectedItemId={getValueForString('system_status_id')}
            onSelect={(item) => onChangeInput('system_status_id', item)}
            label={t('object_status')}
            dataFields={['name']}
            fontSize={'sm'}
            fontWeight={'bold'}
            required={false}
            errorMessage={getErrorMessage('system_status_id')}
          />
        </div>
        <div className={styleClass.inputGroup}>
          <div className={styleClass.wrapper}>
            <div className={'mx-2'}>
              <MultiSelector
                items={disciplines}
                selectedItems={selectedDisciplines}
                onSelect={(ids: number[]) => setSelectedDisciplines(ids)}
                label={t('disciplines')}
                dataFields={['shortName', 'name']}
                fontSize={'sm'}
                fontWeight={'bold'}
              />
            </div>
          </div>
          <div className={'w-1/2'}>
            <Input
              label={t('location')}
              value={getValueForString('location')}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onChangeInput('location', e.target.value)
              }
              block={true}
              required={false}
              errorMessage={getErrorMessage('location')}
            />
          </div>
        </div>
        <div className={styleClass.inputGroup}>
          <div className={styleClass.wrapper}>
            <Selector
              items={systemMmis}
              onOpenSelectFunction={getSystemMmis}
              loading={systemMmiLoading}
              selectedItemId={getValueForString('system_mmi_id')}
              onSelect={(item) => onChangeInput('system_mmi_id', item)}
              label={t('system_mmi')}
              dataFields={['name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              required={false}
              errorMessage={getErrorMessage('system_mmi_id')}
            />
          </div>
          <div className={styleClass.wrapper}>
            <Selector
              items={systemFmis}
              onOpenSelectFunction={getSystemFmis}
              loading={systemMmiLoading}
              selectedItemId={getValueForString('system_fmi')}
              onSelect={(item) => onChangeInput('system_fmi_id', item)}
              label={t('system_fmi')}
              dataFields={['name']}
              fontSize={'sm'}
              fontWeight={'bold'}
              required={false}
              errorMessage={getErrorMessage('system_fmi_id')}
            />
          </div>
        </div>

        {(editing || massEditing) && (
          <div className={styleClass.inputGroup}>
            <div className={'w-1/2 pl-2 flex items-center gap-2'}>
              <CheckBox
                disableTab={true}
                onChange={(item) => onChangeInput('is_active', item)}
                valueProp={getValueForBoolean('is_active')}
              />
              <label className={styleClass.label}>{t('is_active')}</label>
            </div>
          </div>
        )}
        <div className={styleClass.inputGroup}>
          <div className={'w-1/2 pl-2 flex items-center gap-2'}>
            <CheckBox
              disableTab={true}
              onChange={(item) => onChangeInput('is_testable', item)}
              valueProp={getValueForBoolean('is_testable')}
            />
            <label className={styleClass.label}>{t('is_testable')}</label>
          </div>
          <div className={'w-1/2 pl-2 flex items-center gap-2'}>
            <CheckBox
              disableTab={true}
              onChange={(item) => onChangeInput('is_in_bim', item)}
              valueProp={getValueForBoolean('is_in_bim')}
            />
            <label className={styleClass.label}>{t('is_in_bim')}</label>
          </div>
        </div>

        <div className={styleClass.inputGroup}>
          <Textarea
            isValid={false}
            label={t('description')}
            value={getValueForString('description')}
            onChange={(e) => onChangeInput('description', e.target.value)}
            block={true}
            errorMessage={getErrorMessage('description')}
          />
        </div>
        {optionalFields.length > 0 && (
          <div className="pb-8">
            <DocumentMetaDataFields
              title={''}
              onFieldsUpdate={onOptionalFieldsUpdate}
              fields={optionalFields}
              required={false}
            />
          </div>
        )}
        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={() => closeModal(false)}
          >
            <p className={'first-capitalize'}>{t('cancel')}</p>
          </Button>
          {editing || massEditing ? (
            <Button type={Button.ButtonType.PRIMARY} disabled={saving}>
              {saving ? <Spinner /> : t('edit')}
            </Button>
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={onMultipleCreate}
                disabled={saving}
              >
                {saving ? <Spinner /> : t('add_multiple')}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                onClick={onSingleCreate}
                disabled={saving}
              >
                <p>{saving ? <Spinner /> : t('add')}</p>
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SystemForm

import { useTranslation } from 'react-i18next'
import { MarkdownSpan, MarkdownBold } from 'src/components/markdown/Markdown'
import { ActivityType, IActivityLog } from 'src/service/OrgTypes'
import { getItemNameFromActivityLog } from 'src/utility/activityLogUtils'
import { classNames } from 'src/utility/utils'

interface ActivityLogItemTitleProps {
  activityLog: IActivityLog
  className?: string
}

const ActivityLogItemTitle = ({
  activityLog,
  className = '',
}: ActivityLogItemTitleProps) => {
  const { t } = useTranslation()

  const itemName = getItemNameFromActivityLog(activityLog, t)

  const blueUnderlineClassName = 'text-blue-500 underline cursor-pointer'

  const TitleText = () => {
    if (activityLog.activity_type === ActivityType.UPDATE) {
      return (
        <MarkdownSpan
          value={t('activity_log_updated_title', {
            itemName,
          })}
          classNames={[blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.CREATE) {
      return (
        <MarkdownSpan
          value={t('activity_log_created', {
            itemName,
          })}
          classNames={[blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.DELETE) {
      return (
        <MarkdownSpan
          value={t('activity_log_deleted', {
            itemName,
          })}
          classNames={[blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.IMPORT) {
      return (
        <MarkdownBold
          value={t('activity_log_import', {
            model: activityLog.model || '',
          })}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.CREATE_GROUP) {
      return (
        <MarkdownBold
          value={t('activity_log_created_group', {
            model: activityLog.model || '',
          })}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.UPDATE_GROUP) {
      return (
        <MarkdownBold
          value={t('activity_log_updated_group', {
            model: activityLog.model || '',
          })}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.DOCUMENT_UPLOAD) {
      return (
        <MarkdownSpan
          value={t('activity_log_upload', {
            fileName: activityLog.filedName,
            itemName,
          })}
          classNames={[
            activityLog.document_id || activityLog.parent_type === 'Project'
              ? blueUnderlineClassName
              : 'text-gray-500 line-through',
          ]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.DOCUMENT_DELETE) {
      return (
        <MarkdownSpan
          value={t('activity_log_doc_delete', {
            fileName: activityLog.filedName,
            itemName,
          })}
          classNames={['font-bold', blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.RESPONSIBLE_ASSIGNMENT) {
      return (
        <MarkdownSpan
          value={t('activity_log_assigned_you_to', {
            itemName,
          })}
          classNames={[blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.COMMENT) {
      return (
        <MarkdownSpan
          value={t('activity_log_commented', {
            itemName,
          })}
          classNames={[blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.COMMENT_MENTION) {
      return (
        <MarkdownSpan
          value={t('activity_log_mentioned_you_in', {
            itemName,
          })}
          classNames={[blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.CREATED_FMI_SUGGESTION) {
      return (
        <MarkdownSpan
          value={t('activity_log_created_fmi_suggestion', {
            value: activityLog.newValue,
            itemName,
          })}
          classNames={['font-bold', blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.APPROVED_FMI_SUGGESTION) {
      return (
        <MarkdownSpan
          value={t('activity_log_approved_fmi_suggestion', {
            value: activityLog.newValue,
            itemName,
          })}
          classNames={['font-bold', blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.DECLINED_FMI_SUGGESTION) {
      return (
        <MarkdownSpan
          value={t('activity_log_declined_fmi_suggestion', {
            value: activityLog.newValue,
            itemName,
          })}
          classNames={['font-bold', blueUnderlineClassName]}
        />
      )
    }

    if (activityLog.activity_type === ActivityType.OMEGA_SYNC) {
      return (
        <MarkdownSpan
          value={t('synced_with_omega')}
          classNames={['font-bold', blueUnderlineClassName]}
        />
      )
    }

    return <span className={blueUnderlineClassName}>{itemName}</span>
  }

  if (activityLog.activity_type === ActivityType.SYSTEM_NOTIFICATION) {
    return null
  }

  return (
    <div
      className={classNames(
        'text-sm truncate text-gray-600 mx-2 font-normal',
        className,
      )}
    >
      <TitleText />
    </div>
  )
}

export default ActivityLogItemTitle

import Functions from '@material-symbols/svg-500/outlined/functions.svg'
import Add from '@material-symbols/svg-500/rounded/add.svg'
import CalendarToday from '@material-symbols/svg-500/rounded/calendar_today-fill.svg'
import Close from '@material-symbols/svg-500/rounded/close-fill.svg'
import HourglassBottom from '@material-symbols/svg-500/rounded/hourglass_bottom-fill.svg'
import Person from '@material-symbols/svg-500/rounded/person-fill.svg'
import moment from 'moment'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { IConstructionWagon, IWagonType } from 'src/service/OrgTypes'
import Icon from 'src/ui-elements/icon/Icon'
import { toFixed } from '../../utility/Utility'

interface ICustomLaneHeader {
  locomotives: IConstructionWagon[] | IWagonType[]
  id?: string
  onWagonDelete?: (wagonType: IWagonType | IConstructionWagon) => void
  openWagonModal?: (wagonType: IWagonType | IConstructionWagon) => void
  onAddActivities?: (id: number) => void
  canAddActivities?: boolean
  onAddWagons?: (executionOrder?: number) => void
  editable?: boolean
  editTitle?: (title: string, id: number) => void
  showCreateWagonForm?: () => void
}

const CustomLaneHeader = ({
  locomotives,
  id,
  onWagonDelete,
  openWagonModal,
  onAddActivities,
  onAddWagons,
  canAddActivities,
  editable = true,
  editTitle,
  showCreateWagonForm,
}: ICustomLaneHeader) => {
  const isAddLane = id?.includes('add_lane')
  const isCreateWagon = id?.includes('create_wagon')
  const wagon = locomotives.find(
    (loc) => loc.id === Number(isAddLane ? id?.replace('_add_lane', '') : id),
  )
  const history = useHistory()
  const { url } = useRouteMatch()
  const { t } = useTranslation()
  const [showDelete, setShowDelete] = useState(false)

  const isNoWagons = !locomotives.length
  if (isCreateWagon)
    return (
      <div
        style={{ height: 400 }}
        className="w-full cursor-pointer flex flex-col items-center justify-center border-blue-root bg-light-blue hover:border-2 border-dashed rounded-sm text-center"
        onClick={showCreateWagonForm}
      >
        <Add className="text-xxl fill-blue-root" />
        <span className="text-sm text-blue-root first-capitalize">
          {t('create_wagon')}
        </span>
      </div>
    )
  if (isAddLane)
    return (
      <div
        style={{ height: 400 }}
        className="w-full cursor-pointer flex flex-col items-center justify-center bg-white border-2 rounded-sm border-blue-root border-dashed hover:bg-blue-100"
        onClick={() => onAddWagons?.((wagon?.execution_order ?? 0) + 1)}
      >
        <Add className="text-xxl fill-blue-root" />
        {isNoWagons && (
          <span className="text-sm text-blue-root first-capitalize">
            {t('add_wagon')}
          </span>
        )}
      </div>
    )

  return (
    <div
      className="cursor-pointer px-1"
      onDoubleClick={() =>
        history.push(
          `${url}${url.endsWith('/') ? '' : '/'}locomotive_types/${wagon?.id}`,
        )
      }
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
    >
      <div className="flex justify-between">
        {editTitle ? (
          <input
            className="bg-transparent w-full"
            defaultValue={wagon?.title}
            onBlur={(e) => {
              if (e.target.value !== wagon?.title)
                editTitle(e.target.value, wagon?.id ?? 0)
            }}
            onDoubleClick={(e) => e.stopPropagation()}
          />
        ) : (
          <p className="whitespace-normal font-semibold w-full break-words">
            {wagon?.title}
          </p>
        )}
        <div className="flex justify-between">
          {openWagonModal && editable && (
            <span className="text-xs flex">
              <Icon
                icon={Icon.IconType.EDIT}
                onClick={() => wagon && openWagonModal(wagon)}
                style={{ width: 15, height: 15 }}
              />
            </span>
          )}
          {onWagonDelete && showDelete && editable && (
            <span className="text-xs flex cursor-pointer">
              <Close
                className="fill-red"
                onClick={() => wagon && onWagonDelete(wagon)}
                style={{ width: 20, height: 20 }}
              />
            </span>
          )}
        </div>
      </div>

      <div className="text-xs flex pt-1 justify-between">
        <span className="flex flex-row items-center">
          <Person className="mr-1 text-lg" />
          {toFixed(wagon?.planned_staffing ?? 0)}
        </span>
        <span className="flex flex-row items-center">
          <HourglassBottom className="mr-1 text-lg" />
          {toFixed(wagon?.duration ?? 0)}
        </span>
        <span className="flex flex-row items-center">
          <Functions className="mr-1 text-lg" />
          {toFixed(wagon?.planned_execution_hours ?? 0)}
        </span>
        {wagon && 'startTime' in wagon && (
          <span className="flex flex-row items-center">
            <CalendarToday className="mr-1 text-lg" />
            <span className="ml-1">{moment(wagon?.startTime).format('w')}</span>
          </span>
        )}
      </div>
      {canAddActivities && (
        <span
          onClick={() => onAddActivities?.(wagon?.id ?? 0)}
          className="cursor-pointer border-2 border-dashed border-blue-root flex justify-center items-center bg-white p-1 px-2 mt-1 rounded-sm fill-blue-root hover:bg-blue-200"
        >
          <Add />
        </span>
      )}
    </div>
  )
}

export default CustomLaneHeader

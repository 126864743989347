import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'
import { editConstructionTask } from 'src/service/ConstructionTaskService'
import { IConstructionTask } from 'src/service/OrgTypes'
import { actionStatus } from 'src/service/SystemValues'
import {
  defaultDateColumnObject,
  defaultNumberColumn,
  defaultTextColumn,
  statusColumn,
} from 'src/ui-elements/Table/Columns'
import SimpleTable from 'src/ui-elements/Table/SimpleTable'

const SimpleActivitiesTable = ({
  constructionTasks,
  setConstructionTasks,
}: {
  constructionTasks: IConstructionTask[]
  setConstructionTasks: (tasks: IConstructionTask[]) => void
}) => {
  const activityTableColumnHelper = createColumnHelper<IConstructionTask>()

  const { t } = useTranslation()

  const activityTableColumns = [
    activityTableColumnHelper.accessor(
      'title',
      defaultTextColumn('title', { name: capitalize(t('title')) }),
    ),
    activityTableColumnHelper.accessor(
      'deadline',
      defaultDateColumnObject(
        'deadline',
        { name: capitalize(t('deadline')) },
        () => false,
      ),
    ),
    statusColumn(
      'status',
      ['status'],
      { name: capitalize(t('status')) },
      actionStatus(t),
      (statusChange, key) => {
        const dataField = constructionTasks.find((d) => d.id === key)
        if (dataField) {
          dataField.status = statusChange.id
          editConstructionTask(dataField).then(() => {
            const index = constructionTasks.findIndex(
              (task) => task.id === dataField.id,
            )
            const newTasks = [...constructionTasks]
            newTasks[index] = dataField
            setConstructionTasks(newTasks)
          })
        }
      },
    ),
    activityTableColumnHelper.accessor(
      'total_work_hours',
      defaultNumberColumn('total_work_hours', {
        name: capitalize(t('total_work_hours')),
      }),
    ),
    activityTableColumnHelper.accessor(
      'number_of_workers',
      defaultNumberColumn('number_of_workers', {
        name: capitalize(t('number_of_workers')),
      }),
    ),
  ] as ColumnDef<IConstructionTask>[]

  return (
    <SimpleTable
      name="ActivitiesInDiscipline"
      data={constructionTasks}
      columns={activityTableColumns}
      selectable={false}
      enablePagination={false}
    />
  )
}

export default SimpleActivitiesTable

import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getProjectSystemConnectionTypes,
  getSystemConnectionGraph,
  getSystemsConnectedToSystem,
} from 'src/service/SystemService'

export const useProjectSystemConnectionTypes = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [projectId],
    queryFn: async () => getProjectSystemConnectionTypes(projectId),
  })
}

export const useSystemConnectionGraph = (systemId: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [projectId, systemId],
    queryFn: async () => getSystemConnectionGraph(projectId, systemId),
  })
}

export const useSystemsConnectedToSystemPaginated = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getSystemsConnectedToSystem>[3]['sort']
  filters: Omit<Parameters<typeof getSystemsConnectedToSystem>[3], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'systemsConnectedToSystems',
      { projectId: projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getSystemsConnectedToSystem(projectId, page, pageSize, {
        ...filters,
        sort,
      }),
    placeholderData: keepPreviousData,
    enabled,
  })
}

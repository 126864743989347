import { capitalize } from 'lodash'
import moment from 'moment'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {
  BarColors,
  barSize,
  cartesianDashArray,
  cartesianGridStroke,
  graphMargin,
  legendStyle,
  maxBarWidth,
  NotifyNoDisplay,
  toolTipStyle,
} from 'src/components/KPI/KPIUtils'
import {
  InformationComponent,
  InformationTitleComponent,
} from 'src/components/information-component'
import FloatingFilterTableKeeper from 'src/components/shared/FloatingFilterTableKeeper'
import { usePlannedTestWorkGroupsSummary } from 'src/query/systems/testWorkGroups'
import { IBarData, FloatingFilterTableKeeperTypes } from 'src/service/OrgTypes'
import { IPlannedTestWorkGroupsSummary } from 'src/service/TestWorkGroupService'
import Loader from 'src/ui-elements/loader/Loader'

export type IPlannedTWGSummary =
  IPlannedTestWorkGroupsSummary[0][0]['status_count'] & {
    monthAndYear: string
    year: number
    month: number
  }

interface ITestWorkGroupGraphProps {
  projectId: number
  updateFilter: (
    dateFilter: Pick<IPlannedTWGSummary, 'year' | 'month'>,
    selectedDisciplines: number[],
  ) => void
}

const TestWorkGroupGraph: React.FC<ITestWorkGroupGraphProps> = ({
  projectId,
  updateFilter,
}: ITestWorkGroupGraphProps) => {
  const { t } = useTranslation()
  const [selectedDisciplines, setSelectedDisciplines] = useState<number[]>([])
  const [selectedContracts, setSelectedContracts] = useState<number[]>([])
  const [filterFetched, setFilterFetched] = useState<boolean>(false)

  const getOneYearFromTodayAndOneYearBack = () => {
    return {
      earliest: moment().subtract(1, 'year').startOf('month'),
      latest: moment().add(1, 'year').endOf('month'),
    }
  }

  const [endDate, setEndDate] = useState<moment.Moment>(
    getOneYearFromTodayAndOneYearBack().latest,
  )
  const [startDate, setStartDate] = useState<moment.Moment>(
    getOneYearFromTodayAndOneYearBack().earliest,
  )

  const { data: plannedTWGSummary = [], isPending } =
    usePlannedTestWorkGroupsSummary(
      {
        planned_end: [startDate.startOf('month'), endDate.endOf('month')],
        discipline: selectedDisciplines,
      },
      filterFetched,
    )

  const barDataKeys: IBarData[] = [
    {
      dataKey: 'done',
      name: capitalize(t('done')),
      stackId: 'a',
      fill: BarColors.DONE,
    },
    {
      dataKey: 'done_with_issues',
      name: capitalize(t('done_with_issues')),
      stackId: 'a',
      fill: BarColors.DONE_WITH_ISSUES,
    },
    {
      dataKey: 'open',
      name: capitalize(t('open')),
      stackId: 'a',
      fill: BarColors.OPEN,
    },
    {
      dataKey: 'in_progress',
      name: capitalize(t('in_progress')),
      stackId: 'a',
      fill: BarColors.IN_PROGRESS,
    },
    {
      dataKey: 'not_relevant',
      name: capitalize(t('not_relevant')),
      stackId: 'a',
      fill: BarColors.NOT_RELEVANT,
    },
    {
      dataKey: 'not_set',
      name: capitalize(t('not_set')),
      stackId: 'a',
      fill: BarColors.NOT_SET,
    },
    {
      dataKey: 'error',
      name: capitalize(t('error')),
      stackId: 'a',
      fill: BarColors.ERROR,
    },
  ]

  const updateListView = ({ month, year }: IPlannedTWGSummary) =>
    updateFilter({ month, year }, selectedDisciplines)

  const onFilterClear = () => {
    const dates = getOneYearFromTodayAndOneYearBack()
    setSelectedDisciplines([])
    setStartDate(dates.earliest)
    setEndDate(dates.latest)
  }

  return (
    <InformationComponent fullWidth={false} noMargin>
      <InformationTitleComponent
        title={t('monthly_summary_based_on_planned_date')}
      />
      <FloatingFilterTableKeeper
        filter={{
          startDate,
          endDate,
          selectedView: 1,
          selectedDisciplines,
          setSelectedDisciplines,
          setEndDate,
          setStartDate,
          selectedContracts,
          setSelectedContracts,
        }}
        onFiltersChange={() => setFilterFetched(true)}
        projectId={projectId}
        hideProjectSelector={true}
        onFilterClear={onFilterClear}
        filterName={FloatingFilterTableKeeperTypes.TEST_WORK_GROUP_GRAPH}
      />

      <ResponsiveContainer width="100%" minHeight={280} height={280}>
        {plannedTWGSummary.length > 0 ? (
          <ComposedChart data={plannedTWGSummary} margin={graphMargin}>
            <XAxis dataKey="monthAndYear" tickLine={false} />
            <YAxis width={30} tickLine={false} allowDecimals={false} />
            <Tooltip formatter={toolTipStyle.noDescimalTrailingZeroes} />
            <Legend wrapperStyle={legendStyle.wrapper} />
            <CartesianGrid
              stroke={cartesianGridStroke}
              strokeDasharray={cartesianDashArray}
            />
            {barDataKeys.map((data, i) => (
              <Bar
                key={`barData-${i}`}
                maxBarSize={maxBarWidth}
                barSize={barSize}
                stackId={data.stackId}
                name={data.name}
                dataKey={data.dataKey}
                fill={data.fill}
                onClick={updateListView}
              />
            ))}
          </ComposedChart>
        ) : isPending ? (
          <Loader centerLoader={false} />
        ) : (
          <NotifyNoDisplay />
        )}
      </ResponsiveContainer>
    </InformationComponent>
  )
}

export default TestWorkGroupGraph

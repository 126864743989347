import { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import { styleClass as columnStyleClass } from 'src/ui-elements/Table/Columns'
import { IStepData } from '../../types/IFlow'

interface IDocListStageProps {
  step: IStepData
}

const Step: FC<IDocListStageProps> = ({ step }: IDocListStageProps) => {
  return (
    <div className={twMerge('flex items-center', columnStyleClass.cell)}>
      <span
        className={twMerge(
          'rounded-full w-5 text-center text-xs text-white font-medium',
          step?.review ? 'bg-d-grey-light' : 'bg-blue-root',
        )}
      >
        {step?.sequence}
      </span>
      <span
        className={twMerge(
          'ml-1 font-medium text-xs text-gray-700 first-capitalize',
          step?.review ? 'text-d-grey-light' : '',
        )}
      >
        {step?.name}
      </span>
    </div>
  )
}

export default Step

import DropDown from '@icons/keyboard_arrow_down.svg'
import Preview from '@icons/preview.svg'
import Close from '@material-symbols/svg-500/rounded/close.svg'
import { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import SystemSelectModal from 'src/components/system/system-table/SystemSelectModal'
import { ModalContext } from 'src/context/ModalContextProvider/ModalContext'
import { ISystem } from 'src/service/OrgTypes'
import InlineCompontent from 'src/ui-elements/page-display/inline-components/InlineComponent'

interface SystemSelectorWithListProps {
  currentSystemId?: number
  projectId: number
  selectedSystemId?: number | null | undefined
  onSystemSelect: (id: number) => void
  label: string
  systemSettingId?: number
  initialSystem?: ISystem
  className?: string
  labelWidth?: string
  disabled?: boolean
  onRemove?: (id: number) => void
  gotoButton?: boolean
}

const SystemSelectorWithList = ({
  currentSystemId,
  projectId,
  selectedSystemId,
  onSystemSelect,
  label,
  systemSettingId,
  initialSystem,
  className,
  labelWidth,
  disabled,
  onRemove,
  gotoButton,
}: SystemSelectorWithListProps) => {
  const [showReplacedByModal, setShowReplacedByModal] = useState(false)
  const [system, setSystem] = useState<ISystem | undefined>(initialSystem)
  const modalContext = useContext(ModalContext)
  const history = useHistory()
  useEffect(() => {
    setSystem(initialSystem)
  }, [initialSystem])

  return (
    <>
      <InlineCompontent
        label={label}
        labelWidth={labelWidth ?? 'w-48'}
        content={
          <div
            className={`flex flex-row mx-2 group items-center max-h-[20px] truncate`}
          >
            <p
              className={`items-center truncate text-sm leading-5 ${
                selectedSystemId ? 'text-gray-700' : 'text-gray-400'
              } ${className}`}
              onClick={() => !disabled && setShowReplacedByModal(true)}
            >
              {system?.record_id
                ? `${system?.record_id ?? ''} ${system?.name ?? ''}`
                : `____`}
            </p>
            <DropDown
              className={twMerge(
                'invisible',
                !disabled && 'group-hover:visible w-5 h-5 fill-gray-400 ml-0.5',
              )}
            />
            <div className="flex">
              {onRemove && selectedSystemId && (
                <Close
                  className="fill-red cursor-pointer w-5 h-5 hover:bg-red-100"
                  onClick={(e) => {
                    e.stopPropagation()
                    onRemove(selectedSystemId)
                  }}
                />
              )}
              {gotoButton && selectedSystemId && system && (
                <Link
                  to={`?modal=system&project=${projectId}&id=${system.id}`}
                  onClick={(e) => {
                    const link = `?modal=system&project=${projectId}&id=${system.id}`
                    e.stopPropagation()
                    history.push(link)
                    modalContext.actions.checkURL(link)
                  }}
                >
                  <Preview className="fill-blue-root cursor-pointer w-5 h-5 hover:bg-blue-100" />
                </Link>
              )}
            </div>
          </div>
        }
        disabled={disabled}
      />
      {showReplacedByModal && (
        <SystemSelectModal
          tableName={'selectReplacedBySystem'}
          key={projectId}
          isSingleSelect
          filter={{ system_setting: systemSettingId ? [systemSettingId] : [] }}
          onRowClick={(item) => {
            if (item.id === currentSystemId) return
            onSystemSelect(item.id)
            setSystem(item)
            setShowReplacedByModal(false)
          }}
          closeModal={() => setShowReplacedByModal(false)}
        />
      )}
    </>
  )
}

export default SystemSelectorWithList

import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { massUpdateTestSystemGroups } from 'src/service/SystemService'
import CheckboxWithLabel from 'src/ui-elements/checkbox/CheckboxWithLabel'
import TestSystemGroupList from '../../components/system/test-system-groups/TestSystemGroupList'
import Button from '../../ui-elements/button/Button'
import Modal from '../../ui-elements/modal/Modal'
import ModalFooter from '../../ui-elements/modal/ModalFooter'

interface ISelectTestSystemGroupList {
  systemIds: number[]
  testSystemGroupIds?: number[]
  reload: () => void
}

const SelectTestSystemGroupList: FC<ISelectTestSystemGroupList> = ({
  systemIds,
  testSystemGroupIds = [],
  reload,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const [overwrite, setOverwrite] = useState(false)
  const [selectedTestSystemGroupIds, setSelectedTestSystemGroupIds] =
    useState(testSystemGroupIds)

  const onSubmit = async () => {
    await massUpdateTestSystemGroups(
      systemIds,
      selectedTestSystemGroupIds,
      overwrite,
    )
    reload()
  }

  return (
    <Modal
      show={true}
      title={t('test_system_groups')}
      closeModal={reload}
      size={'w-4/5'}
      maxWidth={1200}
    >
      <div className={'w-full flex px-2 pb-4 flex-col min-h-[48vh]'}>
        <TestSystemGroupList
          projectId={projectId}
          updateSelectedTestSystemGroups={setSelectedTestSystemGroupIds}
          workableList={false}
        />
      </div>
      <div className={'flex items-center gap-2 text-sm text-gray-700 mt-2'}>
        <CheckboxWithLabel
          labelRight
          name={t('overwrite_existing_connections')}
          onChange={() => setOverwrite(!overwrite)}
          value={overwrite}
        />
      </div>
      <ModalFooter>
        <Button onClick={reload}>{t('cancel')}</Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
          {t('save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SelectTestSystemGroupList

import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowUp from '@icons/keyboard_arrow_up.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { IFilterObject } from 'src/fdvu/types/IFilterObject'
import { useFilteredSystems } from 'src/query/fdvu/system'
import { ISystem } from 'src/service/OrgTypes'
import Loader from '../../../../../ui-elements/loader/Loader'

interface FilteredSystemsListProps {
  syntaxFilterObjects: IFilterObject[]
  setValue?: (key: string, value: number[]) => void
  width?: string
}

const formatDisplayString = (pattern: string): string => {
  return (
    pattern
      // Replace character groups like [a-zA-Z0-9æøåÆØÅ<>]{1,6} or [a-zA-Z0-9æøåÆØÅ<>]{1} with *
      .replace(/\[a-zA-Z0-9æøåÆØÅ<>]+\{\d(?:,\d)?}/g, '*')

      // Replace numeric groups like [0-9]{1,3} with *
      .replace(/\[0-9]+\{\d(?:,\d)?}/g, '*')

      // Replace specific markers or spacers like [- . =] with an empty string
      .replace(/\[-\ \.=\]+\{\d\}/g, '')

      // Replace non-capturing groups (?:...) with *
      .replace(/\(\?:.*?\)\?/g, '*')

      // Replace wildcard patterns (.*) with *
      .replace(/\.\*/g, '*')

      // Replace separator symbols with nothing
      .replace(/-\+\.=\{1}/g, '')

      // Replace duplicate * (**) with *
      .replace(/\*+/g, '*')

      // Replace brackets [] with nothing
      .replace(/\[/g, '')
      .replace(/]/g, '')

      // Replace separator symbols with nothing
      .replace(/-\+\.=\{1}/g, '')

      // Remove starting caret ^ and ending dollar $ if present
      .replace(/^\^/, '')
      .replace(/\$$/, '')
  )
}

const FilteredSystemsList = ({
  syntaxFilterObjects,
  width,
}: FilteredSystemsListProps) => {
  const { t } = useTranslation()

  const { data: filteredSystems = {}, isLoading } =
    useFilteredSystems(syntaxFilterObjects)

  const [showResults, setShowResults] = useState(true)
  const [openResultKey, setOpenResultKey] = useState<string>()

  return (
    <div className={twMerge('overflow-y-auto', width)}>
      {isLoading ? (
        <Loader centerLoader={false} />
      ) : (
        <div className="w-full">
          <button
            className="flex flex-row p-2 justify-center items-center bg-blue-root-wash hover:bg-blue-root-focus w-full"
            onClick={() => setShowResults(!showResults)}
          >
            <p className={'break-normal all-small-caps'}>
              {`${Object.keys(filteredSystems)?.length} ${t('hits')}`}
            </p>{' '}
            {Object.keys(filteredSystems)?.length > 0 && showResults ? (
              <ArrowUp className={'fill-text-grey text-xl'} />
            ) : (
              <ArrowDown className={'fill-text-grey text-xl'} />
            )}
          </button>
          <div className={'flex flex-col'}>
            {showResults &&
              Object.keys(filteredSystems)?.map((pattern) => {
                const systemGroup = filteredSystems[pattern]
                const groupIsOpen = openResultKey === pattern
                return (
                  <div key={pattern}>
                    <div
                      className={twMerge(
                        'flex items-center gap-2 hover:bg-blue-root-tab-wash p-2 cursor-pointer',
                        groupIsOpen && 'bg-blue-root-wash',
                      )}
                      onClick={() =>
                        setOpenResultKey(groupIsOpen ? undefined : pattern)
                      }
                    >
                      <div className="w-full flex flex-col items-start justify-center text-sm">
                        {formatDisplayString(pattern)}
                        <div className="text-d-fontgrey text-xs break-words">
                          {`${systemGroup.system_syntax_category.syntax_string} - ${systemGroup.system_syntax_category.name}: `}
                          {!!systemGroup.system_type
                            ? systemGroup.system_type?.name
                            : t('with_children_in_search')}
                        </div>
                      </div>
                      <div className="flex text-sm p-1 rounded-sm">
                        {systemGroup.systems.length}
                        {groupIsOpen ? (
                          <ArrowUp className={'fill-text-grey text-xl'} />
                        ) : (
                          <ArrowDown className={'fill-text-grey text-xl'} />
                        )}
                      </div>
                    </div>
                    {groupIsOpen && (
                      <div className={'flex flex-col text-xs cursor-default'}>
                        {systemGroup.systems.map((system: ISystem) => (
                          <div
                            key={system.id}
                            className={'p-2 hover:bg-bg-grey'}
                          >{`${system.record_id}`}</div>
                        ))}
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </div>
  )
}

export default FilteredSystemsList

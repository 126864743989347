import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Systems from 'src/components/system/Systems'
import DocUploadSummery from 'src/document/components/FileUpload/fix-upload/DocUploadSummery'
import { ISystem, ISystemImportHistory } from 'src/service/OrgTypes'
import { getSingleSystemHistory } from 'src/service/SystemService'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

export interface ISystemImportDetailProps {
  systemImportId: number
  closeModal: () => void
  show: boolean
}

const SystemImportDetail: FC<ISystemImportDetailProps> = ({
  systemImportId,
  closeModal,
  show,
}) => {
  const { t } = useTranslation()
  const [systemImportHistory, setSystemImportHistory] =
    useState<ISystemImportHistory>()

  useEffect(() => {
    getSingleSystemHistory(systemImportId).then((res) => {
      setSystemImportHistory(res)
    })
  }, [systemImportId])

  return (
    <Modal
      show={show}
      title={t('import_detail')}
      closeModal={closeModal}
      maxWidth={1230}
    >
      <div className={'min-h-95 pr-4'}>
        <DocUploadSummery
          reject={false}
          quantity={systemImportHistory?.new_systems?.length ?? 0}
          objectImport={{
            isSystemImport: true,
            newComponents: true,
            active:
              systemImportHistory?.new_systems?.filter(
                (s: ISystem) => s.is_active,
              ).length ?? 0,
            inactive:
              systemImportHistory?.new_systems?.filter(
                (s: ISystem) => !s.is_active,
              ).length ?? 0,
          }}
        >
          <Systems
            className="bg-[#F4F6F7] overflow-auto min-h-95 p-5"
            systems={systemImportHistory?.new_systems ?? []}
            tableName={'system_import_history_updated_systems'}
          />
        </DocUploadSummery>

        <DocUploadSummery
          reject={false}
          quantity={systemImportHistory?.updated_systems?.length ?? 0}
          objectImport={{
            isSystemImport: true,
            newComponents: false,
            active:
              systemImportHistory?.updated_systems?.filter(
                (s: ISystem) => s.is_active,
              ).length ?? 0,
            inactive:
              systemImportHistory?.updated_systems?.filter(
                (s: ISystem) => !s.is_active,
              ).length ?? 0,
          }}
        >
          <Systems
            className="bg-[#F4F6F7] overflow-auto min-h-95 p-5"
            systems={systemImportHistory?.updated_systems ?? []}
            tableName={'system_import_history_updated_systems'}
          />
        </DocUploadSummery>
      </div>

      <ModalFooter>
        <Button type={Button.ButtonType.PRIMARY} onClick={closeModal}>
          {t('done')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SystemImportDetail

import Add from '@icons/add.svg'
import Edit from '@icons/edit.svg'
import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowUp from '@icons/keyboard_arrow_up.svg'
import Upload from '@icons/upload.svg'
import { useQueryClient } from '@tanstack/react-query'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import usePersistedState from 'src/components/hooks/UsePresistedState'
import { IMPORT_TYPE } from 'src/components/notifications/import/ImportNotificationItem'
import TestWorkGroupForm from 'src/components/system/test-work-groups/TestWorkGroupForm'
import TestWorkGroupGraph, {
  IPlannedTWGSummary,
} from 'src/components/system/test-work-groups/TestWorkGroupGraph'
import TestWorkGroupInspectorPanel from 'src/components/system/test-work-groups/TestWorkGroupInspectorPanel'
import { useTestWorkGroupColumns } from 'src/components/system/test-work-groups/useTestWorkGroupColumns'
import ImportModal from 'src/components/upload-item/ImportModal'
import DataSelectors, {
  DATA_TYPES,
  ImportFiledTypes,
} from 'src/document/components/FileUpload/selectors/DataSelectors'
import { testWorkGroupExportTemplate } from 'src/export-templates/TestWorkGroupExport'
import { testWorkGroupImport } from 'src/export-templates/TestWorkGroupImport'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import useUserAccess from 'src/hooks/useUserAccess'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { useFilteredTestWorkGroups } from 'src/query/systems/testWorkGroups'
import { ITestWorkGroup } from 'src/service/OrgTypes.js'
import {
  deleteTestWorkGroup,
  testWorkGroupBulkDelete,
  testWorkGroupValidateFromJson,
} from 'src/service/TestWorkGroupService.js'
import Table from 'src/ui-elements/Table/Table'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Modal from 'src/ui-elements/modal/Modal'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'

export const testWorkGroupsListImportColumns = [
  DataSelectors.defineDataField('record_id', DATA_TYPES.string),
  DataSelectors.defineDataField('title', DATA_TYPES.string),
  DataSelectors.defineDataField('description', DATA_TYPES.string),
  DataSelectors.defineDataField('location', DATA_TYPES.string),
  DataSelectors.getDataField(ImportFiledTypes.PRIORITY),
  DataSelectors.defineDataField('planned_start', DATA_TYPES.date),
  DataSelectors.defineDataField('planned_end', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_start', DATA_TYPES.date),
  DataSelectors.defineDataField('actual_end', DATA_TYPES.date),
  DataSelectors.getDataField(ImportFiledTypes.TEST_WORK_GROUP_STATUSES),
  DataSelectors.getDataField(ImportFiledTypes.DISCIPLINE),
]

interface ITestWorkGroupsListProps {
  projectId: number
  statusId?: number
  disciplineIds?: number[]
  selectedDates?: string[]
  onTestWorkGroupChange?: () => void
  defaultShowGraph?: boolean
  readonly?: boolean
}

const TestWorkGroupList = ({
  projectId,
  statusId,
  disciplineIds,
  selectedDates,
  onTestWorkGroupChange,
  defaultShowGraph = false,
  readonly,
}: ITestWorkGroupsListProps) => {
  const { writeAccess } = useUserAccess('object')
  const { t } = useTranslation()
  const { confirmDelete } = useDeleteModal()

  const [showGraph, setShowGraph] = usePersistedState(
    'testWorkGroupListGraph',
    defaultShowGraph,
  )
  const [statusFilter, setStatusFilter] = useState<TableFilter>()
  const [dateFilter, setDateFilter] = useState<TableFilter>()
  const [disciplineFilter, setDisciplineFilter] = useState<TableFilter>()

  const [selectedTestWorkGroup, setSelectedTestWorkGroup] =
    useState<ITestWorkGroup>()
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [selectedTestWorkGroupIds, setSelectedTestWorkGroupIds] = useState<
    number[]
  >([])
  const [showMassEditModal, setShowMassEditModal] = useState<boolean>(false)
  const [showImportModal, setImportModal] = useState(false)
  const { addAlert } = useAlert()
  const showAlert = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('TestWorkGroup')

  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['testWorkGroupsFiltered'] })
  }

  useEffect(() => {
    if (!statusId) return
    setStatusFilter({ test_work_group_status: [statusId] })
  }, [statusId])

  useEffect(() => {
    if (!selectedDates) return
    setDateFilter({ planned_end: selectedDates })
  }, [selectedDates])

  useEffect(() => {
    if (!disciplineIds) return
    setDisciplineFilter({ discipline: disciplineIds })
  }, [disciplineIds])

  useEffect(() => {
    reload()
  }, [projectId])

  const history = useHistory()

  const onRowClick = (workGroup: ITestWorkGroup) => {
    onTestWorkGroupChange?.()
    history.push(`/systems/test_work_groups?testWorkGroupId=${workGroup.id}`)
  }

  const deleteRow = (testWorkGroup: ITestWorkGroup) => {
    if (!testWorkGroup.id) return
    deleteTestWorkGroup(testWorkGroup.id).then(() => {
      reload()
      onTestWorkGroupChange?.()
    })
  }

  const onDeleteItemClick = async (row: ITestWorkGroup) => {
    const itemIdnType = `${row.record_id} (${t('test_work_group')})`
    const itemName = `${row.record_id} - ${row.title}`
    const deleteConfirmed = await confirmDelete({ itemIdnType, itemName })
    if (deleteConfirmed) {
      deleteRow(row)
    }
  }

  const { userDefinedAttributesColumns, columns } = useTestWorkGroupColumns({
    writeAccess: writeAccess && !readonly,
    reload,
  })

  const onCloseInspector = () => {
    setSelectedTestWorkGroup(undefined)
  }

  const handlePreviewClick = (data: ITestWorkGroup) => {
    setSelectedTestWorkGroup(data)
  }

  const onBulkDelete = (testWorkGroups: ITestWorkGroup[]) => {
    testWorkGroupBulkDelete(
      projectId,
      testWorkGroups.map((testWorkGroup) => testWorkGroup.id),
    ).then(() => {
      showAlert(
        'success',
        t('successfully_deleted'),
        t('selected_test_work_group_are_deleted'),
      )
      reload()
      setSelectedTestWorkGroupIds([])
      onTestWorkGroupChange?.()
    })
  }

  const openMassEditModal = (selectedItems: ITestWorkGroup[]) => {
    setSelectedTestWorkGroupIds(selectedItems.map((item) => item.id))
    setShowMassEditModal(true)
  }

  const closeMassEditModal = () => {
    setShowMassEditModal(false)
    setSelectedTestWorkGroupIds([])
    reload()
  }

  const closeCreateModal = () => {
    setShowCreateModal(false)
    reload()
  }

  const updateFilter = (
    { month, year }: Pick<IPlannedTWGSummary, 'year' | 'month'>,
    disciplines: number[],
  ) => {
    const start = moment().year(year).month(month).startOf('month')
    const end = moment().year(year).month(month).endOf('month')
    setDateFilter({ planned_end: [start.format(), end.format()] })
    setDisciplineFilter({ discipline: disciplines })
  }

  return (
    <div className={'flex flex-col'}>
      {showGraph && (
        <TestWorkGroupGraph projectId={projectId} updateFilter={updateFilter} />
      )}
      <Button
        type={ButtonType.SECONDARY}
        onClick={() => setShowGraph(!showGraph)}
      >
        {showGraph ? (
          <ArrowUp className={'text-xl'} />
        ) : (
          <ArrowDown className={'text-xl'} />
        )}
        {showGraph ? t('hide_graph') : t('show_graph')}
      </Button>
      <Table
        name={'testWorkGroupsList'}
        columns={columns}
        legacyColumns={userDefinedAttributesColumns}
        initialFilter={{
          ...statusFilter,
          ...disciplineFilter,
          ...dateFilter,
        }}
        onRowClick={onRowClick}
        useDataQuery={useFilteredTestWorkGroups}
        onRedirectClick={onRowClick}
        onPreviewClick={handlePreviewClick}
        onDeleteClick={writeAccess && !readonly ? onDeleteItemClick : undefined}
        tableButtons={(selectedItems) => ({
          onBulkDelete:
            writeAccess && !readonly
              ? () => onBulkDelete(selectedItems)
              : undefined,
          exportData: testWorkGroupExportTemplate,
          customButtons:
            writeAccess && !readonly
              ? [
                  <Button
                    key={'create-button'}
                    onClick={() => setShowCreateModal(true)}
                    type={Button.ButtonType.PRIMARY}
                  >
                    <Add className="fill-white text-xl" />
                    {t('new_test_work_group')}
                  </Button>,
                  <Button
                    key={'import-button'}
                    title={t('import')}
                    type={Button.ButtonType.SECONDARY}
                    className={'p-1 w-8 h-8'}
                    onClick={() => setImportModal(true)}
                  >
                    <Upload className="text-xl" />
                  </Button>,
                  <Button
                    key={'edit-button'}
                    title={t('edit')}
                    type={Button.ButtonType.SECONDARY}
                    disabled={!selectedItems.length}
                    onClick={() => openMassEditModal(selectedItems)}
                    className={'p-1 w-8 h-8'}
                  >
                    <Edit className={'text-xl'} />
                  </Button>,
                ]
              : undefined,
        })}
      />

      <Modal
        show={showMassEditModal}
        closeModal={closeMassEditModal}
        title={t('edit_multiple_test_work_groups')}
        maxWidth={800}
      >
        <TestWorkGroupForm
          editingMode={true}
          projectId={projectId}
          testWorkGroupIds={selectedTestWorkGroupIds}
          closeModal={() => {
            closeMassEditModal()
            onTestWorkGroupChange?.()
          }}
        />
      </Modal>
      {selectedTestWorkGroup?.id && (
        <TestWorkGroupInspectorPanel
          projectId={projectId}
          testWorkGroupId={selectedTestWorkGroup?.id}
          open={true}
          onClose={onCloseInspector}
          onUpdate={() => {
            reload()
            onTestWorkGroupChange?.()
          }}
        />
      )}
      <Modal
        show={showCreateModal}
        closeModal={closeCreateModal}
        title={t('add_work_group')}
        maxWidth={800}
      >
        <TestWorkGroupForm
          editingMode={false}
          projectId={projectId}
          closeModal={() => {
            closeCreateModal()
            onTestWorkGroupChange?.()
          }}
        />
      </Modal>
      {showImportModal && (
        <ImportModal
          columns={testWorkGroupsListImportColumns}
          import_type={IMPORT_TYPE.TEST_WORK_GROUP_IMPORT}
          show={showImportModal}
          close={() => {
            setImportModal(false)
            reload()
            onTestWorkGroupChange?.()
          }}
          useDefinedField={systemTypeGroup?.optional_fields ?? []}
          importApi={testWorkGroupValidateFromJson}
          modal_title={'upload_test_work_groups'}
          template={testWorkGroupImport}
          uploadUrl={`{ORG_URL}test_work_groups/validate_for_import_job`}
        />
      )}
    </div>
  )
}

export default TestWorkGroupList

import { ColumnFiltersState } from '@tanstack/react-table'
import { t } from 'i18next'
import { FC, useState } from 'react'
import SystemSelectTable from 'src/components/system/system-table/SystemSelectTable'
import { ISystem } from 'src/service/OrgTypes'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { classNames } from 'src/utility/utils'

interface ISelectSystemsListProps {
  tableName: string
  onSubmit?: (ids: number[]) => void
  closeModal: () => void
  initialSelection?: number[]
  targetName?: string
  filter?: Omit<TableFilter, 'sort'>
  defaultFilter?: ColumnFiltersState
  loading?: boolean
  isSingleSelect?: boolean
  onRowClick?: (system: ISystem) => void
}

const SystemSelectModal: FC<ISelectSystemsListProps> = ({
  tableName,
  onSubmit,
  closeModal,
  initialSelection,
  targetName,
  filter,
  loading,
  isSingleSelect = false,
  onRowClick,
  defaultFilter,
}) => {
  const [selectedSystems, setSelectedSystems] = useState<number[]>(
    initialSelection ?? [],
  )

  const styleClass = {
    root: classNames('md_w-full', 'flex', 'p-2', 'flex-col', 'min-h-[700px]'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }

  return (
    <Modal
      show={true}
      title={
        targetName
          ? t('select_items_you_want_to_add_to_target_w_param', {
              param: isSingleSelect
                ? t('system').toLowerCase()
                : t('systems').toLowerCase(),
              target: targetName,
            })
          : t('select_items_you_want_to_add_w_param', {
              param: isSingleSelect
                ? t('system').toLowerCase()
                : t('systems').toLowerCase(),
            })
      }
      closeModal={closeModal}
      size={'w-9/10'}
      minHeight={'800px'}
      maxWidth={1700}
    >
      <div className={styleClass.root}>
        <SystemSelectTable
          tableName={tableName}
          onSelectedSystems={setSelectedSystems}
          initialSelection={initialSelection}
          filter={filter}
          onRowClick={onRowClick}
          isSingleSelect={isSingleSelect}
          defaultFilter={defaultFilter}
        />
      </div>
      {!isSingleSelect && onSubmit && (
        <ModalFooter>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          {!loading ? (
            <Button
              disabled={selectedSystems.length < 1}
              type={Button.ButtonType.PRIMARY}
              onClick={() => onSubmit(selectedSystems)}
            >
              {t('save')}
            </Button>
          ) : (
            <Spinner />
          )}
        </ModalFooter>
      )}
    </Modal>
  )
}

export default SystemSelectModal

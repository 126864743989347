import DataSet from '@material-symbols/svg-500/outlined/dataset.svg'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { IConstructionTask, IConstructionWagon } from 'src/service/OrgTypes'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'

const MConstructionTaskItem = ({
  task,
  wagon,
  noPadding,
}: {
  task: IConstructionTask
  wagon: IConstructionWagon
  noPadding?: boolean
}) => {
  const history = useHistory()
  const getStatusIcon = (task: IConstructionTask): Icons => {
    const delayed =
      task.status !== 'done' && moment().isAfter(task.deadline, 'day')
    return delayed
      ? Icons.CHECK_RED_CIRLCE
      : task.status === 'done'
        ? Icons.CHECK_GREEN_CIRLCE
        : Icons.CHECK_GRAY_CIRLCE
  }
  const { t } = useTranslation()
  return (
    <div
      title={t('click_to_see_details')}
      onClick={() =>
        history.push(
          `/mobile/workops/${task.construction_locomotive_id}/${task.id}`,
        )
      }
      key={task.id}
      className={twMerge(
        'bg-white py-2 flex hover:bg-gray-50 md:rounded cursor-pointer',
        !noPadding && 'px-4',
      )}
    >
      <div className={'flex items-center pr-4'}>
        <Icon
          icon={Icons.WORK_OPS_CIRCLED}
          className={'w-6 h-6 flex flex-shrink-0'}
        />
      </div>

      <div className={'flex-auto min-w-0 flex flex-col'}>
        <p
          className={
            'block w-full truncate text-md font-semibold text-gray-800'
          }
        >
          {task?.title}
        </p>
        <p
          className={
            'block w-full truncate text-sm font-extralight text-gray-600'
          }
        >
          {task?.contractor_comment}
        </p>
        <div className={'w-full truncate flex justify-between'}>
          <p className={'flex items-center font-medium text-gray-600 text-sm'}>
            <Icon
              icon={Icons.CLOCK}
              className={'w-3 h-3 mr-1 flex items-center'}
            />
            {`${task.total_work_hours ? task.total_work_hours : 0}`}
          </p>
          <div className={'flex items-center'}>
            <p className={'flex items-center font-light text-gray-800 text-xs'}>
              <Icon icon={Icons.MAN} className={'w-4 h-4 flex items-center'} />
              {`${task?.number_of_workers ?? '0'}`}
            </p>
            <Icon
              className={'w-6 h-6 ml-2 flex items-center'}
              icon={getStatusIcon(task)}
            />
          </div>
        </div>
        <div className={'w-full truncate flex justify-between'}>
          <p className={'flex items-center font-medium text-gray-600 text-sm'}>
            <Icon
              icon={Icons.LOCOMOTIVE}
              className={'w-5 h-5 mr-1 flex items-center'}
            />
            {`${wagon.title}`}
          </p>
          <div className={'flex items-center'}>
            <p className={'flex items-center font-light text-gray-800 text-xs'}>
              <DataSet className="w-5 h-5" />
              {wagon.control_area?.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MConstructionTaskItem

import moment from 'moment'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import { useTestExecution } from 'src/query/test'
import { ITestExecution } from 'src/service/OrgTypes'
import { editTestExecution } from 'src/service/TestService'
import { updateWallToken } from 'src/service/TheWallService'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineCompontent from 'src/ui-elements/page-display/inline-components/InlineComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import CloseTestExecution from '../approval/CloseTestExecution'
import SendForSigning from '../approval/SendForSigning'
import { TestExecutionStatus } from '../tests/TestExecutionList'
import ApprovalInspectorTable from './ApprovalInspectorTable'

interface TestExecutionInspectorPanelProps {
  testId: number
  testExecutionId: number
  open: boolean
  isTestOwner: boolean
  onClose: () => void
  onUpdate: () => void
}

const TestExecutionInspectorPanel: FC<TestExecutionInspectorPanelProps> = ({
  testId,
  testExecutionId,
  open,
  onClose,
  onUpdate,
  isTestOwner,
}) => {
  const { t } = useTranslation()
  const { data: testExecution, refetch } = useTestExecution(testExecutionId)

  const projectId = useProjectId()

  const editExecution = async (
    id: number,
    testExecution: Partial<ITestExecution>,
  ) => {
    await editTestExecution(projectId, testId, { id: id, ...testExecution })
    refetch()
    onUpdate()
  }

  const downloadFile = async (url: string) => {
    const token = await updateWallToken()
    window.open(`${url}&token=${token}`, '_blank')
  }

  const sections: IInspectorSection[] = [
    {
      name: t('documents'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <InspectorSectionTable
          headerColumns={[t('name'), t('uploaded'), t('')]}
          rowsData={(testExecution?.attachments ?? []).map((doc) => {
            return {
              cells: [
                <a
                  key={doc.name}
                  className="cursor-pointer underline text-blue-root"
                  onClick={() => downloadFile(doc.url)}
                >
                  {doc.name}
                </a>,
                moment(doc.created_at).format('L'),
                <MaterialIcon
                  key={doc.id}
                  icon="open_in_new"
                  onClick={() => downloadFile(doc.url)}
                  className="bg-d-lightblue cursor-pointer w-12 h-5 text-blue-root text-xs flex px-2 items-center justify-center rounded-md"
                />,
              ],
              id: doc.id,
            }
          })}
        />
      ),
    },
    {
      name: t('approve_test_report'),
      icon: 'fact_check',
      activeIcon: 'fact_check',
      isMaterialIcon: true,
      content: <ApprovalInspectorTable testExecutionId={testExecutionId} />,
    },
  ]

  return (
    <FixedPane
      title={testExecution?.record_id ?? ''}
      show={open}
      onClose={() => onClose()}
      className={'w-[700px]'}
    >
      <>
        {testExecution && (
          <InlineComponentsWrapper
            key={testExecutionId}
            padding="left"
            labelWidth="w-48"
          >
            <DateTimeInlineInputComponent
              label={t('planned_start')}
              selectedTime={`${testExecution.test_planned_start}`}
              onValueSubmitted={(d) => {
                editExecution(testExecutionId, {
                  test_planned_start: moment(d),
                })
              }}
              disabled={testExecution.step !== 'planning'}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label={t('planned_end')}
              selectedTime={`${testExecution.test_planned_end}`}
              onValueSubmitted={(d) => {
                editExecution(testExecutionId, {
                  test_planned_end: moment(d),
                })
              }}
              disabled={testExecution.step !== 'planning'}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label={t('started')}
              selectedTime={`${testExecution.test_started}`}
              onValueSubmitted={(d) => {
                editExecution(testExecutionId, { test_started: moment(d) })
              }}
              disabled={testExecution.step !== 'in_progress'}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label={t('completed')}
              selectedTime={`${testExecution?.test_ended}`}
              onValueSubmitted={(d) => {
                editExecution(testExecutionId, { test_ended: moment(d) })
              }}
              validate={(value) => {
                if (value) {
                  return moment(value).isAfter(
                    moment(testExecution?.test_started),
                  )
                    ? undefined
                    : t('invalid')
                }
                return
              }}
              disabled={testExecution.step !== 'in_progress'}
              inspectorPanel={true}
            />
            <TextInlineInputCompontent
              label={t('revision')}
              value={testExecution?.revision}
              onValueSubmitted={(value) =>
                editExecution(testExecutionId, { revision: value })
              }
              disabled={testExecution.step !== 'in_progress'}
            />
            <SelectorInlineInputComponent
              items={Object.values(TestExecutionStatus).map((e) => ({
                id: e,
                name: t(e),
              }))}
              label="status"
              disabled={testExecution.step !== 'in_progress'}
              initialItem={{
                id: testExecution?.status,
                name: t(testExecution?.status ?? ''),
              }}
              getItemLabel={(s) => `${t(s?.name ?? '')}`}
              selectedId={testExecution?.status}
              onValueSubmitted={(id) =>
                editExecution(testExecutionId, { status: id })
              }
              inspectorPanel={true}
            />
            {testExecution && (
              <InlineCompontent
                label="send_for_approval"
                disabled={testExecution.step !== 'singing'}
                content={
                  <SendForSigning
                    id={testExecutionId}
                    isTestOwner={isTestOwner}
                    step={testExecution?.step ?? false}
                    editExecution={editExecution}
                  />
                }
              />
            )}

            <InlineCompontent
              label="close"
              content={
                <CloseTestExecution
                  id={testExecutionId}
                  isTestOwner={isTestOwner}
                  step={testExecution.step}
                  editExecution={editExecution}
                />
              }
            />
            <DateTimeInlineInputComponent
              label="closed_date"
              selectedTime={testExecution.closed_date?.toString()}
              onValueSubmitted={(_) => {
                /*noop*/
              }}
              disabled={true}
              inspectorPanel={true}
            />
            <TextInlineInputCompontent
              label={t('comment')}
              value={testExecution.comment}
              onValueSubmitted={(value) =>
                editExecution(testExecutionId, { comment: value })
              }
              textArea={true}
              disabled={testExecution.step !== 'closed'}
            />
            <DateTimeInlineInputComponent
              label="created_at"
              selectedTime={testExecution.created_at}
              onValueSubmitted={(_) => {
                /*noop*/
              }}
              disabled={true}
              inspectorPanel={true}
            />
            <DateTimeInlineInputComponent
              label="updated_at"
              selectedTime={testExecution.updated_at}
              onValueSubmitted={(_) => {
                /*noop*/
              }}
              disabled={true}
              inspectorPanel={true}
            />
          </InlineComponentsWrapper>
        )}
        <InspectorSections sections={sections} />
      </>
    </FixedPane>
  )
}

export default TestExecutionInspectorPanel

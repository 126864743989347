import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import {
  getFilterValues,
  setFilterValues,
} from 'src/service/CanavasFilterService'
import { search } from 'src/service/SearchService'
import Badge from 'src/ui-elements/badge/Badge'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { capFirstLetter } from '../../utility/utils'
import MContent from '../components/MContent'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MSelectableChip from '../components/MSelectableChip'

interface ISearchTypes {
  rooms: boolean
  control_areas: boolean
  construction_locomotives: boolean
  systems: boolean
}

const MSearch = (_props: any) => {
  const history = useHistory()
  const { text } = useParams<{ text: string }>()
  const projectContext = useContext<IProjectContext>(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const page = useRef<number>(1)
  const totalPages = useRef<number>(1)
  const [totalItems, setTotalItems] = useState<number>(0)
  const filterName = 'MobileSearchFilter'

  const loadCacheAndFetch = () => {
    getFilterValues(projectId, filterName).then((res) => {
      const filters: ISearchTypes = res && !Array.isArray(res) ? res : types
      if (filters && JSON.stringify(filters) !== JSON.stringify(types)) {
        setTypes(filters)
      } else {
        fetchSearchResults(1, getTypeStr(types), false)
      }
    })
  }

  const setFilterToTableKeeper = (filterData: ISearchTypes) => {
    setFilterValues(projectId, filterName, filterData)
  }

  const getTypeStr = (_typeObj: ISearchTypes) => {
    const typesAsArray = Object.entries(types)
    const filtered = typesAsArray
      .filter(([_, value]) => value === true)
      .map(([key, _]) => key)
    const typesStr =
      filtered.length > 0
        ? filtered
        : ['rooms', 'control_areas', 'construction_locomotives', 'systems']
    return typesStr
  }

  const fetchSearchResults = async (
    pageNumber: number,
    typesStr: string[],
    append: boolean,
  ) => {
    if (text) {
      if (!append) {
        setLoading(true)
      }

      const res = await search(projectId, text, typesStr, pageNumber, 24)

      if (append) {
        setData((prevData: any) => [...prevData, ...res.search_results])
        setPaginationLoading(false)
      } else {
        setData(res.search_results)
        page.current = 1
      }
      totalPages.current = res?.pages ? res?.pages : 1
      setTotalItems(res?.total_items ? res?.total_items : 0)
      setShowMoreButton(page.current < totalPages.current)
      setLoading(false)
    }
  }

  const { t } = useTranslation()
  const userContext: IUserContext = useContext(UserContext)
  const { user } = userContext.state
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [types, setTypes] = useState<ISearchTypes>({
    rooms: true,
    control_areas: true,
    construction_locomotives: true,
    systems: true,
  })
  const [showMoreButton, setShowMoreButton] = useState<boolean>(false)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)

  useEffect(() => {
    loadCacheAndFetch()
  }, [projectId, types])

  const translateType = (name: string) => {
    if (name === 'ControlArea') {
      return capFirstLetter(t('control_area'))
    }
    if (name === 'ConstructionLocomotive') {
      return capFirstLetter(t('wagon'))
    }
    if (name === 'System') {
      return 'System'
    }
    if (name === 'Room') {
      return capFirstLetter(t('room'))
    }
    return name
  }

  const getItem = (item: any, key: number) => (
    <div
      key={key}
      onClick={() => handleClick(item)}
      className={'bg-white px-4 py-2 flex'}
    >
      <div className={'flex items-center'}>
        <div
          className={
            'w-5 h-5 shrink-0 grow-0 flex justify-center items-center mr-4 border rounded-full border-gray-300'
          }
        >
          <Icon
            icon={Icons.SEARCH_GRAY}
            className={'w-3 h-3 flex flex-shrink-0'}
          />
        </div>
      </div>

      <div className={'flex-auto flex flex-col min-w-0'}>
        <p className={'text-md font-semibold text-gray-800 truncate'}>
          {item?.title || item?.room_name || item?.name}
        </p>
        <p
          className={'text-sm font-extralight text-gray-600'}
        >{`${capFirstLetter(t('type'))}: ${translateType(item?.data_type)}`}</p>
        <p className={'text-sm font-light text-gray-600'}>{`${capFirstLetter(
          t('created_at'),
        )}: ${moment(item?.created_at).format('ddd MMM DD YYYY')}`}</p>
      </div>

      <div className={'flex items-center'}>
        <Icon
          className={'w-6 h-6 ml-2 flex items-center'}
          icon={getTypeIcon(item?.data_type)}
        />
      </div>
    </div>
  )

  const handleClick = (item: any) => {
    if (item?.data_type === 'ControlArea' && item?.id) {
      history.push(`/mobile/control-areas/${item?.id}?tabIndex=0`)
    }
    if (item?.data_type === 'ConstructionLocomotive' && item?.id) {
      history.push(`/mobile/wagons/${item?.id}?tabIndex=0`)
    }
    if (item?.data_type === 'System' && item?.id) {
      history.push(`/mobile/systems/${item?.id}`)
    }
    if (item?.data_type === 'Room' && item?.id) {
      history.push(`/mobile/rooms/${item?.id}`)
    }
  }

  const getTypeIcon = (type: string): Icons => {
    return type === 'ControlArea'
      ? Icons.CONSTRUCTION_BLUE
      : type === 'ConstructionLocomotive'
        ? Icons.LOCOMOTIVE_BLUE
        : type === 'Room'
          ? Icons.ROOM_BLUE
          : type === 'System'
            ? Icons.SYSTEM_BLUE
            : Icons.LIST_BLUE
  }

  const Loader = () => (
    <div className={'w-full h-full grid place-items-center relative z-n1'}>
      <SpinnerMobile />
    </div>
  )

  const onLoadMore = () => {
    setPaginationLoading(true)
    if (page.current && totalPages.current) {
      page.current = page.current + 1
      if (page.current <= totalPages.current) {
        fetchSearchResults(page.current, getTypeStr(types), true)
      }
    }
  }

  const getListWithPagination = () => (
    <section className={'flex flex-col'}>
      {data?.length > 0 ? (
        <div>
          <div
            className={
              'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 md:px-4'
            }
          >
            {data.map((itemData: any, i: number) => getItem(itemData, i))}
          </div>
          {showMoreButton && (
            <div className={'flex justify-center items-center my-2 h-10'}>
              {paginationLoading ? (
                <div className={'transform scale-25'}>
                  <SpinnerMobile />
                </div>
              ) : (
                <Button
                  onClick={onLoadMore}
                  type={Button.ButtonType.PRIMARY}
                  size={Button.ButtonSize.XSMALL}
                >
                  {t('load_next_page')}
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className={'w-full h-full grid place-items-center my-4'}>
          <Badge
            text={t('no_results_found')}
            size={Badge.BadgeSize.LARGE}
            color={Badge.BadgeColor.YELLOW}
          />
        </div>
      )}
    </section>
  )

  const updateSelectedTypes = (type: string) => {
    const clone: ISearchTypes = { ...types }
    clone[type] = !types[type]
    setTypes(clone)
    setFilterToTableKeeper(clone)
  }

  const getTitle = () => {
    return t('showing_search_result_header', {
      show: data.length,
      total: totalItems,
      text,
    })
  }

  return (
    <MPage>
      <>
        <MHeader
          onBackClick={() => history.goBack()}
          user={user}
          title={t('search_results')}
        />
        <div
          className={
            'text-xs text-gray-800 font-light p-2 pb-0 pl-4 first-capitalize'
          }
        >
          {getTitle()}
        </div>
        <div
          className={
            'w-full flex justify-around md:justify-start items-center mb-2 px-2'
          }
        >
          <MSelectableChip
            onClick={() => updateSelectedTypes('control_areas')}
            text={t('control_areas')}
            selected={types.control_areas}
          />
          <MSelectableChip
            onClick={() => updateSelectedTypes('construction_locomotives')}
            text={t('wagons')}
            selected={types.construction_locomotives}
          />
          <MSelectableChip
            onClick={() => updateSelectedTypes('systems')}
            text={t('systems')}
            selected={types.systems}
          />
          <MSelectableChip
            onClick={() => updateSelectedTypes('rooms')}
            text={t('rooms')}
            selected={types.rooms}
          />
        </div>
        <MContent>{loading ? Loader() : getListWithPagination()}</MContent>
      </>
    </MPage>
  )
}

export default MSearch

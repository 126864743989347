import { ITimelineItem, TimelineItemType } from 'src/components/timeline/types'
import { ImportTask } from 'src/context/ImportContext/ImportTypes'
import { IPPUGoal, IProject, IProjectData } from './OrgTypes'
import { request } from './service-utils'

/**
 * Project
 */

export const createProject = (
  project: IProject,
  orgId: number,
): Promise<any> => {
  return request(`organizations/${orgId}/projects`, 'POST', project)
}

export const editProject = (project: IProject): Promise<any> => {
  return request(`projects/${project.id}`, 'PUT', project)
}

export const deleteProject = (project: IProject): Promise<any> => {
  return request(`projects/${project.id}`, 'DELETE', {}, true)
}

export const getAllProjects = (organizationId: number): Promise<IProject[]> => {
  return request(`organizations/${organizationId}/projects`, 'GET')
}

export const getMyProjects = (userId: number): Promise<IProjectData[]> => {
  return request(`users/${userId}/projects`, 'GET')
}

export const getUserProjects = (
  organizationId: number,
  userId: number,
): Promise<IProject[]> => {
  return request(
    `organizations/${organizationId}/users/${userId}/projects`,
    'GET',
  )
}

export const getProjectTree = (
  projectId: number,
  token?: string,
): Promise<any> => {
  return request(
    `projects/${projectId}/project_tree`,
    'GET',
    undefined,
    undefined,
    token,
  )
}

export const getChildrenGanttTree = (
  projectId: number,
  type: TimelineItemType,
  itemId: number,
  body: { hierarchy: string },
): Promise<ITimelineItem> => {
  return request(
    `projects/${projectId}/${type}/${itemId}/children`,
    'POST',
    body,
  )
}

export const getProjectMilestoneTree = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(`projects/${projectId}/mile_stone_canvas`, 'POST', data)
}

export const getProjectMainProcessMilestoneTree = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/main_process_mile_stone_timeline_canvas`,
    'POST',
    data,
  )
}

export const getProjectDisciplineMilestoneTree = (
  projectId: number,
  data: any,
): Promise<any> => {
  return request(
    `projects/${projectId}/discipline_mile_stone_timeline_canvas`,
    'POST',
    data,
  )
}

export const getProjectMilestoneThemaTree = (
  projectId: number,
): Promise<any> => {
  return request(`projects/${projectId}/project_tema_milestone_tree`, 'GET')
}

export const getProjectPPU = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/ppu_goals`, 'GET')
}

export const createProjectPPU = (
  projectId: number,
  data: IPPUGoal,
): Promise<any> => {
  return request(`/projects/${projectId}/ppu_goals`, 'POST', data)
}

export const updateProjectPPU = (data: IPPUGoal): Promise<any> => {
  return request(`ppu_goals/${data.id}`, 'PUT', data)
}

export const deleteProjectPPU = (data: IPPUGoal): Promise<any> => {
  return request(`ppu_goals/${data.id}`, 'DELETE', {}, true)
}

export const getProjectPlanFreezePeriod = (
  projectId: number,
  dataType: string,
): Promise<any> => {
  return request(`projects/${projectId}/${dataType}/plan_freeze_period`, 'GET')
}

export const removeProjectLogo = (
  id: number,
  projectLogo: boolean,
): Promise<any> => {
  return request(
    `/projects/${id}`,
    'PUT',
    projectLogo ? { logo: '' } : { image_url: '' },
  )
}

export const syncDocuments = (projectId: number): Promise<ImportTask> => {
  return request(`projects/${projectId}/sync_documents`, 'GET')
}

export const syncDocumentsOmega = (
  projectId: number,
  fileContainerIds: number[],
): Promise<ImportTask> => {
  return request(`projects/${projectId}/sync_documents_omega`, 'POST', {
    file_container_ids: fileContainerIds,
  })
}

export const syncObjectsOmega = (
  projectId: number,
  systemIds: number[],
): Promise<ImportTask> => {
  return request(`projects/${projectId}/sync_objects_omega`, 'POST', {
    system_ids: systemIds,
  })
}

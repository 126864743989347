import { ExportTemplate } from 'src/context/ExportContext/ExportTypes'
import { addColumnsToExportTemplate } from 'src/utility/exportUtils'

export const keypointListExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/key_points_filtered',
    method: 'POST',
  },
  name: 'Keypoints',
  displayName: 'key_points',
  columns: [
    'id',
    'title',
    'milestone',
    'theme',
    'discipline',
    'contract',
    'mainprocess',
    'type',
    'status',
    'improvements',
    'deadline',
    'baseline',
    'duration',
    'delay',
    'responsible',
    'reporter',
    'children',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'name',
    'mile_stone.name',
    'team.name',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'main_process.name',
    'tag.name',
    'status',
    'improvement.title',
    'endTime',
    'baseline',
    'duration',
    'delay',
    'responsible.firstName||responsible.lastName',
    'user.firstName||user.lastName',
    'done_children||open_children||expired_children',
    'created_at',
    'updated_at',
  ],
}

const colRow = addColumnsToExportTemplate(
  keypointListExportTemplate as ExportTemplate,
  [
    {
      column: 'deliveries',
      row: 'taskCtrl_child_0',
      index: 1,
    },
    {
      column: 'comments',
      row: 'taskCtrl_child_1',
      index: 4,
    },
  ],
)

export const keypointForPlanning = {
  ...keypointListExportTemplate,
  rows: colRow.rows.filter((r: string) => r !== 'mile_stone.name'),
  columns: colRow.columns.filter((c: string) => c !== 'milestone'),
}

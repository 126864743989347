import * as React from 'react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IMPORT_TYPE,
  importType,
} from 'src/components/notifications/import/ImportNotificationItem'
import ObjectImports from 'src/components/system/import/ObjectImports'
import { ImportTask } from 'src/context/ImportContext/ImportTypes'
import MaterialIcon from 'src/ui-elements/icon/materialIcon'
import { systemTypeImportColumns } from '../../components/system/SystemTypesList/SystemTypesList'
import { testSystemGroupsListImportColumns } from '../../components/system/test-system-groups/TestSystemGroupList'
import { testWorkGroupsListImportColumns } from '../../components/system/test-work-groups/TestWorkGroupList'
import ImportModal from '../../components/upload-item/ImportModal'
import { ImportContext } from '../../context/ImportContext/ImportContext'
import DocumentImports from '../../document/components/FileUpload/docUpload/DocumentImports'
import { testSystemGroupImport } from '../../export-templates/TestSystemGroupImport'
import { testWorkGroupImport } from '../../export-templates/TestWorkGroupImport'
import { systemTypeValidateFromJson } from '../../service/SystemTypeFieldService'
import { testSystemGroupImportFromJson } from '../../service/TestSystemGroupService'
import { testWorkGroupValidateFromJson } from '../../service/TestWorkGroupService'

const ImportToast = () => {
  const { state, actions } = useContext(ImportContext)
  const inProgress = state.importTasks.find(
    (task) => task.status === 'in_progress',
  )

  const sharePoint = inProgress
    ? importType(inProgress) === IMPORT_TYPE.SHAREPOINT_SYNC
    : false

  const isOmega =
    inProgress && importType(inProgress) === IMPORT_TYPE.OMEGA_SYNC

  const { setSelectedItem } = actions

  const { t } = useTranslation()

  const getDomainId = (fieldName: string, task?: ImportTask) => {
    const id = task?.data?.[fieldName]
    return id ? Number(id) : undefined
  }

  const categoryId = getDomainId('category_id', state.selectedItem)
  const folderId = getDomainId('folder_id', state.selectedItem)
  const systemSettingId = getDomainId('syntax_id', state.selectedItem)

  return (
    <>
      <div className="absolute bottom-20 z-50 left-20 w-auto ">
        {inProgress && (
          <div
            className="flex items-center w-full max-w-xs p-4  text-gray-500 bg-white space-x-4 divide-x divide-gray-500 rounded-lg shadow-lg"
            role="alert"
          >
            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white bg-blue-root rounded-full">
              <MaterialIcon icon="upload" />
              <div className="absolute -top-1 -right-1 w-3 h-3 bg-orange-one rounded-full animate-ping" />
            </div>
            <div className="flex flex-col pl-2">
              <div className="text-sm font-medium text-gray-700">
                {sharePoint
                  ? t('share_point_sync_in_progress')
                  : isOmega
                    ? t('omega_sync_in_progress')
                    : t('import_in_progress')}
              </div>
            </div>
          </div>
        )}
      </div>
      {state.selectedItem &&
        importType(state.selectedItem) === IMPORT_TYPE.SYSTEM_IMPORT &&
        systemSettingId && (
          <ObjectImports
            show={true}
            close={() => {
              setSelectedItem(undefined, undefined)
            }}
            systemSettingId={systemSettingId}
            useDefinedFiled={state.metaData}
            JobId={state.selectedItem.id}
          />
        )}
      {state.selectedItem &&
        importType(state.selectedItem) ===
          IMPORT_TYPE.TEST_SYSTEM_GROUP_IMPORT && (
          <ImportModal
            columns={testSystemGroupsListImportColumns}
            import_type={IMPORT_TYPE.TEST_SYSTEM_GROUP_IMPORT}
            show={true}
            close={() => {
              setSelectedItem(undefined, undefined)
            }}
            useDefinedField={state.metaData}
            importApi={testSystemGroupImportFromJson}
            modal_title={'upload_test_system_groups'}
            template={testSystemGroupImport}
            uploadUrl={`{ORG_URL}test_system_groups/validate_for_import_job`}
            JobId={state.selectedItem.id}
          />
        )}
      {state.selectedItem &&
        importType(state.selectedItem) ===
          IMPORT_TYPE.TEST_WORK_GROUP_IMPORT && (
          <ImportModal
            columns={testWorkGroupsListImportColumns}
            import_type={IMPORT_TYPE.TEST_WORK_GROUP_IMPORT}
            show={true}
            close={() => {
              setSelectedItem(undefined, undefined)
            }}
            useDefinedField={state.metaData}
            importApi={testWorkGroupValidateFromJson}
            modal_title={'upload_test_work_groups'}
            template={testWorkGroupImport}
            uploadUrl={`{ORG_URL}test_work_groups/validate_for_import_job`}
            JobId={state.selectedItem.id}
          />
        )}
      {state.selectedItem &&
        importType(state.selectedItem) === IMPORT_TYPE.SYSTEM_TYPE_IMPORT &&
        systemSettingId && (
          <ImportModal
            columns={systemTypeImportColumns}
            import_type={IMPORT_TYPE.SYSTEM_TYPE_IMPORT}
            show={true}
            close={() => {
              setSelectedItem(undefined, undefined)
            }}
            useDefinedField={[]}
            importApi={(projectId, data) =>
              systemTypeValidateFromJson(
                projectId,
                systemSettingId,
                data,
                categoryId,
              )
            }
            modal_title={'upload_objects_types'}
            template={testSystemGroupImport}
            uploadUrl={`{ORG_URL}system_settings/${systemSettingId}/system_types/validate_for_import_job/${
              categoryId ?? ''
            }`}
            JobId={state.selectedItem.id}
          />
        )}
      {state.selectedItem &&
        importType(state.selectedItem) === IMPORT_TYPE.DOCUMENT_IMPORT &&
        folderId && (
          <DocumentImports
            show={true}
            close={() => {
              setSelectedItem(undefined, undefined)
            }}
            folderId={folderId}
            optionalFields={state.metaData ?? []}
            importJobId={state.selectedItem.id}
          />
        )}
      {state.selectedItem &&
        importType(state.selectedItem) === IMPORT_TYPE.DOCUMENT_IMPORT &&
        folderId && (
          <DocumentImports
            show={true}
            close={() => {
              setSelectedItem(undefined, undefined)
            }}
            folderId={folderId}
            optionalFields={state.metaData ?? []}
            importJobId={state.selectedItem.id}
          />
        )}
    </>
  )
}

export default ImportToast

import { useQueryClient } from '@tanstack/react-query'
import { RowSelectionState } from '@tanstack/react-table'
import { FC, MouseEvent, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useFileContainerListColumns from 'src/document/components/DocumentExplorer/useFileContainerListColumns'
import DocumentInspectorPanel from 'src/document/components/Inspector/DocumentInspectorPanel'
import { IFileContainer } from 'src/document/types/IFileContainer'
import {
  fileContainerKey,
  useFileContainersWithPagination,
} from 'src/query/documents/fileContainer'
import Table from 'src/ui-elements/Table/Table'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface ISelectFileContainersListProps {
  tableName?: string
  selectedFileContainerIds?: number[]
  onSelectFileContainers?: (ids: number[]) => void
  closeModal: () => void
  selectSingle?: boolean
  initialFilter?: Omit<TableFilter, 'sort'>
}

const SelectFileContainersList: FC<
  PropsWithChildren<ISelectFileContainersListProps>
> = ({
  tableName,
  selectedFileContainerIds,
  onSelectFileContainers,
  closeModal,
  selectSingle = false,
  initialFilter,
  children,
}) => {
  const [selectedFileContainer, setSelectedFileContainer] = useState<
    IFileContainer | undefined
  >(undefined)
  const [showInspector, setShowInspector] = useState<boolean>(false)
  const [selectedFileContainers, setSelectedFileContainers] = useState<
    number[]
  >([...(selectedFileContainerIds ?? [])])

  const { t } = useTranslation()

  const handleSelectedFileContainers = (rows: RowSelectionState) => {
    setSelectedFileContainers(Object.keys(rows).map((row) => +row))
  }

  const handlePreviewClick = (data: IFileContainer) => {
    setSelectedFileContainer(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedFileContainer(undefined)
  }

  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.invalidateQueries({
      queryKey: [fileContainerKey],
    })
  }

  const { newColumns, legacyColumns, defaultOrdering } =
    useFileContainerListColumns({
      readonly: true,
      projectLevel: true,
      refetch: reload,
    })

  const onSubmit = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onSelectFileContainers?.(selectedFileContainers)
  }

  return (
    <Modal
      show={true}
      title={t('select_items_you_want_to_add_w_param', {
        param: t('document'),
      })}
      closeModal={closeModal}
      size={'w-9/10'}
      maxWidth={1700}
    >
      <div className={'w-full flex px-2 pb-4 flex-col min-h-[48vh]'}>
        <Table
          name={tableName ?? 'select-file-container-modal'}
          tableButtons={() => ({})}
          legacyColumns={legacyColumns}
          columns={newColumns}
          defaultOrdering={defaultOrdering}
          onPreviewClick={handlePreviewClick}
          onRowClick={(fileContainer) => {
            selectSingle && onSelectFileContainers?.([fileContainer.id])
          }}
          useDataQuery={useFileContainersWithPagination}
          setSelectedRowsParent={handleSelectedFileContainers}
          initialSelectedRowIds={
            selectSingle ? undefined : selectedFileContainerIds
          }
          selectable={!selectSingle}
          initialFilter={initialFilter}
        />
        {children}

        {showInspector && selectedFileContainer && (
          <DocumentInspectorPanel
            documentId={selectedFileContainer.id}
            open={showInspector}
            onClose={onCloseInspector}
          />
        )}
      </div>
      {!selectSingle && (
        <ModalFooter>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
            {t('save')}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default SelectFileContainersList

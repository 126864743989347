import moment from 'moment'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Switch } from 'react-router-dom'
import { ConfirmModalContextProvider } from 'src/context/ConfirmModalContext'
import SubcontractorWrapper from 'src/subcontractor/components/SubcontractorWrapper'
import SubcontractorPage from 'src/subcontractor/pages/SubcontractorPage'

const FdvuSubcontractorRouter = () => {
  const { i18n } = useTranslation()

  useEffect(() => {
    moment.locale(i18n.language === 'no' ? 'nb' : i18n.language)
  }, [i18n.language])

  return (
    <Switch>
      <ConfirmModalContextProvider>
        <SubcontractorWrapper>
          <Route
            exact={true}
            path={`/subcontractor`}
            render={(_routeProps) => {
              return <SubcontractorPage />
            }}
          />
        </SubcontractorWrapper>
      </ConfirmModalContextProvider>
    </Switch>
  )
}

export default FdvuSubcontractorRouter

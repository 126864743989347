import { PropsWithChildren, useContext, FormEvent } from 'react'
import { Controller, Control, UseFormTrigger } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import ConfirmDialogContext from 'src/context/ConfirmModalContext'
import { ConfirmModalBody } from 'src/reviewed-ui-elements/ConfirmModal'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import MentionTextArea from 'src/ui-elements/textarea/MentionTextArea'

interface ConfirmModalProps {
  title?: string
  message?: string
  iconColor?: string
  confirmButtonType?: ButtonType
  confirmText?: string
  cancelText?: string
  controlClose?: (arg: () => void) => void
}

export const useConfirmModal = () => {
  const { showConfirmation } = useContext(ConfirmDialogContext)
  const { t } = useTranslation()

  const confirm = ({
    title,
    message,
    iconColor,
    confirmText,
    cancelText,
    confirmButtonType,
    children,
    controlClose,
  }: PropsWithChildren<ConfirmModalProps> = {}) => {
    const confirmModalBody = (
      <ConfirmModalBody confirmMessage={message} iconColor={iconColor}>
        {children}
      </ConfirmModalBody>
    )

    return showConfirmation({
      title: title || t('confirm_change'),
      body: confirmModalBody,
      confirmText,
      cancelText,
      confirmButtonType,
      controlClose,
    })
  }

  return { confirm }
}

export const useConfirmWithCommentModal = (
  control: Control<{ comment: string }>,
  trigger: UseFormTrigger<{ comment: string }>,
) => {
  const { showConfirmation } = useContext(ConfirmDialogContext)
  const { t } = useTranslation()

  const triggerValidateForm = async (e?: FormEvent) => {
    e?.preventDefault()
    return await trigger(['comment'])
  }

  const confirm = ({
    title,
    message,
    iconColor,
    confirmText,
    cancelText,
    confirmButtonType,
    children,
    controlClose,
  }: PropsWithChildren<ConfirmModalProps>) => {
    const confirmModalBody = (
      <ConfirmModalBody confirmMessage={message} iconColor={iconColor}>
        {children}
        <form onSubmit={triggerValidateForm}>
          <Controller
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div>
                {error && <p className={'text-sm text-red'}>{error.message}</p>}
                <MentionTextArea
                  id={'comment-field'}
                  placeholder={t('write_a_comment')}
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  autoFocus
                  backgroundColor={error ? 'red-100' : undefined}
                />
              </div>
            )}
            rules={{ required: t('please_provide_a_comment') }}
            name={'comment'}
          />
        </form>
      </ConfirmModalBody>
    )

    return showConfirmation({
      title: title || t('confirm_change'),
      body: confirmModalBody,
      confirmText,
      cancelText,
      confirmButtonType,
      controlClose,
      validateFormCallback: triggerValidateForm,
    })
  }

  return { confirm }
}

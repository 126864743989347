import Edit from '@material-symbols/svg-500/rounded/edit.svg'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { checkInColor } from 'src/components/construction/wagon/CheckListDetail'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import { useUserDisciplines } from 'src/query/disciplines'
import {
  getConstructionWagon,
  getPrevNextWagonDetails,
} from 'src/service/ConstructionWagonService'
import { IConstructionWagon, IDiscipline } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import Icon from 'src/ui-elements/icon/Icon'
import useAlert from 'src/ui-elements/toast/useAlert'
import { capFirstLetter, classNames } from '../../utility/utils'
import { FullPageLoaderVh } from '../MUtils'
import FloatingButton from '../components/FloatingButton'
import MCard from '../components/MCard'

interface IMWagonDetail {
  onAddIssue: () => void
  onWagonChange: (w: IConstructionWagon) => void
}

export const getStatusColor = (wagon: IConstructionWagon) => {
  if (
    wagon.activity_disciplines?.some((discipline) =>
      wagon.check_ins?.some(
        (checkIn) => checkIn.discipline_id === discipline.id,
      ),
    )
  ) {
    return wagon.hand_over_color
  }

  if (wagon.status_color) {
    return wagon.status_color
  }

  return wagon.hand_over_color
}

const MWagonDetail = ({ onAddIssue, onWagonChange }: IMWagonDetail) => {
  const styleClass = {
    root: classNames('bg-mobile', 'relative'),
  }

  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const history = useHistory()
  const { vid } = useParams<{ caid: string; vid: string }>()
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<
    {
      left: string | JSX.Element | undefined
      right: string | JSX.Element | undefined
    }[]
  >([])
  const [childData, setChildData] = useState<
    {
      left: string | JSX.Element | undefined
      right: string | JSX.Element | number | undefined
    }[]
  >([])
  const [disciplineOptions, setDisciplineOptions] = useState<IDiscipline[]>([])

  const userContext: IUserContext = useContext(UserContext)

  const { data: userDisciplines = [] } = useUserDisciplines(
    userContext.state.user.id,
  )

  const user = userContext.state.user
  useEffect(() => {
    const disciplineCheckInOptions = userDisciplines.filter((discipline) =>
      wagon.activity_disciplines?.some(
        (wagonDiscipline) => wagonDiscipline.id === discipline.id,
      ),
    )
    setDisciplineOptions(disciplineCheckInOptions)
  }, [user.id, wagon?.activity_disciplines, userDisciplines])

  const fetchWagon = async (id: string) => {
    if (id) {
      setLoading(true)
      const wagon = await getConstructionWagon(+id)
      const dataRow = [
        { left: t('control_area'), right: `${wagon?.control_area?.title}` },
        { left: t('area'), right: `${wagon?.control_area?.total_area}` },
        {
          left: t('control_area_group'),
          right: `${wagon?.control_area?.control_area_group?.title}`,
        },
      ]
      setData(dataRow)
      setWagonData(wagon)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchWagon(vid)
  }, [vid])

  const setWagonData = (wagon: IConstructionWagon) => {
    setWagon(wagon)
    const wagonRow = prepareWagonData(wagon)
    setChildData(wagonRow)
    onWagonChange(wagon)
  }
  const prepareWagonData = (wagon: IConstructionWagon) => {
    const checkIns =
      wagon.activity_disciplines?.map((discipline) => {
        const checkIn = wagon.check_ins?.find(
          (checkIn) =>
            checkIn.is_check_in && checkIn.discipline_id === discipline.id,
        )
        return {
          left: discipline.name,
          right: (
            <Badge
              text={!!checkIn ? t('yes') : t('no')}
              color={
                checkIn
                  ? (checkInColor(checkIn) as BadgeColor)
                  : BadgeColor.BLUE
              }
              size={Badge.BadgeSize.SMALL}
            />
          ),
          noBold: true,
        }
      }) ?? []
    const checkOuts =
      wagon.activity_disciplines?.map((discipline) => {
        const checkIn = wagon.check_ins?.find(
          (checkIn) =>
            !checkIn.is_check_in && checkIn.discipline_id === discipline.id,
        )
        return {
          left: discipline.name,
          right: (
            <Badge
              text={!!checkIn ? t('yes') : t('no')}
              color={
                checkIn
                  ? (checkInColor(checkIn) as BadgeColor)
                  : BadgeColor.BLUE
              }
              size={Badge.BadgeSize.SMALL}
            />
          ),
          noBold: true,
        }
      }) ?? []
    return [
      { left: t('control_area'), right: `${wagon.control_area?.title}` },
      { left: t('wagon'), right: `${wagon.title}` },
      { left: t('description'), right: `${wagon.subject}` },
      {
        left: t('start_date'),
        right: moment(wagon.startTime).format('ddd MMM DD YYYY'),
      },
      {
        left: t('end_date'),
        right: moment(wagon?.startTime)
          .add(
            wagon?.extended_duration ? wagon.extended_duration - 1 : 0,
            'days',
          )
          .format('ddd MMM DD YYYY'),
      },
      { left: t('duration'), right: wagon.duration + ` (${t('days')})` },
      {
        left: t('working_hours'),
        right: !Number.isNaN(wagon?.planned_staffing)
          ? Number(wagon?.planned_execution_hours).toFixed(1)
          : 0,
      },
      {
        left: t('number_of_workers'),
        right: !Number.isNaN(wagon?.planned_staffing)
          ? Number(wagon?.planned_staffing).toFixed(1)
          : '0',
      },
      {
        left: t('checked_in'),
        right: wagon.check_ins?.some((checkIn) => checkIn.is_check_in) ? (
          <div
            className="p-2 border bg-blue-100 text-blue-500 font-medium rounded-full cursor-pointer flex justify-between"
            onClick={() => {
              history.push(`/mobile/${wagon.id}/checks`)
            }}
          >
            <span className="first-capitalize text-sm">{t('details')}</span>
            <Edit className="text-lg fill-blue-500" />
          </div>
        ) : (
          <></>
        ),
        underline: true,
      },
      ...checkIns,
      {
        left: t('checked_out'),
        right: wagon.check_ins?.some((checkIn) => !checkIn.is_check_in) ? (
          <div
            className="p-2 border bg-blue-100 text-blue-500 font-medium rounded-full cursor-pointer flex justify-between"
            onClick={() => {
              history.push(`/mobile/${wagon.id}/checks`)
            }}
          >
            <span className="first-capitalize text-sm">{t('details')}</span>
            <Edit className="text-lg fill-blue-500" />
          </div>
        ) : (
          <></>
        ),
        underline: true,
      },
      ...checkOuts,
      {
        left: t('status'),
        right: (
          <Badge
            text={t(getStatusColor(wagon) ?? 'not_checked_in')}
            color={
              (getStatusColor(wagon) as BadgeColor | undefined) ??
              BadgeColor.DEFAULT
            }
            size={Badge.BadgeSize.SMALL}
          />
        ),
      },
    ]
  }

  const NoVognErrorMessage = (): JSX.Element => (
    <div
      className={
        'shadow-sm p-3 rounded-xl bg-m-warning-yellow text-sm text-gray-600 flex font-light'
      }
    >
      <Icon icon={Icon.IconType.WARNING_YELLOW} className={'w-4 h-4 mr-3'} />
      <span className={'font-medium'}>{t('note')}:&nbsp;</span>&nbsp;
      {capFirstLetter(t('wagon'))}
      <span className={'font-semibold'}>&nbsp;{t('not_caps')}&nbsp;</span>{' '}
      {t('found_for_this_week')}
    </div>
  )

  // Disciplines the user can check in for
  const disciplineCheckInOptions = disciplineOptions.filter(
    (discipline) =>
      !wagon.activity_disciplines?.some(
        (wagonDiscipline) =>
          wagonDiscipline.id === discipline.id &&
          wagon.check_ins?.some(
            (check_in) =>
              check_in.is_check_in && check_in.discipline_id === discipline.id,
          ),
      ),
  )

  // Disciplines the user can check out for
  const disciplineCheckOutOptions = disciplineOptions.filter((discipline) => {
    const disciplineHasBeenCheckedIn = wagon.check_ins?.some(
      (check_in) =>
        check_in.is_check_in && check_in.discipline_id === discipline.id,
    )
    const disciplineHasNotBeenCheckedOut = !wagon.check_ins?.some(
      (check_in) =>
        !check_in.is_check_in && check_in.discipline_id === discipline.id,
    )
    return disciplineHasBeenCheckedIn && disciplineHasNotBeenCheckedOut
  })

  const handleCheckInOut = (isCheckIn: boolean) => {
    history.push(`/mobile/check-in-out/${wagon.id}`, { isCheckIn })
  }

  const checkInOutTooltip = (): string | undefined => {
    if (disciplineCheckOutOptions.length > 0 && !wagon.status_color) {
      return t('the_wagon_must_have_status_meeting')
    }
    if (disciplineOptions.length === 0) {
      return t('no_activities_in_your_disciplines')
    }
    return undefined
  }

  const CheckInOutBar = (): JSX.Element => (
    <div
      className={
        'px-6 bg-mobile sticky bottom-0 py-1 z-50 border-t border-gray-300'
      }
    >
      <div className={'flex justify-between'}>
        <Button
          width={'max(120px, 36vw)'}
          onClick={() => handleCheckInOut(true)}
          disabled={disciplineCheckInOptions.length === 0}
          inMobile={true}
          type={Button.ButtonType.PRIMARY}
          size={Button.ButtonSize.SMALL}
        >
          {wagon.has_checked_in ? t('checked_in') : t('check_in')}
        </Button>
        <Button
          width={'max(120px, 36vw)'}
          onClick={() => handleCheckInOut(false)}
          disabled={
            disciplineCheckOutOptions.length === 0 || !wagon.status_color
          }
          inMobile={true}
          type={Button.ButtonType.PRIMARY}
          size={Button.ButtonSize.SMALL}
        >
          {wagon.has_checked_out ? t('checked_out') : t('check_out')}
        </Button>
      </div>
      {checkInOutTooltip() && (
        <p className={'pl-2 text-xxs text-yellow-400 font-light'}>
          {checkInOutTooltip()}
        </p>
      )}
    </div>
  )

  const getNeighbouringWagon = async (
    wagon: IConstructionWagon,
    prev: boolean,
  ) => {
    if (wagon.id) {
      if (
        (prev && wagon.has_previous === false) ||
        (!prev && wagon.has_next === false)
      ) {
        addAlert({
          type: 'error',
          title: t('an_error_occurred'),
          description: t('no_wagon_found'),
          autoClose: true,
        })
        return
      } else {
        setLoading(true)
        getPrevNextWagonDetails(wagon.id, prev ? 'previous' : 'next').then(
          (res) => {
            if (res && res?.message === 'Item not found') {
              addAlert({
                type: 'error',
                title: t('an_error_occurred'),
                description: t('no_wagon_found'),
                autoClose: true,
              })
              return
            }
            setWagonData(res)
            setLoading(false)
            history.push(`/mobile/wagons/${res.id}?tabIndex=0`)
          },
        )
      }
    }
  }

  const footer = (wagon: IConstructionWagon) => (
    <div className={'mt-2'}>
      <div className={'w-full flex justify-between'}>
        <Button
          width={'40%'}
          disabled={wagon.has_previous === false}
          onClick={() => getNeighbouringWagon(wagon, true)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          ❮ {t('previous')}
        </Button>
        <Button
          width={'40%'}
          disabled={wagon.has_next === false}
          onClick={() => getNeighbouringWagon(wagon, false)}
          inMobile={true}
          size={Button.ButtonSize.XSMALL}
          type={Button.ButtonType.SECONDARY}
        >
          {t('next')} ❯
        </Button>
      </div>
    </div>
  )

  return (
    <div className={styleClass.root}>
      <div className={'w-full md:w-1/2 lg:w-1/3'}>
        <FloatingButton
          fullWidth={true}
          floating={false}
          onClick={onAddIssue}
        />
      </div>
      <div
        className={
          'flex flex-col p-6 pt-0 flex-auto min-w-0 overflow-y-auto md:min-h-80vh lg:min-h-90vh'
        }
      >
        {loading ? (
          <FullPageLoaderVh />
        ) : data?.length > 0 ? (
          <>
            <p className={'block text-gray-600 text-sm mb-2'}>
              {capFirstLetter(t('selected_control_area'))}
            </p>
            <MCard data={data} />
            <div className={'mt-4 mb-2'}>
              {childData.length > 0 ? (
                <>
                  <p className={'text-gray-600 text-sm mb-1'}>
                    {capFirstLetter(t('selected_wagon'))}
                  </p>
                  <div className={'mt-2 mb-1'}>
                    <MCard data={childData} footer={footer(wagon)} />
                  </div>
                </>
              ) : (
                <NoVognErrorMessage />
              )}
            </div>
          </>
        ) : (
          <div className={'m-4 flex justify-center'}>
            <Badge
              text={t('no_results_found')}
              size={Badge.BadgeSize.LARGE}
              color={Badge.BadgeColor.TEAL}
            />
          </div>
        )}
      </div>
      {childData.length > 0 && <CheckInOutBar />}
    </div>
  )
}

export default MWagonDetail

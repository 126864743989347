import moment from 'moment'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UserIcon from 'src/components/user/UserIcon'
import { useTestApprovals } from 'src/query/test'
import { TestApprovalStatus, IUserData, Signature } from 'src/service/OrgTypes'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import List from 'src/ui-elements/list/List'
import { IListColumns } from 'src/ui-elements/list/ListTypes'
import Modal from 'src/ui-elements/modal/Modal'

interface TestApprovalCountProps {
  approval?: TestApprovalStatus
  executionId: number
}

export const badgeColorApproval = (value: string): BadgeColor | undefined => {
  switch (value) {
    case 'ready_for_approval':
      return BadgeColor.BLUE
    case 'approved':
      return BadgeColor.GREEN
    case 'rejected':
      return BadgeColor.RED
    default:
      return undefined
  }
}

const TestApprovalCount: FC<TestApprovalCountProps> = ({
  approval,
  executionId,
}) => {
  const [showApprovals, setShowApprovals] = useState<boolean>()
  const [selectedApproval, setSelectedApproval] = useState<{
    user: IUserData
    signature: Signature
  }>()
  const [showImgModal, setShowImgModal] = useState(false)

  const { data: approvals = [] } = useTestApprovals(executionId, {
    enabled: !!showApprovals,
  })

  const { t } = useTranslation()

  const columns: IListColumns[] = [
    {
      id: 'user',
      size: '200',
      name: 'test_participant',
      dataField: 'user',
      cell: (user: IUserData) => (
        <UserIcon
          userId={user.id}
          firstName={user.firstName}
          lastName={user.lastName}
          image_url={user.image_url}
        />
      ),
    },
    {
      id: 'comment',
      size: '250',
      name: 'comment',
      dataField: 'comment',
    },
    {
      id: 'status',
      size: '150',
      name: 'status',
      dataField: 'status',
      cell: (status: string) => (
        <Badge
          size={Badge.BadgeSize.LARGE}
          text={t(status)}
          color={badgeColorApproval(status)}
        />
      ),
    },
    {
      id: 'signature',
      size: '100',
      name: 'signatures',
      dataField: 'signatures||user',
      cell: ({ signatures: _signature, user }) => {
        const signature = _signature[0]
        if (signature) {
          return (
            <div
              className="hover:underline hover:scale-105 text-blue-root"
              onClick={(e) => {
                e.stopPropagation()
                setSelectedApproval({ signature, user })
                setShowImgModal(true)
              }}
            >
              <img
                key={user.id + 'signature'}
                width={'50'}
                height={'50'}
                src={signature.signature}
                alt={`${user.firstName} signature`}
              />
            </div>
          )
        }
        return <></>
      },
    },
    {
      name: 'date',
      size: '200',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
  ]

  const totalCount = approval?.total_approval_requests
  const signed = approval?.completed_approval_requests
  return (
    <>
      <div
        onClick={() => setShowApprovals(true)}
        className="hover:underline hover:scale-105 text-blue-root"
      >{`${signed}/${totalCount}`}</div>

      <Modal
        title={t('test_approvals')}
        show={showApprovals}
        closeModal={() => {
          setShowApprovals(false)
        }}
        maxWidth={1200}
      >
        <div className="p-4 mb-5">
          <List
            data={approvals}
            columns={columns}
            itemsPerPage={0}
            disableColumnSelector
            tableName={'testCaseApprovals'}
          />
        </div>
      </Modal>

      {selectedApproval?.user && (
        <Modal
          show={showImgModal}
          closeModal={() => setShowImgModal(false)}
          title={t('signature')}
          size={'w-1/2'}
          maxWidth={400}
        >
          <div className={'pb-2 flex flex-col gap-4 px-2'}>
            <div className={`flex flex-col w-full justify-center`}>
              <label className={` w-32`}>{t('date')}</label>
              <div className={'flex items-center text-sm p-1 pl-0'}>
                {moment(selectedApproval?.signature.signature_date).format(
                  'L LT',
                )}
              </div>
            </div>
            <div className={`flex flex-col w-full justify-center`}>
              <label className={` w-32`}>{t('signature')}</label>
              <img
                width={'250'}
                height={'250'}
                src={selectedApproval?.signature.signature}
                alt={`${selectedApproval.user.firstName} signature`}
              />
            </div>
            <div className={`flex flex-col w-full justify-center`}>
              <label className={` w-32`}>{t('signed_by')}</label>
              <UserIcon
                userId={selectedApproval.user.id}
                firstName={selectedApproval.user.firstName}
                lastName={selectedApproval.user.lastName}
                image_url={selectedApproval.user.image_url}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default TestApprovalCount

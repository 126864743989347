import { TableFilter } from 'src/ui-elements/Table/useTable'

export const defaultFilter = (
  appliesToLevels?: number[],
  parentSystemId?: number,
  parentSystemPath?: string,
  testSystemGroupId?: number,
  systemStatusId?: number,
  systemCategoryId?: number,
  systemSettingId?: number,
  fileContainerId?: number,
  disciplineId?: number,
  isSystemList?: boolean,
  onlyDirectChildren?: boolean,
  systemIds?: number[],
) => {
  const activeFilters: TableFilter = {}
  if (parentSystemId) {
    activeFilters['parent_system'] = [parentSystemId]
  }
  if (parentSystemPath) {
    activeFilters['parent_system_path'] = [parentSystemPath]
  }

  if (testSystemGroupId) {
    activeFilters['test_system_group'] = [testSystemGroupId.toString()]
  }
  if (systemStatusId) {
    activeFilters['system_status'] = [systemStatusId.toString()]
  }
  if (systemCategoryId) {
    activeFilters['system_syntax_category'] = [systemCategoryId.toString()]
  }
  if (systemSettingId) {
    activeFilters['system_setting'] = [systemSettingId.toString()]
  }
  if (disciplineId) {
    activeFilters['discipline'] = [disciplineId.toString()]
  }
  if (fileContainerId) {
    activeFilters['file_container_ids'] = [fileContainerId]
  }
  if (onlyDirectChildren) {
    activeFilters['only_direct_children'] = true
  }
  if (isSystemList) {
    activeFilters['is_component'] = [false]
  }
  if (appliesToLevels?.length) {
    activeFilters['system_syntax_category'] = appliesToLevels
  }
  if (systemIds) {
    activeFilters['ids'] = systemIds
  }
  return activeFilters
}

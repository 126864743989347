import { isEqual } from 'lodash'
import * as React from 'react'
import { useRef } from 'react'
import { metadataTimeTypes } from 'src/document/types/FolderMetadataTypes'
import { IMetaValue } from 'src/document/types/IMetaData'
import { capFirstLetter } from 'src/utility/utils'
import DateTimeInput from '../DateTimeInput'

interface IDocumentMetaDataTimeInput {
  field: IMetaValue
  isPage: boolean
  editMode: boolean
  onChange: (newValue: string) => void
  disabled?: boolean
}

const DocumentMetaDataTimeInput: React.FC<IDocumentMetaDataTimeInput> = ({
  field,
  isPage,
  editMode,
  onChange,
  disabled,
}) => {
  const parentRef = useRef(null)
  return (
    <div
      className={`flex flex-col items-start  w-full font-roboto text-sm ${
        isPage ? 'pl-4' : 'pt-2'
      }`}
    >
      <div
        ref={parentRef}
        className={'flex flex-row flex-wrap font-medium w-1/2'}
      >
        <label className={'block leading-5 pb-2'}>
          {capFirstLetter(field.name)}
          {field.is_required ? ' *' : ''}
        </label>

        {field.error && (
          <p className={'pb-2 text-red-600 ml-2 text-sm font-normal'}>
            {field.error}
          </p>
        )}
      </div>
      {isPage && !editMode ? (
        <span className={'text-gray-500'}>{field.data_value}</span>
      ) : (
        <div
          className={`border border-gray-300 w-full leading-5 font-roboto text-sm px-2 mr-2 py-1.5`}
        >
          <DateTimeInput
            includeTime={isEqual(
              field.pattern,
              metadataTimeTypes['dateTime'].pattern,
            )}
            onSave={onChange}
            date={field.data_value as string}
            disabled={disabled}
          />
        </div>
      )}
    </div>
  )
}

export default DocumentMetaDataTimeInput

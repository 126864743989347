import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { classNames } from 'src/utility/utils'

export interface IDropDownItem {
  icon?: JSX.Element
  title: string
  onClick?: () => void
  addLineBelow?: boolean
  shortcut?: string
  disabled?: boolean
  disabledMessage?: string
}

interface IDropdownMenu {
  isOpen: boolean
  onClose: () => void
  items: IDropDownItem[]
  position?: 'left' | 'right'
  className?: string
}

const DropdownMenu = ({
  isOpen,
  onClose,
  items,
  position = 'left',
  className = '',
}: IDropdownMenu) => {
  const { t } = useTranslation()
  return (
    <CloseClickOutside onClose={onClose}>
      <div
        className="relative text-gray-600"
        onClick={(e) => e.stopPropagation()}
      >
        {isOpen && (
          <div
            className={classNames(
              'z-40 absolute top-0 p-1 shadow-md rounded-md bg-white cursor-pointer',
              position === 'left' ? 'right-0' : 'left-0',
              className,
            )}
          >
            {items.map((item, index) => (
              <div key={index}>
                <div
                  className={twMerge(
                    'p-1 rounded-md flex items-center text-sm pr-8 whitespace-nowrap',
                    item.disabled
                      ? 'hover:bg-grey-light cursor-not-allowed'
                      : 'hover:bg-blue-root-wash',
                  )}
                  onClick={() => {
                    if (item.disabled) return
                    isOpen && onClose()
                    item.onClick && item.onClick()
                  }}
                >
                  {item.icon && (
                    <div
                      className={twMerge(
                        item.disabled ? 'fill-grey-dark' : 'fill-blue-root',
                        'pr-2',
                      )}
                    >
                      {item.icon}
                    </div>
                  )}
                  <Tooltip
                    show={!!item.disabled}
                    message={item.disabledMessage ?? t('disabled')}
                  >
                    <p
                      className={twMerge(
                        'first-capitalize',
                        item.disabled && 'text-grey-dark italic',
                      )}
                    >
                      {item.title}
                    </p>
                  </Tooltip>
                  {item.shortcut && (
                    <div className="absolute right-2 text-xxs">
                      {item.shortcut}
                    </div>
                  )}
                </div>
                {item.addLineBelow && (
                  <div className="border-b border-gray-200"></div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </CloseClickOutside>
  )
}

export default DropdownMenu

import CheckCircle from '@material-symbols/svg-500/rounded/check_circle-fill.svg'
import Warning from '@material-symbols/svg-500/rounded/warning-fill.svg'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useDraftMode from 'src/hooks/useDraftMode'
import { ICheckInOut, IConstructionWagon } from 'src/service/OrgTypes'
import { BadgeColor, BadgeColorMapper } from 'src/ui-elements/badge/BadgeEnums'
import Button from 'src/ui-elements/button/Button'
import useAlert from 'src/ui-elements/toast/useAlert'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { getWagonCheckInOuts } from '../../../service/ConstructionWagonService'
import { capFirstLetter } from '../../../utility/utils'
import CheckInOutDetail from './CheckInOutDetail'
import WagonCheckInOut from './WagonCheckInOut'

interface IChecckListDetail {
  wagon: IConstructionWagon
  onUpdate: () => void
}

const isColor = (color: 'red' | 'yellow', checkInOut: ICheckInOut): boolean => {
  return checkInOut.check_in_parameters.some(
    (param) =>
      param.color === color &&
      param.tasks.some((task) => task.status !== 'done'),
  )
}

export const checkInColor = (
  checkIn?: ICheckInOut,
  checkOut?: ICheckInOut,
): 'red' | 'yellow' | 'green' | undefined => {
  if (
    (!!checkOut && isColor('red', checkOut)) ||
    (!!checkIn && isColor('red', checkIn))
  )
    return 'red'
  if (
    (!!checkOut && isColor('yellow', checkOut)) ||
    (!!checkIn && isColor('yellow', checkIn))
  )
    return 'yellow'
  if (!!checkIn || !!checkOut) return 'green'
  return undefined
}

const CheckListDetail = ({ wagon, onUpdate }: IChecckListDetail) => {
  const { addAlert } = useAlert()
  const { t } = useTranslation()
  const [showCheckInOutModal, setShowCheckInOutModal] = useState<boolean>(false)
  const [isCheckIn, setIsCheckIn] = useState<boolean>(false)
  const [checkIns, setCheckIns] = useState<ICheckInOut[]>([])
  const [checkOuts, setCheckOuts] = useState<ICheckInOut[]>([])
  const [selectedDisciplineId, setSelectedDisciplineId] = useState<
    number | undefined
  >()
  const [editing, setEditing] = useState<boolean>(false)

  const { draftMode } = useDraftMode()
  const checkInData = useMemo(() => {
    return checkIns.find(
      (checkIn) => checkIn.discipline_id === selectedDisciplineId,
    )
  }, [checkIns, selectedDisciplineId])
  const checkOutData = useMemo(() => {
    return checkOuts.find(
      (checkOut) => checkOut.discipline_id === selectedDisciplineId,
    )
  }, [checkOuts, selectedDisciplineId])

  const selectedDiscipline = wagon.activity_disciplines?.find(
    (discipline) => discipline.id === selectedDisciplineId,
  )

  useEffect(() => {
    loadData()
  }, [wagon])

  const loadData = () => {
    if (wagon.id) {
      getWagonCheckInOuts(wagon.id).then((res) => {
        setCheckIns(res.filter((item: ICheckInOut) => item.is_check_in))
        setCheckOuts(res.filter((item: ICheckInOut) => !item.is_check_in))
      })
    }
    if (wagon.activity_disciplines?.length) {
      setSelectedDisciplineId(wagon.activity_disciplines[0].id)
    }
  }

  const onCheckInOutSubmit = () => {
    setShowCheckInOutModal(false)
    loadData()
    onUpdate()
  }

  const handleCheckIn = (disciplineId: number) => {
    setIsCheckIn(true)
    setEditing(false)
    setShowCheckInOutModal(true)
    setSelectedDisciplineId(disciplineId)
  }

  const handleCheckOut = (disciplineId: number) => {
    if (wagon?.status_color !== null) {
      setIsCheckIn(false)
      setEditing(false)
      setShowCheckInOutModal(true)
      setSelectedDisciplineId(disciplineId)
      return
    }
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: t('the_wagon_must_have_status_meeting'),
      autoClose: true,
    })
  }

  const onEditCheckInout = (isThisCheckIn: boolean, disciplineId: number) => {
    setIsCheckIn(isThisCheckIn)
    setEditing(true)
    setShowCheckInOutModal(true)
    setSelectedDisciplineId(disciplineId)
  }

  if (draftMode)
    return (
      <div className="m-8 text-gray-500">
        {t('can_t_check_in_check_out_in_draft_mode')}
      </div>
    )

  return (
    <div className="flex flex-col px-2 h-full">
      <div className={'flex flex-row w-full'}>
        {wagon.activity_disciplines &&
          wagon.activity_disciplines.map((discipline) => {
            const checkIn = checkIns.find(
              (checkIn) => checkIn.discipline_id === discipline.id,
            )
            const checkOut = checkOuts.find(
              (checkOut) => checkOut.discipline_id === discipline.id,
            )
            const hasCheckedIn = !!checkIn
            const hasCheckedOut = !!checkOut
            const isSelectedAccordion = selectedDisciplineId === discipline.id
            const color = checkInColor(checkIn, checkOut)
            return (
              <div
                className={twMerge(
                  'p-1 px-2 flex flex-row w-96 cursor-pointer justify-center items-center rounded-t-xl border min-h-12',
                  color === 'red' &&
                    (isSelectedAccordion
                      ? 'bg-red-300 border-red-500'
                      : BadgeColorMapper()[BadgeColor.RED]),
                  color === 'yellow' &&
                    (isSelectedAccordion
                      ? 'bg-yellow-300'
                      : BadgeColorMapper()[BadgeColor.YELLOW]),
                  color === 'green' &&
                    (isSelectedAccordion
                      ? 'bg-green-300 border-green-500'
                      : BadgeColorMapper()[BadgeColor.GREEN]),
                  !color &&
                    isSelectedAccordion &&
                    'bg-blue-300 border-blue-500',
                  isSelectedAccordion && 'border-double border-4',
                )}
                key={discipline.id}
                onClick={() => setSelectedDisciplineId(discipline.id)}
              >
                <span className="text-black all-small-caps font-semibold leading-none">
                  {discipline.name}
                </span>
                {wagon.id && !hasCheckedOut ? (
                  hasCheckedIn ? (
                    <div className="pl-4">
                      <Button
                        type={Button.ButtonType.DEFAULT}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleCheckOut(discipline.id)
                        }}
                      >
                        {capFirstLetter(t('check_out'))}
                      </Button>
                    </div>
                  ) : (
                    <div className="pl-4">
                      <Button
                        type={Button.ButtonType.DEFAULT}
                        onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          handleCheckIn(discipline.id)
                        }}
                      >
                        {capFirstLetter(t('check_in'))}
                      </Button>
                    </div>
                  )
                ) : (
                  <CheckCircle className="mx-2 fill-black" />
                )}
                {checkIn?.migrated && (
                  <Tooltip
                    message={t(
                      'this_has_been_generated_from_an_old_version_of_the_system',
                    )}
                    direction="bottom"
                    minWidth="52"
                    offset={1}
                  >
                    <Warning className="fill-yellow w-6 h-6" />
                  </Tooltip>
                )}
              </div>
            )
          })}
      </div>
      {selectedDisciplineId ? (
        <div className="flex flex-row h-full">
          {checkInData && (
            <div className="w-full md:w-1/2 pb-3">
              <CheckInOutDetail
                checkInOut={checkInData}
                checkedIn={true}
                wagonId={wagon?.id ? wagon?.id : 0}
                onEdit={() => onEditCheckInout(true, selectedDisciplineId)}
                reload={onUpdate}
              />
            </div>
          )}

          {checkOutData && (
            <div className="w-full md:w-1/2 pb-3">
              <CheckInOutDetail
                checkInOut={checkOutData}
                checkedIn={false}
                wagonId={wagon?.id ? wagon?.id : 0}
                actualStaffing={checkOutData.actual_staffing}
                actualWorkHours={checkOutData.actual_work_hours}
                onEdit={() => onEditCheckInout(false, selectedDisciplineId)}
                reload={onUpdate}
              />
            </div>
          )}
        </div>
      ) : (
        <p className="text-lg bg-gray-200 text-gray-500 w-full h-48 flex items-center justify-center">
          {t('no_disciplines_selected')}
        </p>
      )}
      {showCheckInOutModal && selectedDiscipline && wagon.id && (
        <WagonCheckInOut
          show={showCheckInOutModal}
          isCheckIn={isCheckIn}
          close={() => setShowCheckInOutModal(false)}
          wagonId={wagon.id}
          onSubmit={onCheckInOutSubmit}
          isEditing={editing}
          discipline={selectedDiscipline}
        />
      )}
    </div>
  )
}

export default CheckListDetail

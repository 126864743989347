import { twMerge } from 'tailwind-merge'
import { capFirstLetter, classNames } from '../../utility/utils'

interface IMCard {
  data?: Array<{
    left?: any
    right?: any
    noBold?: boolean
    underline?: boolean
  }>
  children?: any
  header?: any
  secondaryData?: JSX.Element
  footer?: JSX.Element
  className?: string
  noPadding?: boolean
}

const MCard = ({
  data,
  children,
  header,
  secondaryData,
  footer,
  className = '',
  noPadding,
}: IMCard) => {
  const styleClass = {
    root: classNames(
      'w-full',
      'h-auto',
      !noPadding ? 'px-6 py-4' : '',
      'bg-white',
      'rounded-lg',
      'shadow',
      className,
    ),
    item: {
      root: classNames('p-1', 'list-grid'),
      left: (bold: boolean, underline: boolean) =>
        twMerge(
          bold && 'font-semibold',
          'text-gray-600',
          'list-grid-left',
          'text-sm',
          underline && 'underline',
        ),
      right: classNames(
        'text-gray-900',
        'font-light',
        'list-grid-right',
        'text-xs',
      ),
    },
  }

  return (
    <div className={styleClass.root}>
      {header && typeof header === 'function' ? header() : header}
      {data && data.length > 0
        ? data.map((item, key) => {
            return item.left ? (
              <div key={key} className={styleClass.item.root}>
                <div
                  className={styleClass.item.left(
                    !item.noBold,
                    !!item.underline,
                  )}
                >
                  {capFirstLetter(item.left)}
                </div>
                <div className={styleClass.item.right}>
                  {item.right !== null &&
                  item.right !== 'null' &&
                  item.right !== 'undefined'
                    ? item.right
                    : ''}
                </div>
              </div>
            ) : null
          })
        : children}
      {secondaryData ? secondaryData : null}
      {footer ? footer : null}
    </div>
  )
}

export default MCard

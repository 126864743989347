import moment from 'moment'
import { FC, useEffect, useState } from 'react'
import { objectExportTemplate } from 'src/export-templates/ObjectExports'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import { ISystemImportHistory } from '../../../service/OrgTypes'
import { getPaginatedSystemImportHistories } from '../../../service/SystemService'
import List from '../../../ui-elements/list/List'
import {
  filterType,
  IListColumns,
  ISorting,
} from '../../../ui-elements/list/ListTypes'
import Loader from '../../../ui-elements/loader/Loader'
import { constructFilterJson, IActiveFilter } from '../../../utility/utils'
import UserIcon from '../../user/UserIcon'
import SystemImportDetail from './SystemImportDetail'

interface ISystemImportHistoryListProps {
  projectId: number
  systemSettingId: number
}

const SystemImportHistoryList: FC<ISystemImportHistoryListProps> = ({
  projectId,
  systemSettingId,
}) => {
  const [systemsImportHistories, setSystemsImportHistories] = useState<
    ISystemImportHistory[]
  >([])
  const [pages, setPages] = useState(1)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reload, setReload] = useState(false)
  const [systemsImportHistoryId, setSystemsImportHistoryId] = useState(0)
  const { getUserFilter } = useListHelper()

  useEffect(() => {
    setReload((n) => !n)
  }, [projectId])

  const closeModal = () => {
    setShowDetailModal(false)
    setReload((n) => !n)
  }

  const onView = (row: ISystemImportHistory) => {
    setSystemsImportHistoryId(row.id)
    setShowDetailModal(true)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    filterSystemImportHistories(activeFilters, page)
  }

  const filterSystemImportHistories = (
    activeFilters: IActiveFilter,
    page: number,
  ) => {
    setLoading(true)
    getPaginatedSystemImportHistories(
      projectId,
      systemSettingId,
      page,
      30,
      activeFilters,
    ).then((res: any) => {
      setSystemsImportHistories(res.system_import_histories)
      setPages(res.total_pages)
    })
    setLoading(false)
  }

  const columns: IListColumns[] = [
    {
      name: 'date',
      size: '200',
      id: 'date',
      dataField: 'created_at',
      sortingField: 'created_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (date: any) => <span>{date ? moment(date).format('L') : ''}</span>,
    },
    {
      name: 'author',
      id: 'author',
      size: '200',
      dataField: 'imported_by',
      sortingField: 'imported_by',
      filter: [],
      getFilter: getUserFilter,
      filterType: filterType.DEFAULT,
      cell: (importedBy: any) => {
        return importedBy ? (
          <UserIcon
            userId={importedBy.id}
            firstName={importedBy.firstName}
            lastName={importedBy.lastName}
            image_url={importedBy.image_url}
          />
        ) : (
          <span />
        )
      },
    },
    {
      name: 'new',
      id: 'numberOfNewSystems',
      size: '200',
      dataField: 'number_of_new_systems',
      sortingField: 'number_of_new_systems',
      filterType: filterType.NUMBER,
      filter: [],
    },
    {
      name: 'updated',
      id: 'numberOfUpdatedSystems',
      size: '200',
      dataField: 'number_of_updated_systems',
      sortingField: 'number_of_updated_systems',
      filterType: filterType.NUMBER,
      filter: [],
    },
    {
      name: 'total',
      id: 'total',
      size: '200',
      dataField: 'number_of_new_systems||number_of_updated_systems',
      filterType: filterType.NUMBER,
      filter: [],
      cell: (data: {
        number_of_new_systems: number
        number_of_updated_systems: number
      }) => (
        <span>
          {data.number_of_new_systems + data.number_of_updated_systems}
        </span>
      ),
    },
  ]

  return (
    <div className="min-h-95">
      <List
        columns={columns}
        exportTemplate={objectExportTemplate}
        data={systemsImportHistories}
        tableName={'systemImportHistoryList'}
        itemsPerPage={33}
        pagination={true}
        totalPages={pages}
        reload={reload}
        sortBackend={true}
        filterResetOption={true}
        onRowClick={onView}
        getFilteredData={getFilteredData}
      />

      {showDetailModal && systemsImportHistoryId && (
        <SystemImportDetail
          show={showDetailModal}
          closeModal={closeModal}
          systemImportId={systemsImportHistoryId}
        />
      )}

      {loading ? <Loader /> : null}
    </div>
  )
}

export default SystemImportHistoryList
